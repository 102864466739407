import FormSection from '../Forms/FormSection';
import { PvSystemDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { EquipmentDetailsFormSection } from '../SystemDesign/EquipmentDetailsFormSection';
import { MountingHardwareFormSection } from '../SystemDesign/MountingHardwareFormSection';
import { FlagBox } from '../QueueFlags/FlagBox';
import { ProgramType } from 'types';
import { DomesticContentMountingHardwareSection } from '../SystemDesign/DomesticContentMountingHardwareSection';

interface PVSystemProps {
  programType: ProgramType;
  isDomesticContent: boolean;
}

export const PVSystem = ({ programType, isDomesticContent }: PVSystemProps) => {
  return (
    <FormSection description={PvSystemDescription} title="PV System">
      {programType && (
        <>
          <EquipmentDetailsFormSection hideTitleAndDescription programType={programType} />

          {isDomesticContent ? (
            <DomesticContentMountingHardwareSection
              hideTitleAndDescription
              className="border-out"
              programType={programType}
            />
          ) : (
            <MountingHardwareFormSection hideTitleAndDescription className="border-out" programType={programType} />
          )}
        </>
      )}
      <FlagBox dataKey="PV System" />
    </FormSection>
  );
};
