import { Box, Button, FormikSelectInputNative, Modal, toast } from '@palmetto/palmetto-components';
import { Form } from 'react-router-dom';
import { getStateOptions } from '../../helpers/getStateOptions';
import { Field, Formik } from 'formik';
import { FinanceType, FinanceTypeLabels, ProgramType, ProgramTypeLabels } from 'types';
import { isErrorWithData } from '../../services/helpers';
import { useCreateTemplateMutation } from '@/services/contractTemplates';
import { useMemo } from 'react';
import { DocuSignTemplateIdField } from './DocuSignTemplateIdField';
import { EditContractSchema, templateSchema } from './EditContractModal';

export const AddContractModalHeader = () => (
  <Box childGap="sm">
    <Box fontWeight="bold">Add DocuSign Templates</Box>
    <Box>
      Add all applicable DocuSign templates for the selected state. State must be an active market in order to
      configure contracts. If no contract exists (e.g. not offering Spanish), leave blank.
    </Box>
  </Box>
);

export const AddContractModal = ({
  isOpen,
  programType,
  organizationSettingsId,
  handleClose,
}: {
  isOpen: boolean;
  programType: string;
  organizationSettingsId: string;
  handleClose: () => void;
}) => {
  const [createTemplate] = useCreateTemplateMutation();
  const handleSubmit = async (values: EditContractSchema) => {
    try {
      await createTemplate({
        organizationSettingsId,
        templateData: values,
      }).unwrap();
      toast.success('Contract created');
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error creating contract template settings');
      }
    }
  };

  const initialValues: any = useMemo(
    () => ({
      programType,
      state: '',
      contractType: '',
      englishTemplateId: '',
      englishCosignTemplateId: '',
      spanishTemplateId: '',
      spanishCosignTemplateId: '',
    }),
    [programType],
  );

  return (
    <Formik
      validationSchema={templateSchema}
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, resetForm, submitForm }) => (
        <Form noValidate>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy="addContractHeader"
            onDismiss={() => {
              resetForm(initialValues);
              handleClose();
            }}
          >
            <Modal.Header
              id="addContractHeader"
              title="Add Contract"
              onDismiss={() => {
                resetForm(initialValues);
                handleClose();
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <AddContractModalHeader />
                <Field
                  id="programType"
                  name="programType"
                  label="Program Type"
                  options={Object.values(ProgramType).map((programType) => ({
                    label: ProgramTypeLabels[programType],
                    value: programType,
                  }))}
                  component={FormikSelectInputNative}
                  isRequired
                  isDisabled
                />

                <Field
                  type="text"
                  label="State"
                  name="state"
                  id="state"
                  options={getStateOptions(true)}
                  component={FormikSelectInputNative}
                  isRequired
                />

                <Field
                  type="text"
                  label="Contract Type"
                  name="contractType"
                  id="contractType"
                  options={Object.values(FinanceType).map((type) => ({
                    label: FinanceTypeLabels[type],
                    value: type,
                  }))}
                  component={FormikSelectInputNative}
                  isRequired
                />

                <DocuSignTemplateIdField
                  label="English Template ID"
                  name="englishTemplateId"
                  id="englishTemplateId"
                  isRequired
                />

                <DocuSignTemplateIdField
                  label="English Co-Borrower Template ID"
                  name="englishCosignTemplateId"
                  id="englishCosignTemplateId"
                  isRequired
                />

                <DocuSignTemplateIdField
                  label="Spanish Template ID"
                  name="spanishTemplateId"
                  id="spanishTemplateId"
                />

                <DocuSignTemplateIdField
                  label="Spanish Co-Borrower Template ID"
                  name="spanishCosignTemplateId"
                  id="spanishCosignTemplateId"
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm(initialValues);
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Add Contract
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
