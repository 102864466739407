import {
  AppSettingsTypes,
  ContractDocumentStatus,
  ContractStatus,
  Coordinates,
  ContractRecipientStatus,
  ContractDocumentProviders,
} from './';
import { Address, ApplicantType } from './AccountTypes';
import { ObjectId } from 'mongodb';
import { Envelope } from 'docusign-esign';

export type EncryptedString = string;

export enum EntityType {
  accounts = 'accounts',
  accountMilestones = 'accountMilestones',
  accountMilestoneStipulations = 'accountMilestoneStipulations',
  accountPayoutEvents = 'accountPayoutEvents',
  accountRequirements = 'accountRequirements',
  accountTasks = 'accountTasks',
  accountAdders = 'accountAdders',
  addressLocationAnomalyLogs = 'addressLocationAnomalyLogs',
  appSettings = 'appSettings',
  applications = 'applications',
  cedLogs = 'cedLogs',
  contracts = 'contracts',
  contractDocumentSettings = 'contractDocumentSettings',
  creditCheckLogs = 'creditCheckLogs',
  disclosures = 'disclosures',
  documents = 'documents',
  events = 'events',
  financialAccount = 'financialAccount',
  financeProducts = 'financeProducts',
  funds = 'funds',
  incomingInvoices = 'incomingInvoices',
  inboundPayments = 'inboundPayments',
  inboundPaymentsAccountStatus = 'inboundPaymentsAccountStatus',
  inboundPaymentSchedules = 'inboundPaymentSchedules',
  invites = 'invites',
  milestones = 'milestones',
  organizationMilestones = 'organizationMilestones',
  organizations = 'organizations',
  organizationSettings = 'organizationSettings',
  prequalifications = 'prequalifications',
  priceSheetMappings = 'priceSheetMappings',
  priceSheets = 'priceSheets',
  pricingSettings = 'pricingSettings',
  products = 'products',
  projects = 'projects',
  propertyVerificationLogs = 'propertyVerificationLogs',
  queues = 'queues',
  quotes = 'quotes',
  requirements = 'requirements',
  systemDesigns = 'systemDesigns',
  tasks = 'tasks',
  users = 'users',
  utilities = 'utilities',
  paymentPlans = 'paymentPlans',
  webhookClients = 'webhookClients',
  webhookLogs = 'webhookLogs',
  webhookSubscriptions = 'webhookSubscriptions',
  history = 'history',
  migrations = 'migrations',
  billingIrregularStandingAccounts = 'billingIrregularStandingAccounts',
  tempPayoutsImport = 'tempPayoutsImport',
  salesReps = 'salesReps',
}

export enum MetaStatus {
  active = 'active',
  deleted = 'deleted',
}

export enum AccountProgress {
  created = 'created',
  creditApproved = 'creditApproved',
  ordered = 'ordered', //Ordered and contract created might be redundant
  contractCreated = 'contractCreated',
  contractSent = 'contractSent',
  contractSigned = 'contractSigned',
  contractApproved = 'contractApproved',
}

// TODO:  Ensure that if you add additional status values, they are also added to the corresponding milestoneProgress entry.
export enum AccountStatus {
  created = '1 - Created',
  creditApproved = '2 - Credit Approved',
  creditApprovedWithStipulations = '3 - Credit Approved',
  creditDenied = '4 - Credit Denied',
  termsAndConditionsAccepted = 'Terms & Conditions Accepted',
  contractCreated = '5 - Contract Created',
  contractSent = '6 - Contract Sent',
  contractSigned = '7 - Contract Signed',
  contractApproved = '8 - Contract Approved',
  noticeToProceedEligible = '9 - Notice to Proceed eligible',
  noticeToProceedIssued = '10 - M0 Notice to Proceed issued',
  installMilestoneSubmitted = 'Install Milestone Submitted',
  installMilestoneRejected = 'Install Milestone Rejected',
  installMilestoneResubmitted = 'Install Milestone Resubmitted',
  installMilestoneApproved = 'Install Milestone Approved',
  systemActivationMilestoneSubmitted = 'System Activation Milestone Submitted',
  systemActivationMilestoneRejected = 'System Activation Milestone Rejected',
  systemActivationMilestoneResubmitted = 'System Activation Milestone Resubmitted',
  systemActivationMilestoneApproved = 'System Activation Milestone Approved',
  jcoApproved = 'Job Close Out Approved',
  creditExpired = '99 - Credit Expired',
}

export interface Meta {
  status: MetaStatus;
  createdBy: string;
  createdAt: Date;
  modifiedBy?: string;
  modifiedAt?: Date;
  deletedBy?: string;
  deletedAt?: Date;
}

export interface Entity {
  id: ObjectId;
  meta: Meta;
}

export type ContractDocument = {
  settingsVersion?: {
    id: ObjectId;
    versionId: string;
    versionNumber: number;
  };
  references: Array<{
    name: string;
    value: string;
  }>;
  type: string;
  provider: ContractDocumentProviders;
  providerData?: DocusignProviderData;
  templateData?: Record<string, any>;
  status: ContractDocumentStatus;
  recipients: ContractRecipient[];
};

export interface Contract {
  id: ObjectId;
  accountId: ObjectId;
  quoteId: ObjectId;
  externalReference?: string;
  isEPCContract?: boolean;
  contractDocuments: Array<ContractDocument>;
  status: ContractStatus;
}

export interface DocusignProviderData {
  /** This is set dynamically from the ContractDocumentSettings.templates array */
  templateId?: string | null;
  envelopeId?: string;
  status?: Envelope['status']; // the raw docusign status
}

export const DocusignContractSigners = {
  ...ApplicantType,
  salesRep: 'salesRep',
  authorizedSigner: 'authorizedSigner',
};
export interface DocusignContractSigner {
  name: string;
  email?: string;
  type: keyof typeof DocusignContractSigners;
  title?: string;
}
export interface AppSettingsDoc {
  id: string | ObjectId;
  type: AppSettingsTypes;
  value: Record<string, any>;
}

export interface AddressLocationAnomalyLog {
  accountId: ObjectId;
  address: Address;
  coordinates: Coordinates;
  distance: number;
  tolerance: number;
}

export interface ContractRecipient extends DocusignContractSigner {
  status: ContractRecipientStatus;
  signedAt?: string;
}
