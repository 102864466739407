import React, { useCallback } from 'react';
import { Box, Button } from '@palmetto/palmetto-components';
import RequirePermissions from '../../../auth/requirePermissions';
import { OrganizationNode } from '../../../../types/Organizations';
import { useNavigate } from 'react-router-dom';
import { UserPermissions } from 'types';
import RequirePalmettoFinanceUser from '@/components/auth/RequirePalmettoFinanceUser';

interface PaymentCardHeaderProps {
  href?: string;
  isPhone?: boolean;
  organization?: OrganizationNode;
  onClick?: () => void;
  cardTitle: string;
  isInherited?: boolean;
}

const PaymentCardHeader = ({
  organization,
  isPhone,
  href,
  onClick,
  cardTitle,
  isInherited,
}: PaymentCardHeaderProps) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    if (href) {
      navigate(href);
    } else {
      onClick?.();
    }
  }, [href, navigate, onClick]);

  return (
    <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
      <Box as="h3" fontWeight="medium" fontSize="md">
        {cardTitle}
      </Box>
      {isInherited && (
        <Box style={{ flex: '1' }} alignItems={'center'}>
          Payment Plan is inherited by parent organization
        </Box>
      )}
      <RequirePalmettoFinanceUser>
        <RequirePermissions permissions={[UserPermissions.admin]}>
          {organization && (
            <Button
              variant="secondary"
              tone="neutral"
              size="sm"
              aria-label="Edit Payment Schedule"
              iconPrefix="pencil"
              onClick={handleClick}
            >
              {!isPhone && 'Edit'}
            </Button>
          )}
        </RequirePermissions>
      </RequirePalmettoFinanceUser>
    </Box>
  );
};

export default PaymentCardHeader;
