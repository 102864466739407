import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { Box, toast, useOpenClose, Alert } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import {
  AdderType,
  InstallPackage as IInstallPackage,
  QuoteStatus,
  DeepPartial,
  QueueStatus,
  QueueType,
  AdderDetails,
  ProgramType,
  AdditionalCostAdder,
  AdditionalCostAdderType,
} from 'types';
import { Formik, FormikValues } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { setPvSystemInitialValues } from '../../helpers/setPvSystemInitialValues';
import { useGetQuoteSettingsQuery, useGetQuotesQuery } from '../../services/quotes';
import { useGetCurrentContractQuery, useVoidContractMutation } from '../../services/contract';
import { ContractKwhInfo } from './ContractKwhInfo';
import { useGetAddersQuery } from '../../services/adders';
import { mapAddersValuesFromDocument } from '../../helpers/addersHelpers';
import { addersSchema } from '../Adders/AddersTab';
import { pvSystemSchema } from '../Account/PVSystem';
import { useSaveInstallPackageMutation, useSubmitInstallPackageMutation } from '../../services/installPackage';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { PVSystem } from '../MilestonePackagePartials/PVSystem';
import { MonitoringActive } from '../MilestonePackagePartials/MonitoringActive';
import { Adders } from '../MilestonePackagePartials/Adders';
import { Incentives } from '../MilestonePackagePartials/Incentives';
import { Design } from '../MilestonePackagePartials/Design';
import { InstallPhotos } from '../MilestonePackagePartials/InstallPhotos';
import { DiffErrorAlert } from '../ProductionValueCheck/DiffErrorAlert';
import { useCreateSystemDesignMutation } from '../../services/systemDesign';
import { isProductionWithinChangeToleranceFE } from '../../helpers/productionValueCheck';
import { Permit } from '../MilestonePackagePartials/Permit';
import { PreventSubmissionModal } from '../ProductionValueCheck/PreventSubmissionModal';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { Installer } from '../MilestonePackagePartials/Installer';
import { Footer } from '../MilestonePackagePartials/Footer';
import { useGetOrganizationDetailsQuery } from '../../services/organizations';
import { isErrorWithData } from '../../services/helpers';
import { DATE_FORMAT, convertToISODateString } from '../../helpers/dates';
import { ElectricianSignOff } from '@/components/MilestonePackagePartials/ElectricianSignOff';
import { InstallLoadProof } from '../MilestonePackagePartials/InstallLoadProof';
import { DomesticContent } from './DomesticContent';
import { SolarSerialNumber } from '../MilestonePackagePartials/SolarSerialNumber';
import { checkDomesticContentEligibility } from '@/helpers/checkDomesticContentEligibility';
import { SolarReadinessAdder } from '../MilestonePackagePartials/doePr/SolarReadinessAdder';
import { EnhancedFormikForm } from '../EnhancedFormikForm';
interface InstallPackageProps {
  canSubmitInstallPackage: boolean;
}

export const formatInstallPackagesData = (
  values: FormikValues,
  installerRequired: boolean,
): DeepPartial<IInstallPackage> => {
  const inverters = values?.inverterModels?.reduce(
    (acc: { manufacturer: string; model: string; count: number }[], inverterModel: any) => {
      acc.push({
        manufacturer: values?.inverterManufacturer,
        model: inverterModel?.model,
        count: inverterModel?.count,
      });

      return acc;
    },
    [],
  );
  const adders = Object.keys(AdderType)
    .filter((type) => type !== AdderType.electricalUpgrade)
    .reduce((acc: AdderDetails[], adderType: string) => {
      if (values[adderType] && typeof values[adderType] === 'object' && values[adderType] !== null) {
        acc.push({ ...values[adderType], type: adderType });
      }

      return acc;
    }, []);

  const mountingHardware = values.mountingHardware?.map((hardware: any) => {
    return {
      ...hardware,
      mountingHardwareModels: hardware.mountingHardwareModels?.map((model: any) => {
        const { value, label, ...rest } = model; // Destructure and exclude `value` and `label`
        return rest;
      }),
    };
  });

  return {
    systemDesign: {
      panelManufacturer: values.panelManufacturer,
      panelModel: values.panelModel,
      totalPanelCount: values.totalPanelCount,
      inverterManufacturer: values.inverterManufacturer,
      inverters,
      mountingType: values.mountingType,
      mountingManufacturer: values.mountingManufacturer,
      mountingHardware: mountingHardware,
    },
    ...(installerRequired && {
      installer: {
        legalName: values.legalName,
        iccDocketNumber: values.iccDocketNumber,
        qualifiedPersonOnsiteFirstName: values.qualifiedPersonOnsiteFirstName,
        qualifiedPersonOnsiteLastName: values.qualifiedPersonOnsiteLastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.installerState,
      },
    }),
    monitoring: {
      monitoringSiteId: values.monitoringSiteId,
      batteryMonitoringSiteId: values.batteryMonitoringSiteId,
    },
    solarModuleSerialNumber: values.solarModuleSerialNumber,
    adders,
    designTool: values.designTool,
    permitSubmittedDate: {
      palmetto: values.permitSubmittedDate ? convertToISODateString(values.permitSubmittedDate) : undefined,
    },
    permitApprovedDate: {
      palmetto: values.permitApprovedDate ? convertToISODateString(values.permitApprovedDate) : undefined,
    },
    installScheduledDate: {
      palmetto: values.installScheduledDate ? convertToISODateString(values.installScheduledDate) : undefined,
    },
    expectedPTODate: {
      palmetto: values.expectedPTODate ? convertToISODateString(values.expectedPTODate) : undefined,
    },
    utility: {
      tariffId: values.tariffId === '' ? null : values.tariffId,
    },
    electricianSignoffDate: {
      palmetto: values.electricianSignoffDate ? convertToISODateString(values.electricianSignoffDate) : undefined,
    },
  };
};

export const InstallPackage = ({ canSubmitInstallPackage }: InstallPackageProps) => {
  const navigate = useNavigate();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);

  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const { data: domesticContentQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.domesticContent,
  });

  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);
  const installerRequired = account?.address?.state === 'IL';
  const isPR = account?.programType === ProgramType.doePr;
  const isDomesticContentEligible = checkDomesticContentEligibility(account);

  const { data: quotes, isLoading: isQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: contract, isLoading: isContractLoading } = useGetCurrentContractQuery(id);
  const { data: addersData, isLoading: isAddersLoading } = useGetAddersQuery(id);
  const [createSystemDesign] = useCreateSystemDesignMutation();
  const [voidContract] = useVoidContractMutation();
  const [triggerSubmit] = useSubmitInstallPackageMutation();
  const [triggerSave] = useSaveInstallPackageMutation();

  const { data: quoteSettings } = useGetQuoteSettingsQuery({ programType: account?.programType });
  const { data: organizationData } = useGetOrganizationDetailsQuery({
    alias: account?.licensedOrganizationId ? account?.licensedOrganizationId : account?.organizationId,
  });

  const shouldDisplayDomesticContentSection = isDomesticContentEligible;
  const shouldDisplayDomesticContentReviewInformation =
    domesticContentQueue?.status &&
    (action === ReviewPackageActions.review ||
      (action === ReviewPackageActions.edit &&
        (isFinCoUser || domesticContentQueue.status === QueueStatus.rejected))) ||
    domesticContentQueue?.status === QueueStatus.approved;
  const domesticContentNeedsApproval = isDomesticContentEligible
    ? domesticContentQueue?.status !== QueueStatus.approved
    : false;

  const [isVoiding, setIsVoiding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    isOpen: isPreventSubmissionOpen,
    handleOpen: setPreventSubmissionOpen,
    handleClose: setPreventSubmissionClose,
  } = useOpenClose();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const isLoading = isAccountLoading || isQuotesLoading || isContractLoading || isAddersLoading || isQueueLoading;

  const inverterSchema = yup.object({
    model: yup.string().required('Inverter Model is a required field.'),
    count: yup
      .number()
      .required('Inverter Count is a required field.')
      .min(1, 'Inverter Count must be greater than 0.'),
  });

  const pvSystemInstallPackageSchema = yup.object({ ...pvSystemSchema.fields }).concat(
    yup.object({
      panelManufacturer: yup.string().required('Panel Manufacturer is a required field.'),
      panelModel: yup.string().required('Panel Model is a required field.'),
      totalPanelCount: yup
        .number()
        .required('Panel Count is a required field.')
        .min(1, 'Total Panel Count must be greater than 0.'),
      inverterManufacturer: yup.string().required('Inverter Manufacturer is a required field.'),
      inverterModels: yup
        .array()
        .of(inverterSchema)
        .required('Inverter Models are required.')
        .min(1, 'Inverter Models must have at least one entry.'),
      mountingType: yup.string().required('Mounting Type is a required field.'),
      mountingHardware: isDomesticContentEligible
        ? yup
          .array()
          .required('Mounting Hardware is a required field.')
          .min(1, 'Mounting Hardware Models must have at least one entry.')
        : yup.array(),
      mountingManufacturer: isDomesticContentEligible
        ? yup.string()
        : yup.string().required('Mounting Manufacturer is a required field.'),
    }),
  );
  const baseInstallPackageSchema = yup.object({}).concat(addersSchema).concat(pvSystemInstallPackageSchema);
  const installerSchema = yup.object().shape({
    legalName: yup.string().required('Legal name is a required field.'),
    iccDocketNumber: yup.string().required('ICC Docket Number is a required field.'),
    qualifiedPersonOnsiteFirstName: yup.string().required('Qualified Person Onsite First Name is a required field.'),
    qualifiedPersonOnsiteLastName: yup.string().required('Qualified Person Onsite Last Name is a required field.'),
    phoneNumber: yup.string().required('Phone Number is a required field.'),
    email: yup.string().required('Email is a required field.').email('Email is not a valid email.'),
    address: yup.string().required('Address is a required field.'),
    city: yup.string().required('City is a required field.'),
    installerState: yup.string().required('State is a required field.'),
  });

  const serialNumberSchema = yup.object({
    solarModuleSerialNumber: yup
      .string()
      .trim()
      .required('Serial Number is a required field.')
      .min(1, 'Serial Number cannot be empty or whitespace only.'),
  });

  const installPackageSchema = useMemo(() => {
    let schemaToValidate = baseInstallPackageSchema;

    if (installerRequired) {
      schemaToValidate = schemaToValidate.shape(installerSchema.fields);
    }
    if (isDomesticContentEligible) {
      schemaToValidate = schemaToValidate.shape(serialNumberSchema.fields);
    }
    return schemaToValidate;
  }, [installerRequired, isDomesticContentEligible]);

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ReviewPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/installation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/installation-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const accountInitialValues = {
    lseId: account?.utility?.lseId,
    monitoringSiteId: account?.monitoringSiteId,
    state: account.address.state,
    tariffId: account?.utility?.tariffId || '',
    designTool: account.designTool,
    batteryMonitoringSiteId: account.batteryMonitoringSiteId,
    solarModuleSerialNumber: account?.solarModuleSerialNumber,
    legalName: account.installer?.legalName ? account.installer?.legalName : organizationData?.name,
    iccDocketNumber: account.installer?.iccDocketNumber ? account.installer?.iccDocketNumber : '',
    qualifiedPersonOnsiteFirstName: account.installer?.qualifiedPersonOnsiteFirstName
      ? account.installer?.qualifiedPersonOnsiteFirstName
      : '',
    qualifiedPersonOnsiteLastName: account.installer?.qualifiedPersonOnsiteLastName
      ? account.installer?.qualifiedPersonOnsiteLastName
      : '',
    phoneNumber: account.installer?.phoneNumber ? account.installer?.phoneNumber : organizationData?.phoneNumber,
    email: account.installer?.email ? account.installer?.email : organizationData?.email,
    address: account.installer?.address ? account.installer?.address : organizationData?.address,
    city: account.installer?.city ? account.installer?.city : organizationData?.city,
    installerState: account.installer?.state ? account.installer?.state : organizationData?.state,
    permitApprovedDate: account?.permitApprovedDate?.palmetto
      ? DateTime.fromISO(account?.permitApprovedDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
      : undefined,
    permitSubmittedDate: account?.permitSubmittedDate?.palmetto
      ? DateTime.fromISO(account?.permitSubmittedDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
      : undefined,
    installScheduledDate: account?.installScheduledDate?.palmetto
      ? DateTime.fromISO(account?.installScheduledDate?.palmetto).toUTC().toFormat(DATE_FORMAT)
      : undefined,
    expectedPTODate: account?.expectedPTODate?.palmetto
      ? DateTime.fromISO(account?.expectedPTODate?.palmetto).toUTC().toFormat(DATE_FORMAT)
      : undefined,
    electricianSignoffDate: account?.electricianSignoffDate?.palmetto
      ? DateTime.fromISO(account.electricianSignoffDate.palmetto).toUTC().toFormat(DATE_FORMAT)
      : undefined,
    storage: account?.systemDesign?.storage,
  };
  const pvSystemInitialValues = setPvSystemInitialValues(account);
  const contractQuote = (quotes || []).find((element: any) => element.id === contract?.quoteId);
  const firstYearContracted = contractQuote?.systemFirstYearProductionKwh || 0;
  const firstYearInstalled = account?.systemDesign?.systemFirstYearProductionKwh || 0;
  const maxKwh = Math.max(firstYearInstalled, firstYearContracted);
  const minKwh = Math.min(firstYearInstalled, firstYearContracted);
  const difference = (maxKwh - minKwh) / maxKwh;
  const toleranceRate = isProductionWithinChangeToleranceFE({
    newProductionKwh: firstYearInstalled,
    originalProductionKwh: firstYearContracted,
    settings: quoteSettings,
  });
  //If it's already contracted, we should display related
  //UI elements.
  const hasContracted = firstYearContracted > 0;

  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);

  const addersInitialValues = mapAddersValuesFromDocument(addersData?.adders, activeQuote?.adders);

  if (isPR) {
    const solarReadinessValsSavedOnAccountAdders = addersData?.adders.find(
      (adder: AdderDetails) => adder.type === AdderType.solarReadiness,
    );

    // Below is for legacy accounts when the solarReadinessAdder wasn't being saved in the accountAdders collection
    const solarReadinessValOnQuote = activeQuote?.additionalCostAdders.find(
      (adder: AdditionalCostAdder) => adder.type === AdditionalCostAdderType.solarReadiness,
    );

    const initialSolarReadinessValsToUse = solarReadinessValsSavedOnAccountAdders || solarReadinessValOnQuote;

    if (initialSolarReadinessValsToUse) {
      addersInitialValues.solarReadiness = {
        cost: initialSolarReadinessValsToUse.cost || 0,
        reasonOptionsSelected: initialSolarReadinessValsToUse.reasonOptionsSelected || [],
        otherReasonDetails: initialSolarReadinessValsToUse.otherReasonDetails || '',
      };
    } else {
      addersInitialValues.solarReadiness = {
        cost: 0,
        reasonOptionsSelected: [],
        otherReasonDetails: '',
      };
    }
  }
  const canSubmitReview = action === ReviewPackageActions.review && queue;
  const initialValues = {
    ...accountInitialValues,
    ...addersInitialValues,
    ...pvSystemInitialValues,
  };

  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    if (!toleranceRate.valid && !account?.systemDesign?.toleranceException) {
      setPreventSubmissionOpen();
      return;
    }
    try {
      const formattedData = formatInstallPackagesData(values, installerRequired) as IInstallPackage;
      await triggerSubmit({
        accountId: id,
        installPackage: {
          ...formattedData,
          ...(isDomesticContentEligible ? { isDomesticContent: isDomesticContentEligible } : {}),
        },
      }).unwrap();
      toast.success('Install Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error submitting install package');
    } finally {
      setSubmitting(false);
    }
  };

  async function requestVoid() {
    setIsVoiding(true);
    try {
      await voidContract({
        accountId: id,
        contractId: contract.id,
      }).unwrap();

      navigate(`/accounts/${id}`, {
        state: {
          justVoidedContract: true,
          justUpdated1yEstimate: true,
        },
      });
    } catch (error) {
      console.error({ error });
      if (error) {
        toast.error('An error has occurred trying to void the contract. Please try again.');
      } else {
        toast.error('An error has occurred. Please try again later.');
      }
    }
    setIsVoiding(false);
  }

  const handleToleranceExceptionModal = async (note: string): Promise<boolean> => {
    if (!note || note.trim() === '' || note === undefined) {
      toast.error('Notes are required to grant a tolerance exception');
      return false;
    }
    try {
      await createSystemDesign({
        accountId: id,
        systemDesign: { ...account.systemDesign, toleranceException: { note } },
      }).unwrap();

      toast.success('Production Exception Applied');
      return false;
    } catch (e) {
      toast.error('Something went wrong. Please try again.');
      console.log({ e });
      return false;
    }
  };

  const loadingState = {
    isAccountLoading,
    isVoiding,
  };

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    setIsSaving(true);
    const formattedData = formatInstallPackagesData(data, installerRequired);
    try {
      await triggerSave({ accountId, installPackage: formattedData }).unwrap();
      toast.success('Install Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving Install Package');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;
    const teslaInverters = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const otherInverters = /^\d{5,8}$/;
    if (
      values.monitoringSiteId &&
      !teslaInverters.test(values.monitoringSiteId) &&
      !otherInverters.test(values.monitoringSiteId)
    ) {
      errors.monitoringSiteId =
        'Monitoring Site Id must match the required formats: ' +
        '5-8 numbers long for Enphase, SolarEdge, and Tigo. ' +
        'The site ID for Tesla should come from the URL (ex: 8af307d1-ca9f-4843-8079-df2ba9c5736c from https://powerhub.energy.tesla.com/site/8af307d1-ca9f-4843-8079-df2ba9c5736c).';
    }

    if (!values.designTool) {
      errors.designTool = 'Design Tool is required';
    }

    if (installerRequired) {
      if (!values.address) {
        errors.address = 'Installer Address is required';
      }
      if (!values.city) {
        errors.city = 'Installer City is required';
      }
      if (!values.installerState) {
        errors.installerState = 'Installer State is required';
      }
      if (!values.legalName) {
        errors.legalName = 'Installer Legal Name is required';
      }
      if (!values.iccDocketNumber) {
        errors.iccDocketNumber = 'Installer ICC Docket Number is required';
      }
      if (!values.qualifiedPersonOnsiteFirstName) {
        errors.qualifiedPersonOnsiteFirstName = 'Installer Qualified Person Onsite First Name is required';
      }
      if (!values.qualifiedPersonOnsiteLastName) {
        errors.qualifiedPersonOnsiteLastName = 'Installer Qualified Person Onsite Last Name is required';
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Installer Phone Number is required';
      }
      if (!values.email) {
        errors.email = 'Installer Email is required';
      }
    }

    if (isDomesticContentEligible) {
      if (!values.solarModuleSerialNumber) {
        errors.solarModuleSerialNumber = 'Serial Number is a required field';
      }
    }
    return errors;
  };
  const queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser: boolean =
    !isFinCoUser &&
    queue &&
    (queue.status === QueueStatus.conditionallyApproved || queue.status === QueueStatus.approved);

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  return (
    <QueueFlagsProvider queueId={queue?.id}>
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.installPackage} />
      <Helmet>
        <title>{`${account?.primaryApplicantName} Install Package`}</title>
      </Helmet>
      <MainContainer>
        {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
          <QueueBanner
            setIsNotesOpen={setIsNotesOpen}
            queueType={QueueType.installPackage}
            isFinCoUser={isFinCoUser}
          />
        ) : null}
        {action === ReviewPackageActions.review && !canSubmitReview ? (
          <Alert
            variant="warning"
            title="Install Package Not Submitted"
            message="This install package is not ready to be reviewed yet. It has not been submitted."
            hasIcon
          />
        ) : null}
        {hasContracted && !toleranceRate.valid && !account?.systemDesign?.toleranceException && (
          <DiffErrorAlert
            requestVoid={requestVoid}
            percentErrorDiff={difference}
            loadingState={loadingState}
            handleToleranceExceptionModal={handleToleranceExceptionModal}
          />
        )}
        {hasContracted && (
          <Box padding={{ base: '0 xl xl', tablet: '0 0 xl 0' }} borderColor="separator" borderWidth="0 0 xs 0">
            <ContractKwhInfo
              firstYearContracted={firstYearContracted}
              firstYearInstalled={firstYearInstalled}
              toleranceException={account?.systemDesign?.toleranceException}
              outOfRange={!toleranceRate.valid}
            />
          </Box>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={installPackageSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={false}
          validate={handleValidation}
        >
          {() => (
            <EnhancedFormikForm>
              {shouldDisplayDomesticContentSection && (
                <DomesticContent
                  shouldDisplayDomesticContentReviewInformation={shouldDisplayDomesticContentReviewInformation}
                />
              )}
              {isDomesticContentEligible && <SolarSerialNumber />}
              <PVSystem programType={account.programType} isDomesticContent={isDomesticContentEligible} />
              {installerRequired && <Installer />}
              <MonitoringActive adders={activeQuote?.adders} />
              <Adders
                adders={activeQuote?.adders}
                flags={queue?.flags}
                isLoading={isAddersLoading}
                programType={account.programType}
              />
              <Permit allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser} />
              <Incentives
                originContext={QueueType.installPackage}
                allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
                programType={account.programType}
              />
              <Design allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser} />
              <InstallPhotos allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser} />
              {isPR && (
                <>
                  <InstallLoadProof />
                  <ElectricianSignOff />
                  <SolarReadinessAdder />
                </>
              )}
              <Footer
                canSubmitPackage={canSubmitInstallPackage}
                disableAllApprovals={domesticContentNeedsApproval}
                isLoading={isLoading || isSaving}
                isFirstSubmission={!queue}
                canSubmitReview={canSubmitReview}
                onSave={handleSave}
                queueType={QueueType.installPackage}
                disabled={queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
              />
            </EnhancedFormikForm>
          )}
        </Formik>
        <PreventSubmissionModal isOpen={isPreventSubmissionOpen} onDismiss={setPreventSubmissionClose} />
      </MainContainer>
    </QueueFlagsProvider>
  );
};
