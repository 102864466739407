import { EditContractSchema } from '../components/AdminContracts/EditContractModal';
import { api } from './api';

export const contractTemplates = api.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<any[], Record<any, any>>({
      query: (queryParams) => ({
        url: `/admin/contracts/templates${
          queryParams &&
          '?' +
            new URLSearchParams({
              ...queryParams,
            })
        }`,
      }),
      providesTags: () => [{ type: 'ContractTemplates', id: 'LIST' }],
    }),
    getTemplateInfo: build.query<any, { templateId: string }>({
      query: ({ templateId }) => ({
        url: `/admin/contracts/template/${templateId}`,
      }),
      providesTags: (_result, _error, { templateId }) => [{ type: 'ContractTemplateInfo', id: templateId }],
    }),
    getTemplatesListFilters: build.query<any, Record<any, any>>({
      query: () => {
        return {
          url: `admin/contracts/templates/filters`,
        };
      },
    }),
    createTemplate: build.mutation<any, { organizationSettingsId: string; templateData: EditContractSchema }>({
      query: ({ organizationSettingsId, templateData }) => ({ url: `/admin/contracts/templates/${organizationSettingsId}`, method: 'POST', body: templateData }),
      invalidatesTags: () => [{ type: 'ContractTemplates', id: 'LIST' }],
    }),
    updateTemplate: build.mutation<any, { organizationSettingsId: string; contractDocumentSettingsId: string; templateData: EditContractSchema }>({
      query: ({ organizationSettingsId, contractDocumentSettingsId, templateData }) => ({ url: `/admin/contracts/templates/${organizationSettingsId}/${contractDocumentSettingsId}`, method: 'PATCH', body: templateData }),
      invalidatesTags: () => [{ type: 'ContractTemplates', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplatesListFiltersQuery,
  useLazyGetTemplateInfoQuery,
  useLazyGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} = contractTemplates;
