import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SalesRepVerificationOverrideReason, UserPermissions } from 'types';
import { isErrorWithData } from '../../services/helpers';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Box, Button, Card, Modal, SelectInput, toast, useOpenClose } from '@palmetto/palmetto-components';
import RequirePermissions from '../auth/requirePermissions';
import {
  useCreateSalesRepVerificationOverrideMutation,
  useGetAccountQuery,
  useLazyGetSalesRepVerificationUrlQuery,
  useSendSalesRepVerificationUrlMutation,
} from '../../services/accounts';

const overrideSchema = yup.object().shape({
  overrideReasons: yup.array().min(1, 'Please select at least one item.'),
});

type OverrideSchema = yup.InferType<typeof overrideSchema>;

export function GetSalesRepAuthLinkModal() {
  const { id = '' } = useParams<{ id: string }>();
  const { data: account, isLoading }: any = useGetAccountQuery(id);
  const { salesRepEmail, salesRepName } = account || {};

  const [trigger, { data: inviteData, isLoading: isGettingInviteLink }] = useLazyGetSalesRepVerificationUrlQuery();

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const [createOverride] = useCreateSalesRepVerificationOverrideMutation();
  const [sendInvite] = useSendSalesRepVerificationUrlMutation();

  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleCopyToClipBoard = useCallback(async () => {
    if (inviteData?.url) {
      await navigator.clipboard.writeText(inviteData?.url);
      toast.success('Copied to clipboard');
    }
  }, [inviteData, navigator]);

  const handleGetInviteUrl = useCallback(async () => {
    try {
      await trigger(id).unwrap();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error getting invite link: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error getting invite link');
      }
    }
  }, [trigger, id]);

  const handleSendInvite = useCallback(async () => {
    setIsSendingInvite(true);
    try {
      if (salesRepEmail && salesRepName) {
        await sendInvite({ accountId: id, salesRepEmail, salesRepName }).unwrap();
      }
      toast.success('Invite sent');
      closeModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error sending invite: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error sending invite');
      }
    }
    setIsSendingInvite(false);
  }, [salesRepEmail, id, sendInvite]);

  const handleCreateOverride = async (values: OverrideSchema) => {
    try {
      if (values?.overrideReasons) {
        await createOverride({
          accountId: id,
          overrideReasons: values.overrideReasons.map((options: { value: string; label: string }) => options.value),
        }).unwrap();
        toast.success('Verification override created');
      }
      closeModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error creating a verification override: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error creating a verification override');
      }
    }
  };

  return (
    <Box>
      <Button variant="primary" tone="neutral" size="xs" onClick={openModal} iconPrefix="send" disabled={isLoading}>
        Invite to Verify
      </Button>
      <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="4xl">
        <Modal.Header
          id="titleBasic"
          title={`Invite ${salesRepName ? salesRepName : 'the rep'} to verify identity`}
          onDismiss={closeModal}
        />
        <Modal.Body childGap="md" background="secondary">
          <Box as="p">You can send a one-time-use URL to the sales rep by email.</Box>
          <Box as="p">If the sales rep has not received their invitation email:</Box>
          <Box as="ul" padding="0 0 0 md" childGap="sm">
            {salesRepEmail && (
              <li>
                Verify email address on file is correct: <strong>{salesRepEmail}</strong>
              </li>
            )}
            <li>Advise the sales rep to check their spam/junk folder</li>
          </Box>
          <RequirePermissions permissions={[UserPermissions.lightReachSupport]}>
            <Card>
              <Card.Section fontWeight="bold">Override Verification</Card.Section>
              <Card.Section>
                <Box as="p">
                  As technical support, you may override the requirement to verify the sales rep for this account.
                </Box>
              </Card.Section>
              <Formik
                validationSchema={overrideSchema}
                initialValues={{ overrideReasons: [] }}
                validateOnChange={false}
                onSubmit={handleCreateOverride}
              >
                {({ errors, handleBlur, isSubmitting, setFieldValue, submitForm, touched, values }) => (
                  <Form noValidate>
                    <Card.Section>
                      <SelectInput
                        id="overrideReasons"
                        name="overrideReasons"
                        label="Override Reasons"
                        value={values.overrideReasons}
                        onChange={(event) => setFieldValue('overrideReasons', event.target.value)}
                        onBlur={handleBlur}
                        options={[
                          {
                            label: 'Business Decision',
                            value: SalesRepVerificationOverrideReason.businessDecision,
                          },
                          {
                            label: 'Technical Issues',
                            value: SalesRepVerificationOverrideReason.technicalIssues,
                          },
                        ]}
                        isMulti
                        isClearable
                        menuPortalTarget={document.body}
                        error={touched.overrideReasons && (errors.overrideReasons as string)}
                      />
                    </Card.Section>
                    <Card.Footer>
                      <Button
                        variant="secondary"
                        tone="neutral"
                        onClick={submitForm}
                        type="submit"
                        isDisabled={isSubmitting}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Override
                      </Button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            </Card>
          </RequirePermissions>
        </Modal.Body>
        <Modal.Footer justifyContent="space-between">
          {inviteData ? (
            <Box width="100" childGap="md" direction="row" justifyContent="space-between">
              <Button onClick={handleCopyToClipBoard} variant="secondary" tone="neutral">
                Copy URL To Clipboard
              </Button>
            </Box>
          ) : (
            <Button
              variant="secondary"
              tone="neutral"
              isLoading={isGettingInviteLink || isSendingInvite}
              onClick={handleGetInviteUrl}
            >
              Get Invitation URL
            </Button>
          )}
          <Button
            variant="primary"
            iconPrefix="mail"
            className="shadow-xs"
            onClick={handleSendInvite}
            style={{ whiteSpace: 'nowrap' }}
            isDisabled={!salesRepEmail}
            isLoading={isSendingInvite || isGettingInviteLink}
          >
            Invite to Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
