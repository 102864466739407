import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isErrorWithData } from '../../services/helpers';
import { Alert, Box, Button, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useLazyGetInviteLinkQuery, useSendInviteMutation } from '../../services/accounts';
import { DateTime } from 'luxon';
import RequirePermissions from '../auth/requirePermissions';
import { UserPermissions } from 'types';

export function GetInviteLinkModal({ isLoading = false, email = '', contractSent = false }) {
  const { id = '' } = useParams<{ id: string }>();
  const { viewConsumerInviteLink, resendConsumerInviteButtonEnabled } = useFlags();
  const [getInviteLink, { data: inviteData, isLoading: isGettingInviteLink }] = useLazyGetInviteLinkQuery();

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const [sendInvite] = useSendInviteMutation();

  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleGetInviteLink = useCallback(async () => {
    try {
      await getInviteLink(id).unwrap();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error getting invite link: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error getting invite link');
      }
    }
  }, [getInviteLink, id]);

  const handleSendInvite = useCallback(async () => {
    setIsSendingInvite(true);
    try {
      await sendInvite({ accountId: id, email }).unwrap();
      toast.success('Invite sent');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error sending invite: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error sending invite');
      }
    }
    setIsSendingInvite(false);
  }, [email, id, sendInvite]);

  return (
    <>
      {viewConsumerInviteLink && (
        <Box>
          <Button
            variant="secondary"
            tone="neutral"
            size={{ base: 'sm', desktop: 'md' }}
            onClick={openModal}
            iconPrefix="send"
          >
            Send Invite
          </Button>
          <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="4xl">
            <Modal.Header id="titleBasic" title="Invite the customer to create an account" onDismiss={closeModal} />
            <Modal.Body childGap="md" background="secondary">
              {!contractSent && (
                <Alert
                  variant="warning"
                  title="Unable to send invite. Contract not sent!"
                  message="The customer will be invited once the contract is sent. It is not advisable to invite them at this point."
                />
              )}
              <Box as="p">If the customer has not received their invite email:</Box>
              <Box as="ul" padding="0 0 0 md" childGap="sm">
                <li>
                  Verify email address on file is correct: <strong>{email}</strong>
                </li>
                <li>Advise the customer to check their spam/junk folder</li>
              </Box>
            </Modal.Body>
            <Modal.Footer justifyContent="space-between">
              <RequirePermissions permissions={[UserPermissions.admin]}>
                {inviteData ? (
                  <Box width="100" childGap="md" direction="row">
                    <Box
                      flex="auto"
                      radius="sm"
                      borderColor="separator"
                      borderWidth="xs"
                      background="secondary"
                      padding="md"
                    >
                      {inviteData?.inviteLink && <code>{inviteData?.inviteLink}</code>}
                      {inviteData?.userId &&
                        inviteData?.signedUpAt &&
                        `User signed up at ${DateTime.fromISO(inviteData.signedUpAt.toString()).toLocaleString(
                          DateTime.DATETIME_SHORT,
                        )}`}
                      {inviteData?.userId && !inviteData?.signedUpAt && `User is signed up`}
                    </Box>
                    <Button onClick={closeModal} variant="secondary" tone="neutral">
                      Close
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="secondary"
                    tone="neutral"
                    isDisabled={isLoading || !contractSent}
                    isLoading={isGettingInviteLink || isSendingInvite}
                    onClick={handleGetInviteLink}
                  >
                    Get Invite Link
                  </Button>
                )}
              </RequirePermissions>
              {resendConsumerInviteButtonEnabled && !inviteData && (
                <Button
                  variant="primary"
                  iconPrefix="mail"
                  className="shadow-xs"
                  onClick={handleSendInvite}
                  style={{ whiteSpace: 'nowrap' }}
                  isDisabled={isLoading || !contractSent}
                  isLoading={isSendingInvite || isGettingInviteLink}
                >
                  Send Invite
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Box>
      )}
    </>
  );
}
