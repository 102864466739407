import { Helmet } from 'react-helmet';
import { Box, Button, Card, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import {
  useApproveBatchesMutation,
  useCreateProjectTransactionMutation,
  useGetProjectTransactionsQuery,
  useUnbatchTransactionMutation,
} from '@/services/payouts';
import { DateTime } from 'luxon';
import ApproveBatchConfirmationModal from '@/components/Payouts/admin/ApproveBatchConfirmationModal';
import { CreateProjectTransactionPayload, PayeeType, PayoutEventType, ProjectTransactionStatus } from 'types';
import { RemoveTransactionsConfirmationModal } from '@/components/Payouts/admin/RemoveTransactionsConfirmationModal';
import { AddProjectTransactionModal } from '@/components/Payouts/admin/AddProjectTransactionModal';
import { buildQueryString } from '@/helpers/buildQueryString';
import getConfigValue from '@/config';
import { capitalize } from 'lodash';
import { RequirePayoutsAccountingAdminUser } from '../auth/RequirePayoutsAccountingAdminUser';
import { RecalculateBatchesModal } from './admin/RecalculateBatchesModal';

const api = getConfigValue('VITE_APP_API_BASE_URL');

export const ProjectTransactionsView = () => {
  const { batchDate, programType } = useParams<{ batchDate: string; programType: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    isOpen: isApproveModalOpen,
    handleOpen: handleOpenApproveModal,
    handleClose: handleCloseApproveModal,
  } = useOpenClose();
  const {
    isOpen: isConfirmRemoveTransactionModalOpen,
    handleOpen: handleOpenConfirmRemoveTransaction,
    handleClose: handleCloseConfirmRemoveTransaction,
  } = useOpenClose();
  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const {
    isOpen: isRecalculateBatchOpen,
    handleOpen: handleRecalculateBatchModal,
    handleClose: handleCloseRecalculateBatchModal,
  } = useOpenClose();
  const [createTransaction] = useCreateProjectTransactionMutation();
  const status = searchParams.get('status') as ProjectTransactionStatus;
  const payeeType = searchParams.get('payeeType') as PayeeType;
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    batchDate,
    payeeType,
    ...(status ? { status } : {}),
  });
  // Not using RTK query to have the browser download directly
  const exportUrl = `${api}/payouts/transactions?${buildQueryString({ batchDate: batchDate ?? '', payeeType: payeeType ?? '', format: 'csv', ...(status ? { status } : {}) })}`;
  const [approveBatches] = useApproveBatchesMutation();
  const [unbatchTransaction] = useUnbatchTransactionMutation();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isLoading = isTransactionsLoading;
  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );

  const payeesWithNegativeTotals = useMemo(() => {
    const payeeTotals = transactions?.reduce(
      (acc, transaction) => {
        const payee = transaction.payeeName as unknown as string;
        if (!acc[payee]) {
          acc[payee] = { total: 0, batchId: transaction.batchId?.toString() || '' };
        }
        acc[payee].total += transaction.amount;
        return acc;
      },
      {} as Record<string, { total: number; batchId: string }>,
    );
    const negativePayeeTotals = [];
    for (const payee in payeeTotals) {
      if (payeeTotals[payee].total < 0) {
        negativePayeeTotals.push({
          payeeName: payee,
          total: payeeTotals[payee].total,
          batchId: payeeTotals[payee].batchId,
        });
      }
    }
    return negativePayeeTotals;
  }, [transactions]);
  const batchDateDisplay = useMemo(() => {
    return batchDate ? DateTime.fromISO(batchDate).toFormat('EEEE MMM d, yyyy') : '';
  }, [batchDate]);

  const batchIds = useMemo(() => {
    return transactions?.map((transaction) => transaction.batchId as unknown as string) ?? ([] satisfies string[]);
  }, [transactions]);

  const transactionTotalAmount = useMemo(() => {
    return transactions?.reduce((acc, transaction) => acc + transaction.amount, 0) ?? 0;
  }, [transactions]);

  const approveBatch = useCallback(async () => {
    try {
      await approveBatches({ batchIds: [...new Set(batchIds)] }).unwrap();
      handleCloseApproveModal();
      toast.success('Batch approved successfully');
      navigate(`/admin/payouts/${programType}/queued`);
    } catch (error) {
      console.log(error);
      toast.error('Could not approve batch');
    }
  }, [approveBatches, batchIds, programType]);

  const removeSelectedTransactions = useCallback(async () => {
    try {
      for (const id of selectedIds) {
        await unbatchTransaction({ transactionId: id }).unwrap();
      }
      handleCloseConfirmRemoveTransaction();
      setSelectedIds([]);
      toast.success('Transactions removed successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not remove transactions');
    }
  }, [selectedIds, unbatchTransaction]);

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction({
        ...values,
        ...(values.clawbackCategory
          ? { description: `${values.clawbackCategory} - ${values.description ?? ''}` }
          : {}),
      }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <RecalculateBatchesModal
        isOpen={isRecalculateBatchOpen}
        payeesWithNegativeTotals={payeesWithNegativeTotals}
        onClose={handleCloseRecalculateBatchModal}
      />
      <ApproveBatchConfirmationModal
        isOpen={isApproveModalOpen}
        onConfirm={approveBatch}
        onClose={handleCloseApproveModal}
        amount={transactionTotalAmount}
        batchDate={batchDate}
      />
      <RemoveTransactionsConfirmationModal
        isOpen={isConfirmRemoveTransactionModalOpen}
        onClose={handleCloseConfirmRemoveTransaction}
        onConfirm={removeSelectedTransactions}
        count={selectedIds?.length || 0}
        total={selectedIds.reduce((acc, id) => {
          const transaction = transactions?.find((transaction) => transaction.id === id);
          return acc + (transaction?.amount ?? 0);
        }, 0)}
      />
      <AddProjectTransactionModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Payout"
        submitButtonText="Add Payout"
        modalId="addPaymentModal"
        handleSubmit={handleAddTransaction}
        batchDate={batchDate}
        payeeType={payeeType}
      />
      <Card padding="sm" marginTop="0">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="xl" fontWeight="medium">
              {capitalize(payeeType)} Payouts - {batchDateDisplay}
            </Box>
            <RequirePayoutsAccountingAdminUser>
              {status === ProjectTransactionStatus.pending && (
                <Box id="toolbar" direction="row" alignSelf="flex-end" childGap="sm">
                  <Button
                    iconPrefix="tools"
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    onClick={handleRecalculateBatchModal}
                    disabled={isLoading}
                  >
                    Recalculate Batch
                  </Button>
                  <Button
                    iconPrefix="check"
                    size="sm"
                    variant="primary"
                    onClick={handleOpenApproveModal}
                    disabled={isLoading || payeesWithNegativeTotals.length > 0}
                  >
                    Approve
                  </Button>
                  <Button
                    iconPrefix="add"
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    disabled={isLoading}
                    onClick={handleOpenAddProjectTransactionModal}
                  >
                    Add Payout
                  </Button>
                  {!isLoading && selectedIds?.length > 0 && (
                    <Box childGap="sm">
                      <Button
                        size="sm"
                        prefix="trash"
                        variant="primary"
                        tone="danger"
                        onClick={handleOpenConfirmRemoveTransaction}
                      >
                        Remove Transaction{selectedIds.length > 1 ? 's' : ''}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </RequirePayoutsAccountingAdminUser>
          </Box>
        </Card.Header>
        <Box margin="0 lg 0 lg">
          {transactions && (
            <FilterableProjectTransactionTable
              transactions={transactions}
              handleRowSelected={handleRowSelected}
              selectedIds={selectedIds}
              exportUrl={exportUrl}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
