import { ApprovedVendorSettingValue } from 'types';

export interface Option {
  id?: string;
  value: string;
  label: string;
}
export const mapAvlManufacturerOptions = (options: ApprovedVendorSettingValue[]): Option[] =>
  options
    .reduce((acc, item) => {
      const option = {
        value: item.manufacturer ?? item.name,
        label: item.manufacturer ?? item.name,
      };
      if (!item.domesticContentSupported && !acc.some((item) => item.value === option.value)) {
        acc.push(option);
      }
      return acc;
    }, [] as Option[])
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

export const mapAvlManufacturerOptionsForDomesticContent = (options: ApprovedVendorSettingValue[]): Option[] =>
  options
    .reduce((acc, item) => {
      const option = {
        value: item.manufacturer + item.sku,
        label: `${item.manufacturer} - ${item.sku}`,
        manufacturer: item.manufacturer,
        category: item.category,
        sku: item.sku,
      };
      if (item.domesticContentSupported) {
        acc.push(option);
      }
      return acc;
    }, [] as Option[])
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

export const mapAvlModelNumberOptions = (options: ApprovedVendorSettingValue[], manufacturer: string): Option[] =>
  options
    .filter((item) => item.manufacturer === manufacturer)
    .reduce((acc, item) => {
      const option = {
        value: item.modelNumber ?? item.name,
        label: item.modelNumber ?? item.name,
      };
      if (!acc.some((item) => item.value === option.value)) {
        acc.push(option);
      }
      return acc;
    }, [] as Option[])
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

export const maybePrependCustomFreetextOption = (options: Option[], value: string) => {
  if (value && !options.find((option) => option.value === value)) {
    options.unshift({ value, label: `* ${value}` });
  }
  return options;
};
