import {
  Box,
  Button,
  FormikSelectInputNative,
  FormikTextInput,
  Modal,
  SelectInput,
  toast,
  Toggle,
} from '@palmetto/palmetto-components';
import { ChangeEvent, useMemo } from 'react';
import { Form } from 'react-router-dom';
import * as yup from 'yup';
import { getStateOptions } from '../../helpers/getStateOptions';
import { Field, Formik } from 'formik';
import { ProgramType, ProgramTypeLabels, UtilityDocument, UtilityStatus } from 'types';
import { isErrorWithData } from '../../services/helpers';
import { useUpdateUtilityMutation } from '../../services/utilities';

const marketSchema = yup.object().shape({
  state: yup.string().required('State is a required field.'),
  name: yup.string().required('Utility Name is a required field.'),
  lseId: yup.number().required('LSE ID is a required field.'),
  contractType: yup.string().required('Contract Type is a required field.'),
  status: yup.string().required('Status is a required field.'),
  programTypes: yup.array().required('Program Types is a required field.'),
});
export type EditMarketSchema = yup.InferType<typeof marketSchema>;
export const EditMarketModal = ({
  isOpen,
  handleClose,
  market,
}: {
  isOpen: boolean;
  handleClose: () => void;
  market: UtilityDocument & { id: string };
}) => {
  const initialValues: any = useMemo(
    () => ({
      name: market?.name,
      state: market?.state,
      contractType: market?.contractType,
      lseId: market?.lseId,
      programTypes: market?.programTypes?.map((programType) => ({
        value: programType,
        label: ProgramTypeLabels[programType],
      })),
      status: market?.status,
    }),
    [market],
  );
  const [updateUtility] = useUpdateUtilityMutation();
  const handleSubmit = async (values: EditMarketSchema) => {
    try {
      await updateUtility({
        id: market?.id,
        marketData: { ...values, programTypes: values.programTypes.map((v: any) => v.value) },
      }).unwrap();
      toast.success('Market updated');
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error updating market');
      }
    }
  };
  return (
    initialValues && (
      <Formik
        validationSchema={marketSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, handleBlur, isSubmitting, resetForm, setFieldValue, submitForm, touched, values }) => (
          <Form noValidate>
            <Modal
              isOpen={isOpen}
              maxWidth="4xl"
              ariaLabelledBy="editMarketHeader"
              onDismiss={() => {
                resetForm();
                handleClose();
              }}
            >
              <Modal.Header
                id="editMarketHeader"
                title="Edit Market"
                onDismiss={() => {
                  resetForm();
                  handleClose();
                }}
              />
              <Modal.Body background="secondary" childGap="lg">
                <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                  <SelectInput
                    id="programTypes"
                    name="programTypes"
                    label="Program Types"
                    value={values.programTypes}
                    onChange={(event) => setFieldValue('programTypes', event.target.value)}
                    options={Object.values(ProgramType).map((programType) => ({
                      label: ProgramTypeLabels[programType],
                      value: programType,
                    }))}
                    onBlur={handleBlur}
                    isMulti
                    isClearable
                    isRequired
                    error={touched.programTypes && (errors.programTypes as string)}
                  />

                  <Toggle
                    id="status"
                    label="Active"
                    isChecked={values?.status === UtilityStatus.active}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('status', event.target.checked ? UtilityStatus.active : UtilityStatus.inactive);
                    }}
                  />

                  <Field
                    type="text"
                    label="State"
                    name="state"
                    id="state"
                    options={getStateOptions(true)}
                    component={FormikSelectInputNative}
                    isRequired
                  />

                  <Field
                    type="text"
                    label="Utility Name"
                    name="name"
                    id="name"
                    component={FormikTextInput}
                    isRequired
                    placeholder="eg. Pacific Gas & Electric"
                  />

                  <Field
                    maxLength={5}
                    type="number"
                    label="Load Serving Entity (LSE) ID"
                    name="lseId"
                    id="lseId"
                    component={FormikTextInput}
                    isRequired
                    placeholder="eg. 734"
                  />

                  <Field
                    type="text"
                    label="Contract Type"
                    name="contractType"
                    id="contractType"
                    options={[
                      { value: 'ppa', label: 'PPA' },
                      { value: 'lease', label: 'Lease' },
                    ]}
                    component={FormikSelectInputNative}
                    isRequired
                  />
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  tone="neutral"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  type="submit"
                  onClick={submitForm}
                >
                  Edit Market
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    )
  );
};
