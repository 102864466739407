import { useEffect } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { toast } from '@palmetto/palmetto-components';

export const useFormikAutoScroll = () => {
  const formik: FormikContextType<any> = useFormikContext();

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      const findFirstErrorPath = (obj: any, parentPath = ''): string | null => {
        for (const key in obj) {
          const currentPath = parentPath ? `${parentPath}.${key}` : key;
          if (typeof obj[key] === 'string') {
            return currentPath;
          }
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            const nestedError = findFirstErrorPath(obj[key], currentPath);
            if (nestedError) return nestedError;
          }
        }
        return null;
      };

      try {
        const firstErrorPath = findFirstErrorPath(formik.errors);
        if (firstErrorPath) {
          const errorElement = (document.querySelector(`[name="${firstErrorPath}"]`) ||
            document.querySelector(`[id="${firstErrorPath}"]`) ||
            document.querySelector(`[data-testid="${firstErrorPath}"]`) ||
            document.querySelector(`[aria-label*="${firstErrorPath}"]`) ||
            document.querySelector(`div[class*="Select"][id*="${firstErrorPath}"]`) ||
            document.querySelector(`label[for="${firstErrorPath}"]`)?.parentElement) as HTMLElement | null;

          if (errorElement) {
            const scrollContainer =
              errorElement.closest('[class*="Box"]') || errorElement.closest('section') || errorElement;

            const elementRect = scrollContainer.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.scrollY;
            const offset = window.innerHeight / 4;
            window.scrollTo({
              top: absoluteElementTop - offset,
              behavior: 'smooth',
            });
            setTimeout(() => {
              const focusableElement = errorElement.querySelector('input, select, textarea') as HTMLElement;
              if (focusableElement && typeof focusableElement.focus === 'function') {
                try {
                  focusableElement.focus();
                } catch (focusError) {
                  console.error('Error focusing element:', focusError);
                }
              }
            }, 100);
          }
        }
      } catch (error) {
        console.error('Error in auto scroll effect:', error);
        toast.error('An issue was encountered while processing the form');
      }
    }
  }, [formik.isSubmitting, formik.errors]);
};
