import { ObjectId } from 'mongodb';
import { MilestoneTemplateType } from './';

/**
 * @deprecated This is replaced by AccountStipulationType
 */
export enum StipulationType {
  achRequirement = 'achRequirement',
  creditCheckConsent = 'creditCheckConsent',
  employmentVerification = 'employmentVerification',
  identityVerification = 'identityVerification',
  incomeVerification = 'incomeVerification',
  prequalificationConsent = 'prequalificationConsent',
  titleVerification = 'titleVerification',
  txtMessageNotificationsConsent = 'txtMessageNotificationsConsent',
  termsAndConditions = 'termsAndConditions',
}

/**
 * @deprecated This is replaced by AccountMilestoneStipulationDocument
 */
export type Stipulation = {
  stipulationType: StipulationType;
  description: string;
  isSatisfied?: boolean;
  requiresReview?: boolean;
};

export enum AccountStipulationType {
  administrative = 'administrative',
  designVerification = 'designVerification',
}

export enum AccountStipulationReason {
  accountDetails = 'Account Details',
  consumerCompliance = 'Consumer Compliance',
  housingStock = 'Housing Stock',
  ageRelated = 'Age Related',
  potentialOFACMatch = 'Potential OFAC Match',
  other = 'Other',
}

export type AccountMilestoneStipulationDocument = {
  id: ObjectId;
  accountId: ObjectId;
  accountMilestoneType: MilestoneTemplateType;
  accountMilestoneId: ObjectId;
  appliedBy: string;
  appliedByDisplayName: string;
  appliedDate: Date;
  stipulationType: AccountStipulationType;
  description: string;
  isSatisfied: boolean;
  isSatisfiedNote: string;
  reason?: AccountStipulationReason;
  requiresReview: boolean;
  isAppliedByAutomation?: boolean;
};

export type AccountMilestoneStipulation = {
  customerName: string;
  milestoneType: string;
  customerAddress: string;
  customerAccountLink: string;
};
