import { Box, Button, CheckboxInput, Modal, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import { useEditTrancheAccountMutation } from '../../services/funds';
import { DateTime } from 'luxon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TrancheStatus } from 'types';

export const EditTrancheModal = ({
  fundKey,
  selectedTranche,
  isModalOpen,
  closeModal,
}: {
  fundKey: string;
  selectedTranche: any;
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
}) => {
  const [editTrancheAccount] = useEditTrancheAccountMutation();
  const { ptoGreenlight = false } = useFlags();
  const formik = useFormik({
    initialValues: {
      name: selectedTranche?.name,
      submittedDate: selectedTranche?.submittedDate
        ? DateTime.fromISO(selectedTranche.submittedDate).toFormat('MM-dd-yyyy')
        : undefined,
      trancheDate: selectedTranche?.trancheDate
        ? DateTime.fromISO(selectedTranche.trancheDate).toFormat('MM-dd-yyyy')
        : undefined,
      isClosed: selectedTranche?.trancheStatus === TrancheStatus.closed,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      submittedDate: yup.string().required('Proposed Date is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        editTrancheAccount({
          fundKey,
          data: {
            submittedDate: DateTime.fromFormat(values.submittedDate, 'MM-dd-yyyy').toISO(),
            trancheDate: DateTime.fromFormat(values.trancheDate, 'MM-dd-yyyy').toISO(),
            isClosed: values.isClosed,
          },
          trancheKey: selectedTranche.name,
        }).unwrap();
        toast.success('Tranche edited successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error editing tranche');
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Modal ariaLabelledBy="editTranche" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header id="editTranche" title={'Edit Tranche'} onDismiss={closeModal} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="md">
            <Box>
              <Box margin="0 0 sm 0">Tranche Name:</Box>
              <Box>{formik.values.name}</Box>
            </Box>
            <TextInput
              type="text"
              label="Proposed Date"
              name="submittedDate"
              id="submittedDate"
              value={formik.values.submittedDate}
              placeholder="MM-DD-YYYY"
              inputMask="date"
              onChange={(event) => formik.setFieldValue('submittedDate', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.submittedDate && (formik.errors.submittedDate as string)}
            />
            <TextInput
              type="text"
              label="Accepted Date"
              name="trancheDate"
              id="trancheDate"
              placeholder="MM-DD-YYYY"
              inputMask="date"
              value={formik.values.trancheDate}
              onChange={(event) => formik.setFieldValue('trancheDate', event.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.trancheDate && (formik.errors.trancheDate as string)}
            />
            {ptoGreenlight && (
              <CheckboxInput
                id="isClosed"
                name="isClosed"
                label="Tranche is closed"
                onChange={(event) => formik.setFieldValue('isClosed', event.target.checked)}
                isChecked={formik.values.isClosed}
                isDisabled={formik.isSubmitting}
              />
            )}
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
