import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountDocument, ProgramType } from 'types';

export const checkDomesticContentEligibility = (account: AccountDocument): boolean => {
  const { domesticContent = false } = useFlags();

  const isDomesticContentEligible = account?.itcAdderQualifications?.domesticContentEligible;
  const isDomesticContentParticipant = (account.systemDesign as any)?.domesticContentAttestment?.valueConfirmation;

  return domesticContent && isDomesticContentEligible && isDomesticContentParticipant;
};
