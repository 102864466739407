import { Box, TextLink } from '@palmetto/palmetto-components';
import { AccountMilestoneStipulation } from '../../types/Accounts';
import DateTimeDisplay from '../DateTime';
import { AccountStipulationReason } from '@/../../types/src';
import { HiddenLightReachInfoForEpcUser } from '../auth/HiddenLightReachInfoToEpcUser';

interface AdminStipulationDetailsProps {
  stipulation: AccountMilestoneStipulation;
  forBanner?: boolean;
}

const AgeRelatedDescription = () => {
  return (
    <Box as="em" fontSize="sm">
      <p>Consumer Protection Task: Customer must complete review of key contract details.</p>
      <p>To clear stip:</p>
      <p>
        1) Customer logs in to their{' '}
        <TextLink href="https://home.palmetto.com" target="_blank" rel="noreferrer">
          home.palmetto.com
        </TextLink>{' '}
        account.
      </p>
      <p>{'2) Customer completes task by reviewing the details, checking the boxes, and "signing" their name.'}</p>
      <p>{'3) Customer clicks SUBMIT'}</p>
      <p>{'4) Upon submission, this stip will clear automatically.'}</p>
      <p>
        Installer reference guide:{' '}
        <TextLink
          href="https://help.palmetto.finance/en/articles/8284546-4-completing-ntp-m0-requirements"
          target="_blank"
          rel="noreferrer"
        >
          https://help.palmetto.finance/en/articles/8284546-4-completing-ntp-m0-requirements
        </TextLink>{' '}
      </p>
    </Box>
  );
};

const AdminStipulationDetails = ({ stipulation, forBanner }: AdminStipulationDetailsProps) => {
  const { description, reason, appliedBy, appliedByDisplayName, appliedDate } = stipulation || {};
  const isAgeRelated = reason === AccountStipulationReason.ageRelated;

  const reasonText = isAgeRelated ? 'Customer Action Required' : reason;
  const isPotentialAgeRelatedStipulationAddedByAutomation = isAgeRelated && appliedBy === 'unknown';
  const appliedByText = isPotentialAgeRelatedStipulationAddedByAutomation
    ? 'LightReach Support'
    : appliedByDisplayName || appliedBy;

  return (
    <Box
      flex="auto"
      gap="xs"
      padding={forBanner ? 'lg' : '0'}
      fontSize="sm"
      fontWeight="medium"
      justifyContent="center"
    >
      <Box direction="row" gap="2xs">
        {reason && (
          <>
            <Box fontWeight="bold">{reasonText}</Box>
            {' - '}
          </>
        )}
        {isPotentialAgeRelatedStipulationAddedByAutomation ? (
          <AgeRelatedDescription />
        ) : (
          <Box as="em">{description}</Box>
        )}
      </Box>
      {appliedDate && (
        <Box direction="row" gap="2xs" fontSize="xs">
          <DateTimeDisplay value={appliedDate?.toString()} fontSize="xs" dateFormat="DD h:mm a" inline /> by{' '}
          <HiddenLightReachInfoForEpcUser fallbackChildren="LightReach Support">
            {appliedByText}
          </HiddenLightReachInfoForEpcUser>
        </Box>
      )}
    </Box>
  );
};

export default AdminStipulationDetails;
