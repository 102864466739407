import { Box, Button, Modal } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { SyntheticEvent } from 'react';

export const convertIsoToDateMed = (isoDate: string) =>
  isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED) : null;

export const ReCheckSalesRepModal = ({
  isModalOpen,
  closeModal,
  salesRepAccountData,
  salesRepReCheckStatus,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  salesRepAccountData: any;
  salesRepReCheckStatus: string;
}) => {
  return (
    <Modal ariaLabelledBy="salesRepReCheckInfo" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="600px">
      <Modal.Header id="salesRepReCheckInfo" title="Sales Rep Verification Info" onDismiss={closeModal} />
      <form>
        <Modal.Body background="grey-50">
          <Box direction="column" childGap="lg" padding="lg">
            {salesRepAccountData?.photoUrl ? (
              <Box direction="row" justifyContent="center" margin={'0 0 md 0'}>
                <img
                  src={salesRepAccountData.photoUrl}
                  alt={`${salesRepAccountData?.displayName || 'Sales Rep'} Photo`}
                  style={{
                    maxWidth: '120px',
                    maxHeight: '120px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    border: '3px solid #ddd',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onError={(e) => {
                    e.currentTarget.src = 'https://via.placeholder.com/120';
                  }}
                />
              </Box>
            ) : (
              <Box direction="row" justifyContent="center" margin={'0 0 md 0'}>
                <img
                  src="https://via.placeholder.com/120"
                  alt="Fallback Photo"
                  style={{
                    maxWidth: '120px',
                    maxHeight: '120px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            )}

            <Box
              fontSize="sm"
              fontWeight="bold"
              textAlign="center"
              padding="sm"
              background="grey-100"
              margin={'0 0 lg 0'}
              style={{ borderRadius: '8px' }}
            >
              Status: {salesRepReCheckStatus?.toUpperCase()}
            </Box>

            <Box direction="column" childGap="md">
              <InfoRow label="Legal Name" value={salesRepAccountData?.legalName} />
              <InfoRow label="Display Name" value={salesRepAccountData?.displayName} />
              <InfoRow label="Recheck Id" value={salesRepAccountData?.idProviderId} />
              <InfoRow
                label="Verification Date"
                value={convertIsoToDateMed(salesRepAccountData.idProviderVerificationDate)}
              />

              {salesRepAccountData?.emails?.map((email: string, index: number) => (
                <InfoRow key={index} label={`Email ${index + 1}`} value={email} />
              ))}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button as="button" onClick={closeModal} variant="secondary" tone="neutral" size="md">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const InfoRow = ({ label, value }: { label: string; value: string | null | undefined }) => (
  <Box direction="row" justifyContent="space-between" childGap="lg" padding="sm" alignItems="center">
    <Box fontSize="sm" fontWeight="bold" color="grey-700" width="40%">
      {label}:
    </Box>
    <Box fontSize="sm" fontWeight="medium" color="grey-900" width="60%" textAlign="right">
      {value || 'N/A'}
    </Box>
  </Box>
);
