import { Box, Button, FormikSelectInputNative, Modal, toast } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Form } from 'react-router-dom';
import * as yup from 'yup';
import { getStateOptions } from '../../helpers/getStateOptions';
import { Field, Formik } from 'formik';
import {
  FinanceType,
  FinanceTypeLabels,
  Locale,
  OrganizationContractDocumentSettings,
  ProgramType,
  ProgramTypeLabels,
} from 'types';
import { isErrorWithData } from '../../services/helpers';
import { useUpdateTemplateMutation } from '@/services/contractTemplates';
import { AddContractModalHeader } from './AddContractModal';
import { DocuSignTemplateIdField } from './DocuSignTemplateIdField';

const templateIdRegEx = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/;

/** The bare resetForm() call does not seem to work here without explicitly setting the values. */
const clearedFormState = {
  values: {
    programType: '',
    state: '',
    englishTemplateId: '',
    englishCosignTemplateId: '',
    spanishTemplateId: '',
    spanishCosignTemplateId: '',
    contractType: undefined,
  },
};

export const templateSchema = yup.object().shape({
  programType: yup.string().required('Program type is required'),
  state: yup.string().required('State is required'),
  englishTemplateId: yup
    .string()
    .matches(templateIdRegEx, { message: 'Must be a DocuSign UUID' })
    .required('English template ID is required'),
  englishCosignTemplateId: yup
    .string()
    .matches(templateIdRegEx, { message: 'Must be a DocuSign UUID' })
    .required('English co-borrower template ID is required'),
  spanishTemplateId: yup.string().matches(templateIdRegEx, { message: 'Must be a DocuSign UUID' }),
  spanishCosignTemplateId: yup.string().matches(templateIdRegEx, { message: 'Must be a DocuSign UUID' }),
  contractType: yup.string().oneOf(Object.values(FinanceType)),
});
export type EditContractSchema = yup.InferType<typeof templateSchema>;

export const getTemplateId = (
  template: OrganizationContractDocumentSettings,
  language: Locale,
  coborrower: boolean,
) =>
  template?.contractSettings?.contractDocumentSettings?.templates?.find(
    (t) => t.language === language && t.coborrower === coborrower,
  )?.templateId;

export const EditContractModal = ({
  isOpen,
  handleClose,
  template,
}: {
  isOpen: boolean;
  handleClose: () => void;
  template: OrganizationContractDocumentSettings;
}) => {
  const initialValues: any = useMemo(
    () => ({
      programType: template?.programType,
      state: template?.contractSettings?.state,
      contractType: template?.contractSettings.type,
      englishTemplateId: getTemplateId(template, Locale.en, false),
      englishCosignTemplateId: getTemplateId(template, Locale.en, true),
      spanishTemplateId: getTemplateId(template, Locale.es, false),
      spanishCosignTemplateId: getTemplateId(template, Locale.es, true),
    }),
    [template],
  );
  const [updateTemplate] = useUpdateTemplateMutation();
  const handleSubmit = async (values: EditContractSchema) => {
    try {
      await updateTemplate({
        organizationSettingsId: template?.organizationSettingsId as any, // TODO Fix type
        contractDocumentSettingsId: template?.contractSettings.contractDocumentSettingsId as any, // TODO Fix type
        templateData: values,
      }).unwrap();
      toast.success('Contract template updated');
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error updating contract template');
      }
    }
  };
  return (
    initialValues && (
      <Formik
        validationSchema={templateSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, resetForm, submitForm, values }) => (
          <Form noValidate>
            <Modal
              isOpen={isOpen}
              maxWidth="4xl"
              ariaLabelledBy="editContractHeader"
              onDismiss={() => {
                resetForm(clearedFormState);
                handleClose();
              }}
            >
              <Modal.Header
                id="editContractHeader"
                title="Edit Contract"
                onDismiss={() => {
                  resetForm(clearedFormState);
                  handleClose();
                }}
              />
              <Modal.Body background="secondary" childGap="lg">
                <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                  <AddContractModalHeader />
                  <Field
                    id="programType"
                    name="programType"
                    label="Program Type"
                    options={Object.values(ProgramType).map((programType) => ({
                      label: ProgramTypeLabels[programType],
                      value: programType,
                    }))}
                    component={FormikSelectInputNative}
                    isRequired
                    isDisabled
                  />

                  <Field
                    type="text"
                    label="State"
                    name="state"
                    id="state"
                    options={getStateOptions(true)}
                    component={FormikSelectInputNative}
                    isRequired
                  />

                  <Field
                    type="text"
                    label="Contract Type"
                    name="contractType"
                    id="contractType"
                    options={Object.values(FinanceType).map((type) => ({
                      label: FinanceTypeLabels[type],
                      value: type,
                    }))}
                    component={FormikSelectInputNative}
                    isRequired
                  />

                  <DocuSignTemplateIdField
                    label="English Template ID"
                    name="englishTemplateId"
                    id="englishTemplateId"
                    isRequired
                  />

                  <DocuSignTemplateIdField
                    label="English Co-Borrower Template ID"
                    name="englishCosignTemplateId"
                    id="englishCosignTemplateId"
                    isRequired
                  />

                  <DocuSignTemplateIdField
                    label="Spanish Template ID"
                    name="spanishTemplateId"
                    id="spanishTemplateId"
                  />

                  <DocuSignTemplateIdField
                    label="Spanish Co-Borrower Template ID"
                    name="spanishCosignTemplateId"
                    id="spanishCosignTemplateId"
                  />
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  tone="neutral"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  onClick={() => {
                    resetForm(clearedFormState);
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  type="submit"
                  onClick={submitForm}
                >
                  Edit Contract
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    )
  );
};
