import { ObjectId } from 'mongodb';
import {
  ActivityHistoryEventType,
  AllDocumentTypesArr,
  EntityType,
  Meta,
  MilestoneRequirement,
  MilestoneType,
  QueueType,
} from './';

export enum TaskAssigneeType {
  any = 'any-assignee',
  consumer = 'consumer',
  internal = 'internal',
  partner = 'partner',
}

export enum TaskStatus {
  ready = 'ready',
  pending = 'pending',
  completed = 'completed',
  rejected = 'rejected',
  voided = 'voided',
}

export enum TaskType {
  reviewContract = 'reviewContract',
  reviewDisclosureDocument = 'reviewDisclosureDocument',
  reviewDomesticContent = 'reviewDomesticContent',
  reviewElectricianSignOff = 'reviewElectricianSignOff',
  reviewUploadedDocument = 'reviewUploadedDocument',
  reviewIdentity = 'reviewIdentity',
  reviewInstall = 'reviewInstall',
  reviewNTP = 'reviewNTP',
  reviewPermissionToOperate = 'reviewPermissionToOperate',
  reviewSupportingDocument = 'reviewSupportingDocument',
  reviewSystemActivation = 'reviewSystemActivation',
  reviewSystemPostActivation = 'reviewSystemPostActivation',
  reviewTitle = 'reviewTitle',
  reviewSiteAssessment = 'reviewSiteAssessment',
  achRequirement = 'achRequirement',
  identityVerification = 'identityVerification',
  titleVerification = 'titleVerification',
  dxfFile = 'dxfFile',
  shadeReport = 'shadeReport',
  planSet = 'planSet',
  permit = 'permit',
  projectSite = 'projectSite',
  roof = 'roof',
  electrical = 'electrical',
  storage = 'storage',
  systemCommissioning = 'systemCommissioning',
  monitoringSiteId = 'monitoringSiteId',
  productionModel = 'productionModel',
  ptoConfirmation = 'ptoConfirmation',
  extendedWarranty = 'extendedWarranty',
  incentives = 'incentives',
  siteImprovements = 'siteImprovements',
  voidedCheck = 'voidedCheck',
  reviewUtilityBill = 'reviewUtilityBill',
  utilityBillRequirement = 'utilityBillRequirement', // For Puerto Rico
  electricianSignOff = 'electricianSignOff',
  customerSignOff = 'customerSignOff',
}

export const ntpTaskTypes = [
  TaskType.achRequirement,
  TaskType.identityVerification,
  TaskType.reviewIdentity,
  TaskType.reviewTitle,
  TaskType.voidedCheck,
];

export const systemActivationTypes = [
  TaskType.ptoConfirmation,
  TaskType.extendedWarranty,
  TaskType.incentives,
  TaskType.siteImprovements,
  TaskType.monitoringSiteId,
];

export const installTaskTypes = [
  TaskType.dxfFile,
  TaskType.shadeReport,
  TaskType.planSet,
  TaskType.permit,
  TaskType.productionModel,
  TaskType.projectSite,
  TaskType.roof,
  TaskType.electrical,
  TaskType.storage,
  TaskType.systemCommissioning,
  TaskType.electricianSignOff,
  TaskType.customerSignOff,
];

export enum TaskActivityType {
  contract = 'contract',
  requirement = 'requirement',
  review = 'review',
  stipulation = 'stipulation',
}

export interface TaskReferenceIdObject {
  id: ObjectId;
  type: EntityType;
}

export enum TaskRejectionReason {
  documentRejected = 'documentRejected',
  moreInformationNeeded = 'moreInformationNeeded',
  notApplicable = 'notApplicable',
  tryAgain = 'tryAgain',
  notCompleted = 'Not Completed',
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  codeViolation = 'Code Violation',
  poorWorkmanship = 'Poor Workmanship',
  ownershipAccessRequired = 'Ownership Access Required',
  layoutNotMapped = 'Layout Not Mapped',
  noCommunication = 'No Communication',
  systemSettingsIncorrect = 'System Settings Incorrect',
}

export interface RejectionReasons {
  reasons?: TaskRejectionReason[];
  note?: string;
  rejectedAt: Date;
  rejectedBy: string | undefined; // auth0id == userId
}

export interface TaskDocument {
  activityType: TaskActivityType;
  assigneeType: TaskAssigneeType;
  assignedTo?: string; // auth0id == userId
  associatedDocumentTypes?: typeof AllDocumentTypesArr | undefined;
  completedAt?: Date;
  createdAt?: Date;
  description: string;
  isQueueable?: boolean;
  id: ObjectId;
  meta?: Meta;
  name: string;
  queueType?: QueueType;
  referenceIds: TaskReferenceIdObject[];
  rejectionReasons?: RejectionReasons[];
  requirementType?: MilestoneRequirement;
  resubmittedEventType?: ActivityHistoryEventType;
  submittedEventType?: ActivityHistoryEventType;
  completedEventType?: ActivityHistoryEventType;
  startedAt?: Date;
  status: TaskStatus;
  type: TaskType;
  milestone?: MilestoneType;
}
