import { ObjectId } from 'mongodb';
import { ContractSetting, OrganizationContractSettingsDocument } from './OrganizationSettings';

export enum ContractDocumentProviders {
  docusign = 'docusign',
  illinoisShines = 'illinoisShines',
}

export interface ContractDocumentSettingsFilter {
  enabled: boolean;
  type: string;
  value: string;
}

export interface ContractDocumentTemplates {
  templateId: string;
  language: string;
  coborrower: boolean;
  enabled: boolean;
}

export interface ContractDocumentSettings {
  id: ObjectId;
  enabled: boolean;
  name: string;
  isEPCContract?: boolean;
  versionId: string;
  versionNumber: number;
  type: string;
  provider: ContractDocumentProviders;
  providerData: {
    /** @warning Careful with this, it's overridden by the one from the templates array. */
    templateId?: string | null;
  };
  templateData: Record<string, any>;
  filters: Array<ContractDocumentSettingsFilter>;
  templates?: Array<ContractDocumentTemplates>;
}

export interface JoinedContractDocumentSetting extends ContractSetting {
  contractDocumentSettings: ContractDocumentSettings;
}

/** This a join of the org settings' contractSettings type and matched ContractDocumentSettings docs */
export interface OrganizationContractDocumentSettings extends Omit<OrganizationContractSettingsDocument, 'contractSettings' | 'id'> {
  key: string;
  organizationSettingsId: ObjectId;
  contractSettings: JoinedContractDocumentSetting;
}