import { useLazySearchOrganizationsQuery } from '@/services/organizations';
import { Form, Formik, useFormikContext } from 'formik';
import { useState } from 'react';
import { sortByLabel } from '../Pricing/Mappings/util';
import { AsyncSelectInput } from '../Forms/AsyncSelect';
import { Box, Button, toast } from '@palmetto/palmetto-components';
import { useRunTaskMutation } from '@/services/adminTasks';

function debounceAsync(func: (input: string) => Promise<any>, delay: number): (input: string) => Promise<any> {
  let timer: NodeJS.Timeout | null = null;
  let pendingPromise: Promise<any> | null = null;

  return (input: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    pendingPromise = new Promise((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          if (!input || input === '' || input.length < 3) {
            resolve([]);
          }
          const result = await func(input);
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          timer = null;
        }
      }, delay);
    });

    return pendingPromise;
  };
}

export const OrganizationVisibility = () => {
  const [runTask] = useRunTaskMutation();

  const onSubmit = async (values: any) => {
    const toastId = toast.loading('Updating Organization Visibility');
    try {
      const confirmed = window.confirm(
        `Are you sure you want to make ${values.orgsToBeVisible.map((org: any) => org.value).join(', ')} visible to ${values.orgToAddVisibility.value}? This action cannot be undone.`,
      );

      if (!confirmed) return;
      await runTask({
        task: 'orgVisibility',
        orgsToBeVisible: values.orgsToBeVisible.map((org: any) => org.value),
        orgToAddVisibility: values.orgToAddVisibility.value,
      });
      toast.success('Updated visibility successfully');
    } catch (e: any) {
      console.error(e);
      toast.error(e?.data?.message || 'Error updating visibility');
    } finally {
      toast.dismiss(toastId);
    }
  };
  return (
    <Box padding="xl">
      <Formik
        initialValues={{
          orgsToBeVisible: [],
          orgToAddVisibility: '',
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <Box gap="md">
            <OrgVisibilityFormFields />
            <Box>
              <Button size="md" variant="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export const OrgVisibilityFormFields = () => {
  const [triggerSearch] = useLazySearchOrganizationsQuery();

  const handleSearchText = async (searchText: string) => {
    const results = await triggerSearch({
      searchTerm: searchText,
      useRootOrganization: true,
    });

    return results?.data?.flat
      ?.map((org) => ({
        value: org.alias,
        label: `${org.name} (${org.alias})`,
      }))
      .sort(sortByLabel);
  };
  const debouncedSearch = debounceAsync(handleSearchText, 400);
  const formik = useFormikContext<any>();

  return (
    <Box childGap="lg">
      <AsyncSelectInput
        id="orgsToBeVisible"
        name="orgsToBeVisible"
        label="Organizations Needing Visibility"
        loadOptions={debouncedSearch}
        value={formik.values.orgsToBeVisible}
        onChange={(event) => {
          console.log(event);
          formik.setFieldValue('orgsToBeVisible', event.target.value);
        }}
        onBlur={formik.handleBlur}
        isClearable
        isMulti
        isDisabled={formik.isSubmitting}
        noOptionsMessage={() => 'Search for an organization'}
        error={formik.touched.orgsToBeVisible && (formik.errors.orgsToBeVisible as string)}
      />

      <AsyncSelectInput
        id="orgToAddVisibility"
        name="orgToAddVisibility"
        label="Organization To Add Visibility To"
        loadOptions={debouncedSearch}
        value={formik.values.orgToAddVisibility}
        onChange={(event) => {
          formik.setFieldValue('orgToAddVisibility', event.target.value);
        }}
        onBlur={formik.handleBlur}
        isClearable
        isDisabled={formik.isSubmitting}
        noOptionsMessage={() => 'Search for an organization'}
        error={formik.touched.orgToAddVisibility && (formik.errors.orgToAddVisibility as string)}
      />
      {formik.values?.orgsToBeVisible?.length > 0 && formik.values?.orgToAddVisibility?.value ? (
        <Box padding="lg">
          This will make <b>{formik.values.orgsToBeVisible.map((org: any) => org.label).join(', ')}</b> visible to{' '}
          <b>{formik.values?.orgToAddVisibility?.label}</b>
        </Box>
      ) : null}
      <Box fontWeight="bold">
        NOTE: This does not automatically add visibility for child orgs. All orgs needing visibility will need to be
        requested separately.
      </Box>
    </Box>
  );
};
