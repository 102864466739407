import {
  Box,
  Button,
  FileUpload,
  Modal,
  SelectInputNative,
  TextareaInput,
  toast,
  useBreakpoint,
} from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { FlagDocumentTypes, FlagReasons, UserPermissions } from 'types';
import { QueueFlagsContext } from './QueueFlagsContext';
import { useUploadDocumentMutation } from '../../services/documents';
import RequirePermissions from '../auth/requirePermissions';
import { trimFileNames } from '@/helpers/trimFileNames';
import { isErrorWithData } from '@/services/helpers';
import { HTTP_STATUS_CODES } from '@/constants';

interface AddFlagModalProps {
  accountId: string;
  isOpen: boolean;
  onDismiss: () => void;
  dataKey: string;
  fileName?: string;
  baseCategory?: string;
  showUploadButton?: boolean;
  type?: string;
}

export const AddFlagModal = ({
  accountId,
  isOpen,
  onDismiss,
  dataKey,
  fileName,
  baseCategory,
  showUploadButton = false,
  type,
}: AddFlagModalProps) => {
  const [note, setNote] = useState('');
  const [reason, setReason] = useState('');
  const { addFlag, isLoading } = useContext(QueueFlagsContext);
  const [flagFiles, setFlagFiles] = useState<FileList | undefined>();
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const { isPhone } = useBreakpoint();

  const setNoteText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  }, []);
  const setReasonValue = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setReason(event.target.value);
  }, []);
  const handleFileUpload = useCallback((event: any) => {
    setFlagFiles(event.target.files);
  }, []);
  const reasonOptions = useMemo(() => {
    return Object.values(FlagReasons).map((value) => ({ value: value, label: value }));
  }, []);

  const handleHide = useCallback(() => {
    setNote('');
    setReason('' as FlagReasons);
    onDismiss();
    setFlagFiles(undefined);
  }, [onDismiss]);

  const handleSave = useCallback(async () => {
    if (isLoading || isUploadingDocument) {
      return;
    }

    if (reason === '') {
      return toast.error('Flag Reason is required');
    }

    let uploadedDocumentId = '';

    try {
      if (flagFiles) {
        const data = new FormData();
        data.append('type', FlagDocumentTypes.flag);

        for (let i = 0; i < flagFiles.length; i++) {
          data.append(`files[${i}]`, flagFiles[i]);
        }

        const uploadedDocumentResult = (await uploadDocument({
          accountId,
          formData: data,
          invalidateAllTags: false,
        }).unwrap()) as any;
        uploadedDocumentId = uploadedDocumentResult[0].id;
        uploadedDocumentResult?.[0]?.files.length > 1
          ? toast.success(`Documents uploaded`)
          : toast.success(`Document uploaded`);
      }

      await addFlag({
        dataKey,
        fileName,
        note,
        reason: reason as FlagReasons,
        baseCategory,
        fileUpload: uploadedDocumentId,
        type,
      });
    } catch (e: any) {
      if (
        e.status === HTTP_STATUS_CODES.CLIENT_ERROR_ENTITY_TOO_LARGE ||
        e.originalStatus === HTTP_STATUS_CODES.CLIENT_ERROR_ENTITY_TOO_LARGE
      ) {
        toast.error('File size too large. Please upload files smaller than 125MB.');
      } else if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        toast.error('An error occurred while uploading documents');
      }
    }
    handleHide();
  }, [
    addFlag,
    dataKey,
    fileName,
    note,
    reason,
    baseCategory,
    flagFiles,
    uploadDocument,
    handleHide,
    isLoading,
    isUploadingDocument,
  ]);

  const truncatedFileName = trimFileNames(fileName, dataKey);

  return (
    <Modal isOpen={isOpen} maxWidth="4xl" ariaLabelledBy="addFlagHeader" onDismiss={handleHide}>
      <Modal.Header id="addFlagHeader" title={`Flag ${truncatedFileName}`} onDismiss={handleHide} />
      <Modal.Body background="secondary" childGap="lg">
        <Box childGap="md">
          <SelectInputNative
            id="reason"
            label="Reason"
            value={reason}
            onChange={setReasonValue}
            options={reasonOptions}
            isRequired
          />
          <TextareaInput id="note" label="Notes (optional)" value={note} onChange={setNoteText} rows={3} />
          {showUploadButton && (
            <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
              <Box direction="row" childGap="md" alignItems="center">
                {isUploadingDocument ? (
                  <Button
                    variant="secondary"
                    tone="neutral"
                    iconPrefix="upload"
                    isDisabled={true}
                    isLoading={isUploadingDocument || isLoading}
                    size="sm"
                  >
                    Upload
                  </Button>
                ) : (
                  <FileUpload
                    labelText="Upload"
                    id={`upload-${FlagDocumentTypes.flag}-photos`}
                    name={`${FlagDocumentTypes.flag}-files`}
                    accept="image/*,.pdf"
                    size="sm"
                    multiple
                    onChange={handleFileUpload}
                    buttonText={!isPhone ? 'Upload file(s)' : null}
                    onClick={(event: any) => event.stopPropagation()}
                    isDisabled={isUploadingDocument || isLoading}
                    files={flagFiles}
                  />
                )}
              </Box>
            </RequirePermissions>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          as="button"
          onClick={handleHide}
          variant="secondary"
          tone="neutral"
          size="md"
          disabled={isUploadingDocument || isLoading}
        >
          Cancel
        </Button>
        <Button
          size="md"
          type="submit"
          onClick={handleSave}
          color="danger"
          disabled={isUploadingDocument || isLoading}
        >
          Flag Item
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
