import {
  Box,
  Button,
  Card,
  Column,
  SelectInputNative,
  Table,
  useBreakpoint,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useCallback, useState } from 'react';
import { AddContractModal } from './AddContractModal';
import { EditContractModal } from './EditContractModal';
import { ContractFilter } from './ContractFilter';
import { MainContainer } from '../MainContainer';
import { useLazyGetTemplatesQuery } from '@/services/contractTemplates';
import {
  FinanceTypeLabels,
  Locale,
  OrganizationContractDocumentSettings,
  ProgramType,
  ProgramTypeLabels,
  ProgramTypes,
} from 'types';
import { useSearchParams } from 'react-router-dom';
import RequireContractAdminUser from '../auth/RequireContractAdminUser';

export function ContractTemplates() {
  const [selected, setSelected] = useState<any>(null);
  const { isPhone } = useBreakpoint();
  const [queryParams, setQueryParams] = useSearchParams();

  const advancedFilters = queryParams.get('advancedFilters');
  const filters = advancedFilters ? JSON.parse(advancedFilters) : [];
  const programTypeFilter = filters.find((f: any) => f.id === 'programType');
  const programType = programTypeFilter?.selectedValues[0] as ProgramType;

  if (!programType) {
    setQueryParams({
      advancedFilters: JSON.stringify([
        { id: 'programType', operation: 'Equal', selectedValues: [ProgramType.solar] },
      ]),
    });
  }

  const availableProgramTypes = ProgramTypes.map((programType) => ({
    label: ProgramTypeLabels[programType],
    value: programType,
  }));

  const [trigger, { data: templateData = [], isFetching: isTemplateDataFetching }] = useLazyGetTemplatesQuery();
  const {
    isOpen: isAddContractModalOpen,
    handleClose: handleAddContractModalClose,
    handleOpen: handleOpenAddContractModal,
  } = useOpenClose();
  const {
    isOpen: isEditContractModalOpen,
    handleClose: handleEditContractModalClose,
    handleOpen: handleOpenEditContractModal,
  } = useOpenClose();

  // The org settings ID is constant for a given programType
  const organizationSettingsId = templateData?.[0]?.organizationSettingsId;

  const columnConfig = [
    {
      heading: 'State',
      render: (_cell: any, row: OrganizationContractDocumentSettings) => (
        <Box direction="row" alignItems="center">
          {row.contractSettings.state}
        </Box>
      ),
    },
    {
      heading: 'Contract Type',
      render: (_cell: any, row: OrganizationContractDocumentSettings) => (
        <Box direction="row" alignItems="center">
          {FinanceTypeLabels[row.contractSettings.type]}
        </Box>
      ),
    },
    {
      heading: 'English Template',
      width: 420,
      render: (_cell: any, row: OrganizationContractDocumentSettings) => (
        <Box>
          {row.contractSettings.contractDocumentSettings?.templates
            ?.filter((t: any) => t.language === Locale.en)
            .map((t: any) => (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >{`${t.coborrower ? 'Co-borrower' : 'Single'}: ${t.templateId}`}</Box>
            ))}
        </Box>
      ),
    },
    {
      heading: 'Spanish Template',
      width: 420,
      render: (_cell: any, row: OrganizationContractDocumentSettings) => (
        <Box>
          {row.contractSettings.contractDocumentSettings?.templates
            ?.filter((t: any) => t.language === Locale.es)
            .map((t: any) => (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >{`${t.coborrower ? 'Co-borrower' : 'Single'}: ${t.templateId}`}</Box>
            ))}
        </Box>
      ),
    },
    {
      render: (_cell: any, row: OrganizationContractDocumentSettings) => (
        <RequireContractAdminUser>
          <Button onClick={() => handleClick(row)} iconPrefix="settings" variant="secondary" tone="neutral" />
        </RequireContractAdminUser>
      ),
    },
  ];
  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      handleOpenEditContractModal();
    },
    [handleOpenEditContractModal],
  );

  const handleProgramTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // When selecting a program type it overrides all other filters
      setQueryParams({
        advancedFilters: JSON.stringify([
          { id: 'programType', operation: 'Equal', selectedValues: [e.target.value] },
        ]),
      });
    },
    [setQueryParams],
  );

  return (
    <>
      <AddContractModal
        isOpen={isAddContractModalOpen}
        handleClose={handleAddContractModalClose}
        programType={programType}
        organizationSettingsId={organizationSettingsId}
      />
      <EditContractModal
        isOpen={isEditContractModalOpen}
        handleClose={handleEditContractModalClose}
        template={selected}
      />
      <Helmet>
        <title>Contracts</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Contracts"></PageHeader>
        <Box display="flex" direction="row" width="240px">
          <SelectInputNative
            label="Program Type"
            name="programType"
            id="programType"
            options={availableProgramTypes}
            onChange={handleProgramTypeChange}
            value={programType}
            autoComplete="off"
            isDisabled={isTemplateDataFetching}
          />
        </Box>
        <Card padding="sm">
          <Card.Header>
            <Box direction="row" justifyContent="flex-end">
              <RequireContractAdminUser>
                <Button
                  as="button"
                  variant="secondary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create template"
                  onClick={handleOpenAddContractModal}
                >
                  {!isPhone && 'Contract'}
                </Button>
              </RequireContractAdminUser>
            </Box>
          </Card.Header>
          <ContractFilter trigger={trigger} />
          {templateData.length > 0 ? (
            <Table
              rowKey="key"
              columns={columnConfig as Column[]}
              rows={templateData}
              isLoading={isTemplateDataFetching}
              isScrollable={{ x: true }}
            />
          ) : (
            <Box padding="xl" textAlign="center">
              No templates found
            </Box>
          )}
        </Card>
      </MainContainer>
    </>
  );
}
