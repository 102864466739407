import { ObjectId } from 'mongodb';

export interface IdProviderUser {
  emails?: string[];
  displayName?: string;
  legalName?: string;
  photoUrl?: string;
  idProviderId?: string;
  idProviderVerificationDate?: string;
}

export enum SalesRepStatus {
  active = 'active',
  blocked = 'blocked',
  override = 'override',
  stale = 'stale',
}

export interface PreviousProviderAuthentication {
  idProvider: string;
  idProviderToken: string;
  idProviderUser: IdProviderUser;
}

export interface SalesRepDocument {
  id: ObjectId;
  accountIds?: string[];
  idProvider: string;
  idProviderToken?: string;
  idProviderUser: IdProviderUser;
  accountEmails?: (string | undefined)[];
  organizationIds?: string[];
  status: SalesRepStatus;
  authenticatedOn?: Date;
  previousAuthentications?: PreviousProviderAuthentication[];
}

export interface SalesRep {
  id: string;
  accountIds?: string[];
  idProvider: string;
  idProviderToken: string;
  idProviderUser: IdProviderUser;
  accountEmails?: (string | undefined)[];
  organizationIds?: string[];
  status: SalesRepStatus;
  authenticatedOn?: Date;
  previousAuthentications?: PreviousProviderAuthentication[];
}

export enum SalesRepSearchField {
  accountEmails = 'accountEmails',
  idProviderDisplayName = 'idProviderUser.displayName',
  idProviderEmails = 'idProviderUser.emails',
  idProviderId = 'idProviderUser.idProviderId',
  idProviderLegalName = 'idProviderUser.legalName',
  organizationIds = 'organizationIds',
  status = 'status',
}

export enum SalesRepVerificationOverrideReason {
  businessDecision = 'businessDecision',
  technicalIssues = 'technicalIssues',
}
