import { Alert, Box, Button, useOpenClose } from '@palmetto/palmetto-components';
import { VoidConfirmationModal } from './VoidConfirmationModal';
import { percentageFormatter } from '../../helpers/percentageFormatter';
import { DiffErrorAlerProps, Origin } from '../../types/ProductionValueCheck';
import { GrantToleranceExceptionModal } from './GrantToleranceExceptionModal';
import RequirePermissions from '../auth/requirePermissions';
import { useLocation } from 'react-router-dom';
import { UserPermissions } from '@/../../types/src/UserTypes';

export function DiffErrorAlert({ percentErrorDiff, requestVoid, loadingState, handleToleranceExceptionModal, modalCTA2Text }: DiffErrorAlerProps) {
  const { isOpen, handleClose, handleOpen } = useOpenClose();
  const { isOpen: isOpenGrantToleranceModal, handleClose: handleCloseGrantToleranceModal, handleOpen: handleOpenGRantToleranceModal } = useOpenClose();
  const location = useLocation();
  const origin = location?.state?.from as Origin;

  const loading =
    loadingState.isAccountLoading ||
    loadingState.isCurrentContractLoading ||
    loadingState.isCurrentDesignLoading ||
    loadingState.isChecking ||
    loadingState.isVoiding;

  return (
    <Box margin="0 0 xs 0" padding={{ base: '0 0 0 0', tablet: '0 0 xs 0' }}>
      <Alert
        data-testid="prod-val-check-diff-error-alert"
        message={
          <Box width={{ base: 'auto', tablet: '100' }}>
            <Box fontWeight={'bold'}>
              {' '}
              {percentageFormatter.format(percentErrorDiff)} difference would require a new contract to by signed by
              the customer{' '}
            </Box>
            <Box direction={{ base: 'column', tablet: 'row' }}>
              <Box>
                <Box padding={'sm 0 0 0'}>
                  <Box padding={'0 0 xs 0'}>To achieve install approval:</Box>
                  <Box
                    as="ol"
                    style={{
                      paddingLeft: '1.5em',
                      lineHeight: '1.4em',
                    }}
                  >
                    <li>Void the existing quote and contract</li>
                    <li>Update annual system production with the installed system production estimate</li>
                    <li>Create a new contract</li>
                    <li>Customer e-sign new contract</li>
                  </Box>
                  <Box padding={'sm 0 0 0'}>
                    <Button
                      variant="secondary"
                      tone="danger"
                      size={'sm'}
                      onClick={handleOpen}
                      style={{
                        maxWidth: 'fit-content',
                      }}
                      disabled={loading}
                      className="danger"
                    >
                      Void Contract and Update Year-1 Production Value
                    </Button>
                  </Box>
                </Box>
              </Box>
              {handleToleranceExceptionModal && origin !== Origin.PV_SYSTEM_TAB && <RequirePermissions permissions={[UserPermissions.lightReachSupport]}>
                <Box width={{ base: 'auto', tablet: '5xl' }}
                  display={{ base: 'flex' }}
                  alignItems={{ base: 'flex-end' }}
                  justifyContent={{ base: 'flex-end' }}
                >
                  <Button
                    variant="secondary"
                    size={'sm'}
                    onClick={handleOpenGRantToleranceModal}
                    tone='neutral'
                    style={{
                      maxWidth: 'fit-content',
                    }}
                    disabled={loading}
                  >
                    Grant Tolerance Exception
                  </Button>
                </Box>
              </RequirePermissions>}
            </Box>
          </Box>
        }
        variant="danger"
        hasIcon={true}
      />
      <VoidConfirmationModal
        isOpen={isOpen}
        isVoiding={loadingState.isVoiding}
        onVoidRequest={async () => {
          try {
            await requestVoid();
          } catch (e) {
            console.log({ e });
          }
          handleClose();
        }}
        onDismiss={() => {
          handleClose();
        }}
        cta2Text={modalCTA2Text}
      />
      {
        handleToleranceExceptionModal && <GrantToleranceExceptionModal
          isOpen={isOpenGrantToleranceModal}
          onDismiss={() => {
            handleCloseGrantToleranceModal();
          }}
          handleToleranceExceptionModal={handleToleranceExceptionModal}
        />
      }
    </Box>
  );
}
