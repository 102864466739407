import { Helmet } from 'react-helmet';
import { MainContainer } from '@/components';
import PageHeader from '../PageHeader';

export function VerificationError() {
  return (
    <>
      <Helmet>
        <title>Rep Identity Verification Error</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Oops. Something went wrong." />
        <p>
          We were not able to link your Recheck ID with your LightReach project. If you started your Recheck sign up
          on a different device than this one, please return to that device and complete your Recheck sign up there.
          You may also use your LightReach email invitation to try again. We apologize for the inconvenience.
        </p>
      </MainContainer>
    </>
  );
}
