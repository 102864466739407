import DateTimeDisplay from '@/components/DateTime';
import { kwhPriceFormatter } from '@/helpers/currencyFormatter';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { Badge, Box, Icon, TextLink } from '@palmetto/palmetto-components';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { PriceSheetStatus } from 'types';
import { DiffCell } from './DiffCell';
import { DiffMapListCell } from './DiffMapListCell';

interface ColumnLinkProps {
  onColumnClick: (dataKey: string) => void;
  dataKey: string;
  children: ReactNode;
}

const ColumnLink: FC<ColumnLinkProps> = ({ onColumnClick, dataKey, children }) => (
  <TextLink onClick={() => onColumnClick(dataKey)} style={{ textDecoration: 'underline', color: 'inherit' }}>
    {children}
  </TextLink>
);

const hasDifference = (oldValue: number | undefined, newValue: string) => {
  if (newValue === '' || newValue === undefined) {
    return false;
  }
  const newValueNumber = parseFloat(newValue);
  if ((oldValue || oldValue === 0) && Math.abs(newValueNumber - oldValue) < 0.00001) {
    return false;
  }
  return true;
};

export const getColumnConfig = (
  values: Record<string, any>,
  onColumnClick: (dataKey: string) => void,
  priceSheetOptions: Record<string, string>,
) => {
  return [
    {
      heading: 'Status',
      dataKey: 'status',
      width: 125,
      render: (_cell: any, row: any) => (
        <Badge
          variant={
            row.status === PriceSheetStatus.active
              ? 'success'
              : row.status === PriceSheetStatus.archived
                ? 'warning'
                : 'danger'
          }
          message={row.status}
        />
      ),
    },
    { heading: 'org', dataKey: 'organizationName' },
    { heading: 'state', dataKey: 'state' },
    {
      heading: 'utility',
      dataKey: 'utility',
      render: (cell: any) => <Box>{cell.name}</Box>,
    },
    {
      heading: (
        <ColumnLink dataKey="priceSheetName" onColumnClick={onColumnClick}>
          Price Sheet
        </ColumnLink>
      ),
      dataKey: 'priceSheetName',
      render: (cell: any, row: any) =>
        values.priceSheet && priceSheetOptions[values.priceSheet] !== cell ? (
          <DiffCell oldValue={cell} newValue={priceSheetOptions[values.priceSheet]} />
        ) : (
          <Link
            to={`/admin/pricing/${row.programType}/price-sheet/${row.priceSheetId}`}
            style={{ textDecoration: 'underline' }}
          >
            {cell}
          </Link>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="escalationRateMin" onColumnClick={onColumnClick}>
          esc min
        </ColumnLink>
      ),
      dataKey: 'escalationRateMin',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.escalationRateMin) ? (
          <DiffCell
            oldValue={percentageFormatter.format(cell)}
            newValue={percentageFormatter.format(parseFloat(values.escalationRateMin))}
          />
        ) : (
          <div>{percentageFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="escalationRateMax" onColumnClick={onColumnClick}>
          esc max
        </ColumnLink>
      ),
      dataKey: 'escalationRateMax',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.escalationRateMax) ? (
          <DiffCell
            oldValue={percentageFormatter.format(cell)}
            newValue={percentageFormatter.format(parseFloat(values.escalationRateMax))}
          />
        ) : (
          <div>{cell && percentageFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="pvOnlyMin" onColumnClick={onColumnClick}>
          pv-only min
        </ColumnLink>
      ),
      dataKey: 'pvOnlyMin',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.pvOnlyMin) ? (
          <DiffCell oldValue={cell && kwhPriceFormatter.format(cell)} newValue={`$${values.pvOnlyMin}`} />
        ) : (
          <div>{cell && kwhPriceFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="pvOnlyCeiling" onColumnClick={onColumnClick}>
          pv-only max
        </ColumnLink>
      ),
      dataKey: 'pvOnlyCeiling',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.pvOnlyCeiling) ? (
          <DiffCell oldValue={cell && kwhPriceFormatter.format(cell)} newValue={`$${values.pvOnlyCeiling}`} />
        ) : (
          <div>{cell && kwhPriceFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="zeroEscalatorCeiling" onColumnClick={onColumnClick}>
          pv-only zero escalator max
        </ColumnLink>
      ),
      dataKey: 'zeroEscalatorCeiling',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.zeroEscalatorCeiling) ? (
          <DiffCell oldValue={cell && kwhPriceFormatter.format(cell)} newValue={`$${values.zeroEscalatorCeiling}`} />
        ) : (
          <div>{cell && kwhPriceFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="absoluteCeiling" onColumnClick={onColumnClick}>
          absolute max
        </ColumnLink>
      ),
      dataKey: 'absoluteCeiling',
      headerClassName: 'wrap',
      render: (cell: any) =>
        hasDifference(cell, values.absoluteCeiling) ? (
          <DiffCell oldValue={cell && kwhPriceFormatter.format(cell)} newValue={`$${values.absoluteCeiling}`} />
        ) : (
          <div>{cell && kwhPriceFormatter.format(cell)}</div>
        ),
    },
    {
      heading: (
        <ColumnLink dataKey="constructionAdders" onColumnClick={onColumnClick}>
          construction adders
        </ColumnLink>
      ),
      dataKey: 'constructionAdders',
      headerClassName: 'wrap',
      render: (cell: any) => (
        <DiffMapListCell
          oldValue={{
            arbitrageBattery: cell?.find((adder: any) => adder.type === 'arbitrageBattery'),
            electricalUpgrade: cell?.find((adder: any) => adder.type === 'electricalUpgrade'),
            backupBattery: cell?.find((adder: any) => adder.type === 'backupBattery'),
            backupBatteryKwh: cell?.find((adder: any) => adder.type === 'backupBatteryKwh'),
            backupBatteryPriceCap: cell?.find((adder: any) => adder.type === 'backupBatteryPriceCap'),
          }}
          newValue={{
            arbitrageBattery: values.arbitrageBattery,
            electricalUpgrade: values.electricalUpgrade,
            backupBattery: values.backupBattery,
            backupBatteryKwh: values.backupBatteryKwh,
            backupBatteryPriceCap: values.backupBatteryPriceCap,
          }}
        />
      ),
    },
    {
      heading: (
        <ColumnLink dataKey="modifiers" onColumnClick={onColumnClick}>
          modifiers
        </ColumnLink>
      ),
      dataKey: 'modifiers',
      render: (_cell: any, row: any) => (
        <DiffMapListCell
          oldValue={{ holdback: row.holdback, energyCommunity: row.energyCommunity, ppwModifier: row.ppwModifier, domesticContentModifier: row.domesticContentModifier }}
          newValue={{
            holdback: values.holdback,
            energyCommunity: values.energyCommunity,
            ppwModifier: values.ppwModifier,
            domesticContentModifier: values.domesticContentModifier,
          }}
        />
      ),
    },
    {
      heading: 'Created',
      dataKey: 'createdDate',
      width: 150,
      render: (_cell: any, row: any) => <DateTimeDisplay value={row?.createdDate} dateFormat={'DD'} excludeTime />,
    },
    {
      heading: 'Archived',
      dataKey: 'archivedDate',
      width: 150,
      render: (_cell: any, row: any) => <DateTimeDisplay value={row?.archivedDate} dateFormat={'DD'} excludeTime />,
    },
    {
      render: (_cell: any, row: any) =>
        row.status !== 'archived' && (
          <Link
            to={`/admin/pricing/${row.programType}/mappings/edit/${row.id}`}
            style={{ textDecoration: 'underline' }}
          >
            <Icon color="grey-600" name="settings" size="sm" />
          </Link>
        ),
      dataKey: 'edit',
    },
  ];
};
