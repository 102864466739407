import { Helmet } from 'react-helmet';
import {
  generatePath,
  matchPath,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import PageHeader from '../../PageHeader';
import { MainContainer } from '@/components';
import { PaymentPlans } from './PaymentPlans';
import { useEffect, useMemo, useState } from 'react';
import { ProgramType, ProgramTypeLabels, UserPermissions } from 'types';
import { PaymentPlan } from './PaymentPlan';
import { Queued } from './Queued';
import { Paid } from './Paid';
import { useAuth } from '@/components/auth/authProvider';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';
import { RequirePayoutsAccountingAdminUser } from '@/components/auth/RequirePayoutsAccountingAdminUser';
import { AccountPayoutEventErrors } from './AccountPayoutEventErrors';
import { RequirePayoutsAccountingSupportUser } from '@/components/auth/RequirePayoutsAccountingSupportUser';
import RequirePermissions from '@/components/auth/requirePermissions';
import { ProjectTransactionsView } from '../ProjectTransactionsView';
import { UnbatchedTransactions } from './UnbatchedTransactions';
import { InvoiceNeeded } from './InvoiceNeeded';

const baseRoute = '/admin/payouts/:programType';
const routes = {
  paymentPlans: 'payment-plans',
  splitPay: 'split-pay',
  paymentPlan: 'payment-plans/:id',
  queued: 'queued',
  approved: 'approved',
  unbatchedTransactions: 'unbatched-transactions',
};
export function Payouts() {
  const params = useParams();
  const { claims } = useAuth();
  const { data: organizationData } = useGetOrganizationDetailsQuery(
    {
      alias: claims?.org,
    },
    { skip: !claims?.org },
  );

  const programTypesOptions = Object.entries(ProgramTypeLabels).map(([key, value]) => ({
    label: value,
    key,
    value,
  }));
  const [programType, setProgramType] = useState(programTypesOptions[0]);
  const navigate = useNavigate();
  const location = useLocation();

  const updateProgramTypeAndNavigate = (urlProgramType: string) => {
    const lastProgramType =
      programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
    setProgramType(lastProgramType);
    const pathPattern =
      Object.values(routes).find((pattern) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, programType: lastProgramType.key });
    navigate(path);
  };

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    updateProgramTypeAndNavigate(urlProgramType);
  }, []);

  useEffect(() => {
    if (organizationData?.programTypes?.length) {
      const orgProgramType = organizationData.programTypes[0];
      updateProgramTypeAndNavigate(orgProgramType);
    } else {
      setProgramType(programTypesOptions[0]);
    }
  }, [organizationData]);

  const showProgramTypeSelection = useMemo(() => {
    return (
      !location ||
      !(
        location.pathname.includes('queued') ||
        location.pathname.includes('approved') ||
        location.pathname.includes('event-errors') ||
        location.pathname.includes('group-batches')
      )
    );
  }, [location?.pathname]);

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    if (programType.key !== urlProgramType) {
      const lastProgramType =
        programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
      setProgramType(lastProgramType);
    }
  }, [programType, params]);

  const handleProgramTypeChange = (event: any) => {
    const selectedProgramType = event.target.value;
    setProgramType(selectedProgramType);
    const pathPattern =
      Object.values(routes).find((pattern) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, {
      ...params,
      programType: selectedProgramType.key,
    });
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <RequirePermissions
          permissions={[UserPermissions.orgAccountingAdmin, UserPermissions.lightReachAccountingAdmin]}
          checkAllPermissions={false}
        >
          {showProgramTypeSelection && (
            <Box maxWidth="2xl">
              <SelectInput
                id="required"
                label="Program Type"
                value={programType}
                onChange={handleProgramTypeChange}
                options={programTypesOptions}
                menuPortalTarget={document.body}
              />
            </Box>
          )}
        </RequirePermissions>
        <PageHeader title={`Payments`}></PageHeader>

        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to={'queued'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Queued
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to={'approved'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Approved
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePayoutsAccountingAdminUser>
            <NavLink to={'unbatched-transactions'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Unbatched Transactions
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingAdminUser>
          <RequirePermissions
            permissions={[UserPermissions.lightReachAccountingAdmin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <NavLink to={'invoice-needed'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Invoice Needed
                </Box>
              )}
            </NavLink>
          </RequirePermissions>
          <RequirePayoutsAccountingAdminUser>
            <NavLink to={'payment-plans'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payment Plans
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingAdminUser>
          <RequirePayoutsAccountingSupportUser>
            <NavLink to={'event-errors'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payout Event Errors
                </Box>
              )}
            </NavLink>
          </RequirePayoutsAccountingSupportUser>
        </Box>
        <Routes>
          <Route path="payment-plans" element={<PaymentPlans programType={programType.key as ProgramType} />} />
          <Route path="payment-plans/:id" element={<PaymentPlan />} />
          <Route path="queued" element={<Queued programType={programType.key as ProgramType} />} />
          <Route path="approved" element={<Paid programType={programType.key as ProgramType} />} />
          <Route
            path="unbatched-transactions"
            element={<UnbatchedTransactions programType={programType.key as ProgramType} />}
          />
          <Route path="invoice-needed" element={<InvoiceNeeded />} />
          <Route path="event-errors" element={<AccountPayoutEventErrors />} />
          <Route path="group-batches/:batchDate" element={<ProjectTransactionsView />}></Route>
        </Routes>
      </MainContainer>
    </>
  );
}
