import { FormikTextInput } from '@palmetto/palmetto-components';
import { useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { useLazyGetTemplateInfoQuery } from '@/services/contractTemplates';

export const DocuSignTemplateIdField = (props: any) => {
  const { errors }: any = useFormikContext();
  const [field] = useField(props);
  const [name, setName] = useState('');

  useEffect(() => {
    if (!errors.templateId) {
      trigger({ templateId: field.value }, true);
    }
  }, [field.value]);

  // Ideally we could use isError here but any API errors appear to be swallowed by something, and they are no use.
  const [trigger, { data, isFetching, isSuccess }] = useLazyGetTemplateInfoQuery();

  useEffect(() => {
    if (isFetching) {
      setName('');
    } else if (isSuccess) {
      setName(data?.name);
    }
  }, [data?.name, isFetching, isSuccess]);

  return (
    <Field
      {...field}
      {...props}
      type="text"
      helpText={name}
      component={FormikTextInput}
      placeholder="Add DocuSign Template ID"
      isRequired={props.isRequired ?? false}
    />
  );
};
