import { ProgramType } from 'types';
import { QuoteSettings } from '../types/ProductionValueCheck';
import { api } from './api';

export const quotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuotes: build.query<any[], { id: string; programType?: ProgramType }>({
      query: ({ id, programType }) => {
        if (programType === ProgramType.hvac) {
          return { url: `/v2/accounts/${id}/quotes/hvac` };
        } else {
          return { url: `/v2/accounts/${id}/quotes` };
        }
      },
      providesTags: () => [{ type: 'Quotes', id: 'LIST' }],
    }),
    getQuoteSettings: build.query<QuoteSettings, { programType: ProgramType }>({
      query: ({ programType }) => ({ url: `/settings/quotes/${programType}` }),
      providesTags: () => [{ type: 'Quotes', id: 'SETTINGS' }],
    }),
    createQuote: build.mutation<any, { quoteData: any; id: string; programType?: ProgramType }>({
      query: ({ quoteData, id, programType }) => {
        let endUrl = ``;
        switch (programType) {
          case ProgramType.doePr:
            endUrl = `/v2/accounts/${id}/quotes/doe-pr`;
            break;
          case ProgramType.hvac:
            endUrl = `/v2/accounts/${id}/quotes/hvac`;
            break;
          default:
            endUrl = `/v2/accounts/${id}/quotes`;
        }
        return {
          url: endUrl,
          method: 'POST',
          body: quoteData,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Quotes', id: 'LIST' },
        { type: 'AccountMilestones', id },
        { type: 'Accounts', id },
        { type: 'Contracts', id: 'LIST' },
        { type: 'AccountAdders', id },
      ],
    }),
    voidQuote: build.mutation<any, { quoteId: string; accountId: string }>({
      query: ({ quoteId, accountId }) => ({ url: `/v2/accounts/${accountId}/quotes/${quoteId}`, method: 'DELETE' }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'Quotes', id: 'LIST' },
        { type: 'AccountMilestones', id: accountId },
        { type: 'Accounts', id: accountId },
        { type: 'Contracts', id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetQuotesQuery, useCreateQuoteMutation, useVoidQuoteMutation, useGetQuoteSettingsQuery } =
  quotesApi;
