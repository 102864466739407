import { HTMLProps, ReactNode } from 'react';
import { Form } from 'formik';
import { useFormikAutoScroll } from '@/hooks/useAutoScrollWhenErrors';

interface EnhancedFormikFormProps extends HTMLProps<HTMLFormElement> {
  children: ReactNode;
  noValidate?: boolean;
}

export const EnhancedFormikForm = ({ children, ...props }: EnhancedFormikFormProps) => {
  useFormikAutoScroll();
  return <Form {...props}>{children}</Form>;
};
