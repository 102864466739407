import { Box, FormikSelectInputNative, FormikTextInput, FormLabel } from '@palmetto/palmetto-components';
import { useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useGetAccountQuery } from '@/services/accounts';
import {
  ClawbackCategory,
  CreateProjectTransactionPayload,
  PayeeProjectRole,
  PayeeType,
  PayoutEventMap,
  ProgramType,
} from 'types';
import { useGetProjectByExternalIdQuery } from '@/services/payouts';

const clawbackCategoryOptions = [
  { value: ClawbackCategory.ntp, label: 'NTP' },
  { value: ClawbackCategory.cancellation, label: 'Cancellation' },
  { value: ClawbackCategory.install, label: 'Install' },
  { value: ClawbackCategory.partnerInstallNotComplete, label: 'Partner Install Not Completed' },
];

export const AddProjectTransactionForm = ({ payeeType }: { payeeType: PayeeType }) => {
  const { values, setFieldValue } = useFormikContext<CreateProjectTransactionPayload>();
  const { projectExternalId: accountId } = values;
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(
    accountId?.length === 24 ? accountId : (undefined as any),
  );
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(
    accountId?.length === 24 ? accountId : (undefined as any),
  );
  const primaryApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const isLoading = isAccountLoading || isProjectLoading;
  const payoutEventOptions = useMemo(
    () => [
      ...(!isLoading && account?.programType
        ? PayoutEventMap[account.programType as ProgramType].map((event: any) => ({
            value: event.event,
            label: event.name,
          }))
        : []),
      { label: 'Other', value: 'other' },
    ],
    [account?.programType, isLoading],
  );
  const payee = useMemo(() => {
    const payeeRole = payeeType === PayeeType.organization ? PayeeProjectRole.primary : PayeeProjectRole.materials;
    return project?.payees?.find((payee) => payee.role === payeeRole) || null;
  }, [project?.payees]);

  useEffect(() => {
    setFieldValue('payeeId', payee?.id);
  }, [payee?.id, setFieldValue]);

  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      <Box fontSize="sm" padding="md 0" childGap="2xs">
        {primaryApplicant && account && (
          <>
            <Box fontWeight="medium" fontSize="md">
              {account.primaryApplicantName}
            </Box>
            <Box>{primaryApplicant.address.address1}</Box>
            <Box>
              {primaryApplicant.address.city}, {primaryApplicant.address.state} {primaryApplicant.address.zip}
            </Box>
            <Box padding="sm 0 0 0">{primaryApplicant.email}</Box>
          </>
        )}
      </Box>
      <FormLabel inputId="payee">Payee</FormLabel>
      <Box>{payee?.name}</Box>
      <Field
        label="Payout Event"
        name="event"
        id="event"
        component={FormikSelectInputNative}
        isRequired
        options={payoutEventOptions}
      />
      <Field
        type="number"
        name="amount"
        id="amount"
        label="Payout Amount"
        component={FormikTextInput}
        isRequired
        prefix="$"
      />
      <Field
        name="clawbackCategory"
        id="clawbackCategory"
        label="Clawback Category"
        component={FormikSelectInputNative}
        options={clawbackCategoryOptions}
      />
      <Field name="description" id="description" label="Reason" component={FormikTextInput} />
    </Box>
  );
};
