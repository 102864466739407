import { UserPermissions } from "types";

const addressFormFields = ['address1', 'address2', 'city', 'state', 'zip', 'lat', 'lon'];
const applicantFormFields = [
  'firstName',
  'lastName',
  'middleName',
  'applicantAddress1',
  'applicantAddress2',
  'applicantCity',
  'applicantState',
  'applicantZip',
];
const utilityFormFields = ['lseId', 'tariffId', 'rate'];
// const systemDetailFormValues = ['systemFirstYearProductionKwh', 'systemSizeKw', 'inverterManufacturer', 'inverterModel', 'inverterCount', 'panelManufacturer', 'panelModel', 'panelCount']

export const getDisabledFieldsBasedOnStatus = (
  status: string,
  claims: Record<string, any> | undefined,
): string[] => {
  if (claims?.permissions.includes(UserPermissions.lightReachSupport)) {
    return [];
  }
  const statusToLowercase = status.toLowerCase();

  if (statusToLowercase.includes('1 - created')) {
    return [];
  }

  if (statusToLowercase.includes('credit approved')) {
    return [...applicantFormFields];
  }

  //disable all fields except for system details
  return [...addressFormFields, ...applicantFormFields, ...utilityFormFields];
};
