import FormSection from '../Forms/FormSection';
import { BillingDocumentTypes, InstallDocumentTypes } from 'types';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import { DomesticContentReview } from './DomesticContentReview';
import { Box } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { EquipmentListSection } from '../SystemDesign/EquipmentSection';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';

type DomesticContentProps = {
  shouldDisplayDomesticContentReviewInformation?: boolean;
};
export const DomesticContent = ({ shouldDisplayDomesticContentReviewInformation }: DomesticContentProps) => {
  const { values } = useFormikContext<any>();

  return (
    <FormSection
      title="Domestic Content"
      description="This project must pass review for Domestic Content (DC) compliance in order to qualify for DC incentive pricing."
    >
      <Box gap="xl">
        {shouldDisplayDomesticContentReviewInformation && (
          <>
            <DomesticContentReview />
            <DocumentList
              documentTypeFilter={[InstallDocumentTypes.domesticContentDocuments]}
              title="Domestic Content Documents"
              showTypeLabelOnItems={false}
              baseCategory={InstallDocumentTypes.domesticContentDocuments}
            />
          </>
        )}
        <DocumentList
          documentTypeFilter={[InstallDocumentTypes.invoice, InstallDocumentTypes.billOfMaterials, BillingDocumentTypes.incomingCedInvoice]}
          title="Invoice/BOM Document"
          showTypeLabelOnItems={true}
          baseCategory={InstallDocumentTypes.domesticContentDocuments}
        />
        <RequirePalmettoFinanceUser>
          <RequirePermissions permissions={['admin']}>
            <EquipmentListSection installationData={values} />
          </RequirePermissions>
        </RequirePalmettoFinanceUser>
      </Box>
    </FormSection>
  );
};
