import {
  Box,
  Button,
  Card,
  Column,
  Pagination,
  Table,
  useBreakpoint,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProgramType, UserPermissions } from 'types';
import { useLazyGetPriceSheetsQuery } from '../../../services/pricing';
import { AddPriceSheetModal } from './AddPriceSheetModal';
import { FilterBar } from '../../filters';
import { TableLoading } from '../../TableLoading';
import './PriceSheets.css';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { priceSheetColumns } from './priceSheetTableColumns';

export function PriceSheets() {
  const { isOpen, handleClose, handleOpen } = useOpenClose();
  const { isPhone } = useBreakpoint();
  const { programType = ProgramType.solar } = useParams<{ programType: ProgramType }>();
  const [trigger, { data: priceSheetResponse, isLoading, isFetching }] = useLazyGetPriceSheetsQuery({});
  const { data, total }: any = priceSheetResponse || { data: [], total: 0 };
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const searchTerm = searchParams.get('search') || undefined;

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    trigger({
      pageNum,
      searchTerm,
      programType: programType as ProgramType,
    });
  }, [trigger, currentPage, searchTerm, programType]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <>
      <AddPriceSheetModal isOpen={isOpen} handleClose={handleClose} />
      <Card padding="sm">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box>Price Sheets</Box>
            <RequirePalmettoFinanceUser>
              <RequirePermissions permissions={[UserPermissions.pricingAdmin]}>
                <Button
                  as="button"
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create price sheet"
                  onClick={handleOpen}
                >
                  {!isPhone && 'Price Sheet'}
                </Button>
              </RequirePermissions>
            </RequirePalmettoFinanceUser>
          </Box>
        </Card.Header>
        <Box margin="0 lg 0 lg">
          <FilterBar
            filtersState={{
              searchTerm,
            }}
            handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
            placeholder="Search by name, status"
          ></FilterBar>
          {isFetching || isLoading ? (
            <TableLoading rows={12} />
          ) : (
            <Table
              rowKey="id"
              columns={priceSheetColumns[programType] as Column[]}
              rows={data || []}
              isLoading={isLoading || isFetching}
              isScrollable={{ x: true }}
            />
          )}
        </Box>

        {data?.length > 0 && (
          <Pagination
            activePage={Number(currentPage) || 1}
            arePagesVisible
            itemsPerPage={20}
            numberOfPagesDisplayed={3}
            onChange={(page) => onPageChange(page)}
            totalItemsCount={total ?? 0}
            isCompact
          />
        )}
      </Card>
    </>
  );
}
