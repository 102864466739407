import { DocumentFile, DocumentDocument, DocumentStatus, DocumentTypes, documentTitles, UserPermissions } from 'types';
import { documentStatus, documentRejectionReasons } from '../../types/Documents';
import { Badge, Box, Button, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FileItem } from './FileItem';
import { useArchiveDocumentMutation, useUpdateDocumentStatusMutation } from '../../services/documents';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { RejectionModal } from '../Reject/RejectionModal';
import { useMemo } from 'react';
import { isErrorWithData } from '../../services/helpers';
import { AllInstallPackageRejectionReasons } from '../../types/ReviewPackage';
import RejectionAlert from '../Reject/RejectionAlert';
import { QueueRejectionReason, QueueRejectionReasons, QueueStatus } from 'types';
import { useUpdateQueueMutation } from '../../services/queues';
import { useAuth } from '../auth/authProvider';
import { DocumentRejectionModal } from '../DocumentRejectionModal/DocumentRejectionModal';
import { useReinstateContractMutation } from '@/services/contract';
import { ReinstateContractModal } from '../Contracts/ReinstateContractModal';

export function DocumentListItem({
  item,
  showDocumentActions = true,
  allowArchive = true,
  task,
  queueItem,
}: {
  item: DocumentDocument;
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  task: any;
  queueItem?: any;
}) {
  const { type, status, externalReference, files } = item;
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [updateDocumentStatus, { isLoading: isUpdatingStatus }] = useUpdateDocumentStatusMutation();
  const [archiveDocument, { isLoading: isArchiving }] = useArchiveDocumentMutation();
  const [reinstateContract, { isLoading: isReinstatingContract }] = useReinstateContractMutation();
  const documentTitle = documentTitles[type] || (type as string);
  const rejectionReasons = documentRejectionReasons[type];
  const [updateQueue, { isLoading: isUpdatingQueue }] = useUpdateQueueMutation();
  const { user } = useAuth();
  const canArchive = allowArchive && status !== DocumentStatus.approved;

  const handleQueueUpdate = async (status: QueueStatus, note?: string, reasons?: QueueRejectionReason[]) => {
    try {
      await updateQueue({
        id: queueItem.id,
        data: {
          assigneeId: user?.sub,
          status,
          ...(reasons && {
            rejectionReasons: [
              {
                reasons,
                rejectedDocumentId: item?.id,
                ...(note && { note }),
              },
            ],
          }),
        },
      }).unwrap();
      toast.success('Queue updated successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating queue: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating queue');
      }
    }
  };

  const rejectionReasonOptions = useMemo(() => {
    if (!rejectionReasons) {
      return [];
    }
    return rejectionReasons.map((key) => ({
      label: AllInstallPackageRejectionReasons[key],
      value: key,
    }));
  }, [rejectionReasons]);

  const rejectionReasonFromQueue = useMemo(() => {
    if (item && queueItem) {
      return queueItem?.rejectionReasons?.find(
        (reason: QueueRejectionReasons) => reason?.rejectedDocumentId?.toString() === item?.id?.toString(),
      );
    }
  }, [item, queueItem]);

  const reasonText = rejectionReasonFromQueue?.reasons
    .map((r: QueueRejectionReason) => QueueRejectionReason[r as unknown as keyof typeof QueueRejectionReason])
    .join(', ');

  const handleDocumentStatusUpdate = async (
    status: DocumentStatus,
    rejectionNote?: string,
    rejectionReasons?: string[],
  ) => {
    try {
      await updateDocumentStatus({
        id: item?.id?.toString() || '',
        accountId: item?.accountId?.toString() || '',
        status,
        rejectionNote,
        rejectionReasons,
      }).unwrap();
      toast.success('Document updated successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating document: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating document');
      }
    }
  };

  const approve = async () => {
    if (queueItem) {
      return handleQueueUpdate(QueueStatus.approved);
    }
    return handleDocumentStatusUpdate(DocumentStatus.approved);
  };

  const reject = async () => {
    return handleDocumentStatusUpdate(DocumentStatus.rejected);
  };

  const archive = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to Archive this Document? This action cannot be undone.',
    );

    if (!confirmed) return;

    await archiveDocument({
      id: item?.id?.toString() || '',
      accountId: item?.accountId?.toString() || '',
      invalidateAllTags: true,
    });

    toast.success('Document archived successfully');
  };

  const handleRejectModalQueue = async (values: { rejectionReason: string; notes: string | undefined }) => {
    const { rejectionReason, notes } = values;
    await handleQueueUpdate(QueueStatus.rejected, notes, [rejectionReason as QueueRejectionReason]);
    closeModal();
  };

  const handleRejectModal = async (value: {
    rejectionReason: { value: string; label: string }[];
    rejectionNote: string;
  }) => {
    const rejectionReasons = value.rejectionReason.map((reason) => reason.value);
    await handleDocumentStatusUpdate(DocumentStatus.rejected, value.rejectionNote, rejectionReasons);
    closeModal();
  };

  const handleReinstateContract = async () => {
    const toastId = toast.loading('Reinstating contract');
    try {
      await reinstateContract({ accountId: String(item.accountId), externalReference: String(item.externalReference) }).unwrap();
      toast.success('Contract reinstated successfully');
      closeModal();
    } catch (e: any) {
      console.error(e);
      toast.error(e?.data?.message || 'Error reinstating contract');
    } finally {
      toast.dismiss(toastId);
    }
  };

  return (
    <Box borderWidth="xs 0 0 0" borderColor="separator" background="primary">
      <Box padding={{ base: 'md 0', tablet: '0' }} direction="row" width="100" alignItems="center">
        {queueItem ? (
          <DocumentRejectionModal
            isModalOpen={isModalOpen}
            title={documentTitle}
            handleClose={closeModal}
            handleSubmit={handleRejectModalQueue}
          />
        ) : (type === DocumentTypes.contract && status === DocumentStatus.voided ?
          (<ReinstateContractModal
            isModalOpen={isModalOpen}
            handleClose={closeModal}
            handleSubmit={handleReinstateContract}
          />)
          : (
            <RejectionModal
              title={`Reject ${documentTitle}`}
              rejectionReasons={rejectionReasonOptions}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              onSubmit={handleRejectModal}
            />
          ))}
        <Box width={{ base: '100', tablet: '60' }} direction={{ base: 'column', tablet: 'row' }}>
          <Box
            alignItems="flex-start"
            width={{ base: '100', tablet: '30' }}
            padding={{ base: 'md lg', tablet: 'md md md lg' }}
          >
            <Badge message={documentStatus[status]?.label} variant={documentStatus[status]?.variant} />
          </Box>

          <Box
            width={{ base: '100', tablet: '70' }}
            display="block"
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md lg' }}
          >
            <Box fontWeight="medium">{documentTitle}</Box>

            <Box display="block">
              {files.map((f: DocumentFile) => (
                <FileItem file={f} key={f?.md5Hash} />
              ))}
            </Box>

            {externalReference && <Box fontSize="xs">{`Ext. Reference: ${externalReference}`}</Box>}

            {status === DocumentStatus.rejected && rejectionReasonFromQueue && (
              <Box color="danger" margin="xs 0 0 0">
                {rejectionReasonFromQueue.note ? (
                  <Box>
                    {reasonText}: {rejectionReasonFromQueue.note}
                  </Box>
                ) : (
                  <Box>{reasonText}</Box>
                )}
              </Box>
            )}
          </Box>
        </Box>

        {showDocumentActions && (
          <Box
            width="40"
            padding={{ base: '0 lg', tablet: 'md lg' }}
            direction={{ base: 'column', tablet: 'row' }}
            childGap="xs"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {status === DocumentStatus.pending && (
              <div>
                <RequirePalmettoFinanceUser>
                  <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                    <Button
                      size="sm"
                      variant="primary"
                      isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                      onClick={approve}
                    >
                      Approve
                    </Button>
                  </RequirePermissions>
                </RequirePalmettoFinanceUser>
              </div>
            )}
            {type !== DocumentTypes.contract && status === DocumentStatus.pending && (
              <div>
                <RequirePalmettoFinanceUser>
                  <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                    <Button
                      size="sm"
                      variant="primary"
                      tone="danger"
                      isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                      onClick={rejectionReasons?.length ? openModal : reject}
                    >
                      Reject
                    </Button>
                  </RequirePermissions>
                </RequirePalmettoFinanceUser>
              </div>
            )}
            {type === DocumentTypes.contract && status === DocumentStatus.pending && (
              <div>
                <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="primary"
                    tone="danger"
                    isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                    onClick={reject}
                  >
                    Void
                  </Button>
                </RequirePermissions>
              </div>
            )}
            {type === DocumentTypes.contract && status === DocumentStatus.voided && (
              <div>
                <RequirePermissions permissions={[UserPermissions.lightReachSupport]} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    isLoading={isReinstatingContract || isUpdatingStatus || isUpdatingQueue || isArchiving}
                    onClick={openModal}
                  >
                    Reinstate
                  </Button>
                </RequirePermissions>
              </div>
            )}
            {canArchive && (
              <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                <Button
                  size="sm"
                  variant="secondary"
                  tone="neutral"
                  isLoading={isReinstatingContract || isUpdatingStatus || isUpdatingQueue || isArchiving}
                  onClick={archive}
                >
                  Archive
                </Button>
              </RequirePermissions>
            )}
          </Box>
        )}
      </Box>
      {status === DocumentStatus.rejected && (
        <Box
          margin="0 0 xs 0"
          padding={{ base: '0 lg xs lg', tablet: '0 lg xs lg' }}
          direction="row"
          width="100"
          alignItems="flex-start"
        >
          <RejectionAlert task={task} rejectionReasons={AllInstallPackageRejectionReasons} />
        </Box>
      )}
    </Box>
  );
}
