import { Helmet } from 'react-helmet';
import { MainContainer } from '@/components';
import PageHeader from '../PageHeader';

export function VerificationSuccess() {
  return (
    <>
      <Helmet>
        <title>Rep Identity Verification Success</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Thanks!" />
        <p>
          Your Recheck ID has been connected to LightReach. Please close this page and continue working with this
          homeowner to go solar!
        </p>
      </MainContainer>
    </>
  );
}
