import { useGetUtilitiesQuery } from '@/services/utilities';
import { useFormikContext } from 'formik';
import { ProgramType } from 'types';
import { sortByLabel } from './util';
import { isFieldSelected } from './MappingFormFactory';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import { getStateOptions } from '@/helpers/getStateOptions';
import { useLazySearchOrganizationsQuery } from '@/services/organizations';
import { AsyncSelectInput } from '@/components/Forms/AsyncSelect';

function debounceAsync(
  func: (input: string) => Promise<any>, // Async function to be debounced
  delay: number,
): (input: string) => Promise<any> {
  let timer: NodeJS.Timeout | null = null;
  let pendingPromise: Promise<any> | null = null;

  return (input: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    pendingPromise = new Promise((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          if (!input || input === '' || input.length < 3) {
            resolve([]);
          }
          const result = await func(input);
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          timer = null;
        }
      }, delay);
    });

    return pendingPromise;
  };
}

export const TargetAccounts = ({ programType }: { programType: ProgramType }) => {
  const [triggerSearch] = useLazySearchOrganizationsQuery();

  const filter = [{ id: 'programType', operation: 'Equal', selectedValues: [programType] }];
  const { data: utilities = [] } = useGetUtilitiesQuery(
    {
      ...(programType ? { advancedFilters: JSON.stringify(filter) } : {}),
    },
    { skip: programType === ProgramType.hvac },
  );
  const formik = useFormikContext<any>();

  const handleSearchText = async (searchText: string) => {
    const programTypeFilter = [
      {
        id: 'programType',
        operation: 'Equal',
        selectedValues:
          programType === ProgramType.solar ? [ProgramType.newHomes, ProgramType.solar] : [programType],
      },
    ];
    const results = await triggerSearch({
      searchTerm: searchText,
      useRootOrganization: true,
      advancedFilters: JSON.stringify(programTypeFilter),
    });

    return results?.data?.flat
      ?.map((org) => ({
        value: org.alias,
        label: `${org.name} (${org.alias})`,
      }))
      .sort(sortByLabel);
  };

  const debouncedSearch = debounceAsync(handleSearchText, 400);

  const stateUtilityMapping = utilities.reduce(
    (acc, utility) => {
      acc[utility.state] = acc[utility.state] ?? [];
      acc[utility.state].push(utility);
      return acc;
    },
    {} as Record<string, any[]>,
  );

  const stateOptions =
    programType === ProgramType.hvac
      ? getStateOptions()
      : Object.keys(stateUtilityMapping)
          .sort()
          .map((state) => ({ label: state, value: state }));

  const utilityOptions = isFieldSelected(formik.values.state)
    ? stateUtilityMapping[formik.values.state.value]
        ?.map((u: any) => ({ label: u.name, value: u.lseId }))
        .sort(sortByLabel)
    : [];

  return (
    <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
      <Box as="h3" fontWeight="medium" fontSize="md">
        Target Accounts
      </Box>
      <Box childGap="xl">
        <Box>
          <AsyncSelectInput
            id="organizations"
            name="organizationIds"
            label="Organizations (select all that apply)"
            loadOptions={debouncedSearch}
            value={formik.values.organizationIds}
            onChange={(event) => {
              formik.setFieldValue('organizationIds', event.target.value);
            }}
            onBlur={formik.handleBlur}
            isMulti
            isClearable
            isDisabled={formik.isSubmitting}
            noOptionsMessage={() => 'Search for an organization'}
            error={formik.touched.organizationIds && (formik.errors.organizationIds as string)}
          />
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'xl' }} wrap>
          {programType !== ProgramType.hvac && (
            <>
              <Box width={{ base: 'auto', desktop: '20' }}>
                <SelectInput
                  id="state"
                  name="state"
                  label="State"
                  value={formik.values.state}
                  onChange={(event) => {
                    formik.setFieldValue('state', event.target.value);
                    formik.setFieldValue('utilities', '');
                  }}
                  options={stateOptions}
                  onBlur={formik.handleBlur}
                  isDisabled={formik.isSubmitting || programType === ProgramType.doePr}
                  error={formik.touched.state && (formik.errors.state as string)}
                />
              </Box>

              <Box flex="auto">
                <SelectInput
                  id="utilities"
                  name="utilities"
                  label="Utilities (select all that apply)"
                  value={formik.values.utilities}
                  onChange={(event) => formik.setFieldValue('utilities', event.target.value)}
                  options={utilityOptions}
                  onBlur={formik.handleBlur}
                  isDisabled={!isFieldSelected(formik.values.state) || formik.isSubmitting}
                  isMulti
                  isClearable
                  error={formik.touched.utilities && (formik.errors.utilities as string)}
                />
              </Box>
            </>
          )}
          {programType === ProgramType.hvac && (
            <Box flex="auto">
              <SelectInput
                id="states"
                name="states"
                label="States (select all that apply)"
                value={formik.values.states}
                onChange={(event) => formik.setFieldValue('states', event.target.value)}
                options={stateOptions}
                onBlur={formik.handleBlur}
                isMulti
                isClearable
                error={formik.touched.states && (formik.errors.states as string)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
