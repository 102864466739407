import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useUploadDocumentMutation, useGetDocumentsByMultipleRequestsQuery } from '../services/documents';
import {
  Box,
  Button,
  Card,
  Modal,
  Spinner,
  FileUpload,
  SelectInput,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useLocation, useParams } from 'react-router-dom';
import { DocumentListItem } from './documents/DocumentListItem';
import {
  AllDocumentTypes,
  DocumentStatus,
  DocumentTypes,
  InstallDocumentTypes,
  documentTitles,
  ActivationDocumentTypes,
  HVACDocumentTypes,
  UserPermissions,
  DoePrDocumentTypes
} from 'types';
import RequirePermissions from './auth/requirePermissions';
import { useGetTasksQuery } from '../services/tasks';
import { useGetQueuesByAccountIdQuery } from '../services/queues';
import { isErrorWithData } from '@/services/helpers';

type DocumentsProps = {
  documentTypeFilter: Array<AllDocumentTypes> | undefined;
  additionalDocumentsToFetch?: Array<{
    types: Array<AllDocumentTypes> | undefined;
    status?: Array<DocumentStatus>;
  }>;
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  showUploadButton?: boolean;
  className?: string;
  title?: string;
  originContext?: string;
};

const createDocumentApiParams = (
  documentTypeFilter: Array<AllDocumentTypes> | undefined,
  additionalDocumentsToFetch?: Array<{
    types: Array<AllDocumentTypes> | undefined;
    status?: Array<DocumentStatus>;
  }>,
) => {
  const paramsToCallDocumentApi = [];
  if (documentTypeFilter) {
    paramsToCallDocumentApi.push({ documentTypeFilter });
  }
  if (additionalDocumentsToFetch?.length) {
    additionalDocumentsToFetch.forEach((additionalDocument) => {
      paramsToCallDocumentApi.push({
        documentTypeFilter: additionalDocument.types,
        ...(additionalDocument.status && { documentStatusFilter: additionalDocument.status }),
      });
    });
  }
  return paramsToCallDocumentApi;
};

export function Documents({
  documentTypeFilter = [],
  additionalDocumentsToFetch,
  showDocumentActions = true,
  allowArchive = true,
  showUploadButton = true,
  className,
  title,
  originContext,
}: DocumentsProps) {
  const { id } = useParams<{ id: any }>();
  const queryParams = new URLSearchParams(useLocation().search);
  const scrollTo = queryParams.get('scrollTo');
  const { data: items = [], isLoading: isDocumentsLoading } = useGetDocumentsByMultipleRequestsQuery({
    accountId: id,
    apiFilters: createDocumentApiParams(documentTypeFilter, additionalDocumentsToFetch),
  });
  const { data: tasks = [], isLoading: isTasksLoading } = useGetTasksQuery({ id, assignee: 'internal' });
  const documents = [...items];
  const { isPhone } = useBreakpoint();
  const { isOpen: isUploadModal, handleClose: handleUploadModalClose, handleOpen: openUploadModal } = useOpenClose();

  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();

  const isLoading = isDocumentsLoading || isTasksLoading;

  const sectionRefs = useMemo(
    () => ({
      documents: createRef(),
    }),
    [],
  ) as any;

  useEffect(() => {
    if (scrollTo) {
      sectionRefs[scrollTo]?.current?.scrollIntoView();
    }
  }, [scrollTo, sectionRefs]);

  // These values must match the DocumentTypes enum in the API.
  const documentTypeOptions = useMemo(() => {
    const allDocuments = {
      ...DocumentTypes,
      ...InstallDocumentTypes,
      ...ActivationDocumentTypes,
      ...HVACDocumentTypes,
      ...DoePrDocumentTypes
    };
    const documentTypes = Object.keys(allDocuments)
      .map((key) => {
        return { value: key, label: documentTitles[key as AllDocumentTypes] };
      })
      .filter((dt) => dt.value !== DocumentTypes.contract); // Want to fetch contract-type documents but not allow upload
    if (documentTypeFilter.length > 0) {
      return documentTypes.filter((dt) => documentTypeFilter.includes(dt.value as AllDocumentTypes));
    }

    return documentTypes;
  }, [documentTypeFilter]);

  const [files, setFiles] = useState<FileList | undefined>();

  const [type, setType] = useState<{ value: string; label: string } | undefined>();

  useEffect(() => {
    if (documentTypeOptions.length === 1) {
      setType(documentTypeOptions[0]);
    }
  }, [documentTypeOptions, setType]);

  const onFileChange = useCallback(
    (event: any) => {
      setFiles(event.target?.files);
    },
    [setFiles],
  );

  const closeUploadModal = useCallback(() => {
    setFiles(undefined);
    handleUploadModalClose();
  }, [setFiles, handleUploadModalClose]);

  const onTypeChange = useCallback((event: any) => setType(event.target.value), [setType]);

  const handleUpload = async () => {
    const data = new FormData();
    data.append('grouped', 'true');

    if (type && 'value' in type) {
      data.append('type', type.value as string);
    }
    if (!!files) {
      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }
    }
    if (originContext) {
      data.append('context', originContext);
    }
    try {
      await uploadDocument({ accountId: id, formData: data, invalidateAllTags: true }).unwrap();
      toast.success('Document uploaded');
      closeUploadModal();
    } catch (e: any) {
      if (e.status === 413 || e.originalStatus === 413) {
        toast.error('File size too large. Please upload files smaller than 125MB.');
      } else if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        toast.error('An error occurred while uploading documents');
      }
    }
  };

  return (
    <>
      <Modal isOpen={isUploadModal} onDismiss={closeUploadModal} maxWidth="4xl" ariaLabelledBy="uploadDocHeader">
        <Modal.Header id="uploadDocHeader" title="Upload Documents" onDismiss={closeUploadModal} />
        <Modal.Body background="secondary" childGap="lg">
          <SelectInput
            size="md"
            id="required"
            label="Document Type"
            value={type}
            onChange={onTypeChange}
            options={documentTypeOptions}
            isRequired
            isDisabled={isUploadingDocument || documentTypeOptions.length === 1}
            menuPortalTarget={document.body}
          />
          <FileUpload
            buttonText="Choose File(s)"
            name="files"
            labelText="Choose File(s)"
            id="files"
            accept="image/*,.pdf,.dxf"
            variant="secondary"
            tone="neutral"
            multiple
            required
            requiredIndicator=""
            onChange={onFileChange}
            isDisabled={isUploadingDocument}
            files={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" isDisabled={isLoading} onClick={closeUploadModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={isUploadingDocument}
            onClick={handleUpload}
            isDisabled={!files || !files.length}
          >
            Upload Document
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className={className}>
        <Box
          childGap="lg"
          padding="lg"
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          ref={sectionRefs.documents}
        >
          <Box as="h3" fontWeight="medium" fontSize="md">
            {title ? `${title} Documents` : 'Documents'} {documents?.length > 0 && `(${documents?.length})`}
          </Box>
          {showUploadButton && (
            <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
              <Button size="sm" variant="primary" tone="neutral" iconPrefix="upload" onClick={openUploadModal}>
                {!isPhone && 'Upload'}
              </Button>
            </RequirePermissions>
          )}
        </Box>
        <DocumentList
          isLoading={isLoading}
          items={documents as any}
          tasks={tasks}
          showDocumentActions={showDocumentActions}
          allowArchive={allowArchive}
        />
      </Card>
    </>
  );
}
export interface DocumentsListProps {
  isLoading: boolean;
  items: any[];
  tasks: any[];
  showDocumentActions?: boolean;
  allowArchive?: boolean;
}

export function DocumentList({
  isLoading,
  items = [],
  tasks = [],
  showDocumentActions = true,
  allowArchive = true,
}: DocumentsListProps) {
  const totalCount = items?.length || 0;
  const { id } = useParams<{ id: any }>();
  const { data: queueItems, isLoading: isGetQueuesLoading } = useGetQueuesByAccountIdQuery({
    accountId: id,
  });

  const documentIdToQueueIdMap = useMemo(() => {
    if (isGetQueuesLoading || !queueItems || queueItems.length === 0) return {};
    const documentIdToQueueId: any = {};
    for (const item of queueItems) {
      for (const id of item?.documentIds) {
        documentIdToQueueId[id] = item;
      }
    }
    return documentIdToQueueId;
  }, [queueItems, isGetQueuesLoading]);

  return (
    <>
      <Card.Section padding="0">
        {totalCount > 0 ? (
          items.map((item) => {
            const task = tasks.find((task) => {
              const documentReference = task.referenceIds.find((reference: any) => reference.type === 'documents');
              return documentReference?.id === item?.id;
            });
            const docId = item?.id?.toString();
            const queueItem = docId ? documentIdToQueueIdMap[docId] : undefined;
            return (
              <DocumentListItem
                item={item}
                key={item.id}
                showDocumentActions={showDocumentActions && item?.type !== DocumentTypes.utilityBill}
                allowArchive={allowArchive}
                task={task}
                queueItem={queueItem}
              />
            );
          })
        ) : (
          <Box
            display="block"
            textAlign="center"
            padding="lg"
            childGap="xl"
            borderColor="separator"
            borderWidth="xs 0 0 0"
          >
            {isLoading ? (
              <Spinner size="lg" />
            ) : (
              <>
                <Box fontSize="sm" color="body-secondary">
                  No documents exist for this account
                </Box>
              </>
            )}
          </Box>
        )}
      </Card.Section>
    </>
  );
}
