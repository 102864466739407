import { Box } from '@palmetto/palmetto-components';
import { DomesticContentMountingHardwareLabels, SystemDesign } from 'types';

type IInstallationData = SystemDesign & {
  inverterModels: {
    model: string;
    count: number;
  }[];
  backupBattery?: {
    manufacturer: string;
    model: string;
  };
};

export const EquipmentListSection = ({ installationData }: { installationData: IInstallationData }) => {
  const mountingHardwareWithConcatenation = installationData?.mountingHardware?.map((item: any) => ({
    ...item,
    concatenatedModels: item?.mountingHardwareModels?.map((model: any) => `${model.label}`).join(', '),
  }));

  return (
    <Box>
      <Box
        style={{
          marginTop: '2em',
        }}
      >
        <Box as="h3">Installed Equipment</Box>
        <br />
      </Box>
      <Box>
        <table>
          <tbody>
            {mountingHardwareWithConcatenation?.map((element: any, idx: number) => (
              <tr key={idx}>
                <td>
                  {
                    DomesticContentMountingHardwareLabels[
                      element.type as keyof typeof DomesticContentMountingHardwareLabels
                    ]
                  }
                </td>
                <td>{element.concatenatedModels}</td>
              </tr>
            ))}
            <tr>
              <td>Panel Manufacturer:</td>
              <td>{installationData.panelManufacturer}</td>
            </tr>
            <tr>
              <td>Panel Model:</td>
              <td>{installationData.panelModel}</td>
            </tr>
            <tr>
              <td>Inverter Manufacturer:</td>
              <td>{installationData.inverterManufacturer}</td>
            </tr>
            {installationData.inverterModels.map((element: any, index: number) => (
              <tr key={index}>
                <td>Inverter Model</td>
                <td>{element.model}</td>
              </tr>
            ))}
            {installationData.storage?.map((element, idx: number) => (
              <tr key={idx}>
                <td>Storage Manufacturer</td>
                <td>{element.manufacturer}</td>
                <td>Storage Model</td>
                <td>{element.model}</td>
              </tr>
            ))}
            {installationData.backupBattery && (
              <>
                <tr>
                  <td>Backup Battery Manufacturer</td>
                  <td>{installationData.backupBattery.manufacturer}</td>
                </tr>
                <tr>
                  <td>Backup Battery Model</td>
                  <td>{installationData.backupBattery.model}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
