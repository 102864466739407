import {
  Alert,
  Box,
  Button,
  FormikSelectInputNative,
  FormikTextareaInput,
  FormLabel,
  Modal,
  RadioGroup,
  toast,
} from '@palmetto/palmetto-components';
import { useNavigate } from 'react-router';
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
  QueueStatus,
  QueueHoldReason,
  QueueRejectionReason,
  QueueType,
  UpdateQueue,
  ParentReason,
  ProgramType,
} from 'types';
import * as yup from 'yup';
import { useUpdateQueueMutation } from '../../../services/queues';
import { QueueRejectionReasonCheckboxGroup } from './QueueRejectionReasonCheckboxGroup';
import { FlagCount } from './FlagCount';
import { QueueFlagsContext } from '../../QueueFlags/QueueFlagsContext';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import { MultiOptionsQueueRejectionReasonCheckboxGroup } from './MultiOptionsQueueRejectionReasonCheckboxGroup';
import { HvacRejectionReasonCheckboxGroup } from '@/components/NTPMilestonePackage/HVACRejectionReasonCheckboxGroup';

interface CompleteQueueReviewModalProps {
  queueId: string;
  queueType: QueueType;
  flagCount?: number;
  handleClose: () => void;
  disableApproval?: boolean;
  disableAllApprovals?: boolean;
  isForMilestonePackage?: boolean;
  handleFormSave: () => Promise<void>;
  programType?: ProgramType;
}

const queueUpdateSchema = yup.object({
  notes: yup.string().optional().nullable(),
  status: yup.string().required(),
  queueType: yup.string().required(),
  rejectionReasons: yup
    .array()
    .of(yup.string())
    .when(['status', 'queueType', 'programType'], {
      is: (status: QueueStatus, queueType: QueueType, programType: ProgramType) =>
        status === QueueStatus.rejected &&
        queueType !== QueueType.activationPackage &&
        programType !== ProgramType.hvac,
      then: (schema) => schema.required().min(1, 'Rejection Reasons are required'),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  parentReason: yup
    .array()
    .of(yup.string())
    .when(['status', 'queueType', 'programType'], {
      is: (status: QueueStatus, queueType: QueueType, programType: ProgramType) =>
        (status === QueueStatus.rejected && queueType === QueueType.activationPackage) ||
        (status === QueueStatus.rejected &&
          queueType === QueueType.installPackage &&
          programType === ProgramType.hvac),
      then: (schema) => schema.required().min(1, 'Rejection Reasons are required'),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  holdReason: yup.string().when('status', {
    is: QueueStatus.onHold,
    then: (schema) => schema.required('Hold Reason is required'),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

export const CompleteQueueReviewModal = ({
  handleClose,
  queueId,
  queueType,
  disableApproval = false,
  disableAllApprovals = false,
  isForMilestonePackage = false,
  handleFormSave,
  programType = ProgramType.solar,
}: CompleteQueueReviewModalProps) => {
  const navigate = useNavigate();
  const { getAllFlags } = useContext(QueueFlagsContext);
  const flags = getAllFlags(false);

  const [updateQueue] = useUpdateQueueMutation();
  const [finalMilestoneOptions, setFinalMilestoneOptions] = useState<
    {
      label: string;
      value: QueueStatus;
      id: QueueStatus;
    }[]
  >([]);

  const flagsCount = flags.length;
  const hasFlags = flagsCount > 0;

  const [showDisabledApproved, setShowDisabledApproved] = useState<boolean>(disableApproval || hasFlags);
  const [showDisabledAllApproved, setShowDisabledAllApproved] = useState<boolean>(disableAllApprovals || hasFlags);

  useEffect(() => {
    const options = showDisabledApproved
      ? [rejectedOption, onHoldOption]
      : [approvedOption, rejectedOption, onHoldOption];
    setFinalMilestoneOptions(options);
  }, []);

  const isActivationQueue = queueType === QueueType.activationPackage;
  const isPostActivationQueue = queueType === QueueType.postActivationPackage;
  const approvedOption = { label: 'Approved', value: QueueStatus.approved, id: QueueStatus.approved };
  const conditionallyApprovedOption = {
    label: 'Conditionally Approved',
    value: QueueStatus.conditionallyApproved,
    id: QueueStatus.conditionallyApproved,
  };
  const rejectedOption = {
    label: 'Rejected and Request Changes',
    value: QueueStatus.rejected,
    id: QueueStatus.rejected,
  };
  const onHoldOption = { label: 'On Hold', value: QueueStatus.onHold, id: QueueStatus.onHold };

  const allStatusOptions = [approvedOption, conditionallyApprovedOption, rejectedOption, onHoldOption];
  const disabledApprovedOptions = [conditionallyApprovedOption, rejectedOption, onHoldOption];
  const disabledAllApprovedOptions = [rejectedOption, onHoldOption];
  const subsetStatusOptions = [approvedOption, rejectedOption];

  const installMilestoneOptions =
    showDisabledApproved || showDisabledAllApproved
      ? showDisabledApproved
        ? disabledApprovedOptions
        : disabledAllApprovedOptions
      : allStatusOptions;

  const isFinalMilestonePackage =
    isActivationQueue ||
    isPostActivationQueue ||
    (programType === ProgramType.hvac && queueType === QueueType.installPackage);

  const statusQueueOptions = isForMilestonePackage
    ? isFinalMilestonePackage
      ? finalMilestoneOptions
      : installMilestoneOptions
    : subsetStatusOptions;

  const handleApprovedRadioWhenExceptionNoteIsGiven = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    event.preventDefault();
    const exceptionNote = event.target.value;
    setFieldValue('exceptionNote', exceptionNote);
    if (exceptionNote) {
      const options = [approvedOption, rejectedOption, onHoldOption];
      setFinalMilestoneOptions(options);
      setShowDisabledApproved(false);
    } else {
      const options = [rejectedOption, onHoldOption];
      setFinalMilestoneOptions(options);
      setShowDisabledApproved(true);
    }
  };

  const handleSubmit = async (
    values: {
      note: string | undefined;
      status: string | undefined;
      holdReason: string | undefined;
      rejectionReasons: QueueRejectionReason[] | undefined;
      parentReason: ParentReason[] | undefined;
      rejection: { parentReason: ParentReason; rejectedReason: string }[] | undefined;
      subRejection: { rejectedReason: string; subRejectedReason: string }[] | undefined;
      exceptionNote: string | undefined;
      queueType: QueueType | undefined;
      programType: ProgramType | undefined;
    },
    { setSubmitting }: any,
  ) => {
    const {
      status,
      note,
      holdReason,
      rejectionReasons,
      exceptionNote,
      parentReason = [],
      rejection = [],
      subRejection = [],
    } = values || {};
    const exception = exceptionNote ? { note: exceptionNote } : null;
    try {
      let data = { status } as UpdateQueue;
      switch (status) {
        case QueueStatus.onHold:
          data = { ...data, holdReasons: [{ reasons: [holdReason], note }] } as UpdateQueue;
          break;
        case QueueStatus.rejected:
          if (queueType === QueueType.activationPackage || programType === ProgramType.hvac) {
            const rejectionInfo = parentReason.map((option) => ({
              option,
              rejectionReasons: rejection
                .filter((rejectionOption) => rejectionOption.parentReason === option)
                .map((selectedRejection) => ({
                  reason: selectedRejection.rejectedReason,
                  subReasons: subRejection
                    .filter(
                      (subRejectedReason) => subRejectedReason.rejectedReason === selectedRejection.rejectedReason,
                    )
                    .map((subRejectedReason) => subRejectedReason.subRejectedReason),
                })),
            }));
            data = {
              ...data,
              rejectionReasons: [
                {
                  reasons: rejectionReasons,
                  note,
                  rejectionInfo,
                },
              ],
            } as unknown as UpdateQueue;
            break;
          }
          data = { ...data, rejectionReasons: [{ reasons: rejectionReasons, note }] } as UpdateQueue;
          break;
        case QueueStatus.approved:
        case QueueStatus.conditionallyApproved:
          data = { ...data, approvalNote: note, ...(exception && { exception }) } as UpdateQueue;
          break;
        default:
          break;
      }
      await handleFormSave();
      await updateQueue({
        data,
        id: queueId,
      }).unwrap();
      toast.success('Review completed.');
      navigate(-1);
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error completing the review.');
    }
    setSubmitting(false);
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (
      values.status === QueueStatus.rejected &&
      queueType !== QueueType.activationPackage &&
      programType !== ProgramType.hvac &&
      !values.rejectionReasons
    ) {
      errors.rejectionReasons = 'Rejection Reasons are required';
    }

    if (
      values.status === QueueStatus.rejected &&
      queueType === QueueType.activationPackage &&
      programType !== ProgramType.hvac &&
      !values.parentReason
    ) {
      errors.parentReason = 'Rejection Reasons are required';
    }

    if (values.status === QueueStatus.rejected && values.exceptionNote) {
      errors.exceptionNote = 'Exception Note is not required for this action';
    }

    if (values.status === QueueStatus.onHold && !values.holdReason) {
      errors.holdReason = 'Hold Reason is required';
    }

    if (values.status === QueueStatus.onHold && values.exceptionNote) {
      errors.exceptionNote = 'Exception Note is not required for this action';
    }

    if (values.status === QueueStatus.approved || values.status === QueueStatus.conditionallyApproved) {
      if (!values.exceptionNote && hasFlags && isActivationQueue) {
        errors.exceptionNote = 'Exception note is required for this action';
      }
    }

    if (values.exceptionNote && !values.status) {
      errors.status = 'Status is required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        status: !showDisabledApproved || !showDisabledAllApproved ? QueueStatus.approved : QueueStatus.rejected,
        holdReason: '',
        rejectionReasons: [],
        parentReason: [],
        rejection: [],
        subRejection: [],
        note: '',
        exceptionNote: '',
        queueType,
        programType: programType || ProgramType.solar,
      }}
      validate={handleValidation}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={queueUpdateSchema}
      validateOnChange={false}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form noValidate id="completeReviewForm">
          <Modal.Body background="secondary" gap="lg" fontSize="sm">
            <Box as="p" fontWeight="bold" color="body-primary">
              {`Mark this ${isForMilestonePackage ? 'package' : 'document'} as...`}
            </Box>
            <Box gap="lg">
              <Box gap={showDisabledApproved || showDisabledAllApproved ? 'sm' : '0'}>
                {showDisabledApproved && (
                  <RadioGroup
                    name="disabled"
                    id="disabled"
                    value={'disabled'}
                    onChange={() => {}}
                    isDisabled={true}
                    options={[approvedOption]}
                    margin="0"
                  />
                )}
                <RadioGroup
                  name="status"
                  id="status"
                  value={values.status}
                  onChange={(event) => {
                    setFieldValue('status', event.target.value as QueueStatus);
                  }}
                  options={statusQueueOptions}
                />
              </Box>
              {values?.status === QueueStatus.rejected && (
                <Box gap="md">
                  <FormLabel helpText="Select all that apply" inputId="rejectionReasons">
                    Why are you rejecting this {isForMilestonePackage ? 'package' : 'document'}?
                  </FormLabel>
                  {queueType === QueueType.activationPackage ||
                  (programType === ProgramType.hvac && queueType === QueueType.installPackage) ? (
                    <MultiOptionsQueueRejectionReasonCheckboxGroup
                      parentReasons={values?.parentReason}
                      packageRejection={values?.rejection}
                      packageSubRejection={values?.subRejection}
                      isSubmitting={isSubmitting}
                      handleChange={setFieldValue}
                      programType={programType}
                    />
                  ) : queueType === QueueType.ntpPackage ? (
                    <HvacRejectionReasonCheckboxGroup
                      rejectionReasonsValues={values?.rejectionReasons}
                      isSubmitting={isSubmitting}
                      handleChange={setFieldValue}
                    />
                  ) : (
                    <QueueRejectionReasonCheckboxGroup
                      rejectionReasonsValues={values?.rejectionReasons}
                      isSubmitting={isSubmitting}
                      handleChange={setFieldValue}
                    />
                  )}
                  {errors?.rejectionReasons && touched.rejectionReasons && (
                    <Box color="danger">{errors.rejectionReasons as string}</Box>
                  )}

                  {errors?.parentReason && touched.parentReason && (
                    <Box color="danger">{errors.parentReason as string}</Box>
                  )}
                </Box>
              )}
              {values?.status === QueueStatus.onHold && (
                <Box>
                  <Field
                    label="Why are you putting this package on hold?"
                    name="holdReason"
                    id="holdReason"
                    component={FormikSelectInputNative}
                    options={Object.values(QueueHoldReason).map((reason) => ({ label: reason, value: reason }))}
                    isDisabled={isSubmitting}
                  />
                </Box>
              )}
              <Box>
                <Field
                  name="note"
                  id="note"
                  label="Leave comment (Optional)"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Box
                  as="p"
                  style={{
                    marginTop: '0.4em',
                  }}
                >
                  Any fields updated during your review will be saved when Completing Review or Placing On Hold
                </Box>
              </Box>
              {isForMilestonePackage &&
                values.status === QueueStatus.rejected &&
                (queueType === QueueType.ntpPackage ? (
                  <Alert
                    variant="warning"
                    message="This comment will display to the EPC. Please include details on all remaining issues, 
                    including those persisting from the Notice to Proceed Package. A notification about this package rejection will be sent."
                  />
                ) : programType === ProgramType.hvac ? (
                  <Alert variant="warning" message="A notification about this package rejection will be sent." />
                ) : (
                  <Alert
                    variant="warning"
                    message="This comment will display to the EPC. Please include details on all remaining issues, 
                    including those persisting from the Install Package. A notification about this package rejection will be sent."
                  />
                ))}
              {isActivationQueue && hasFlags && (
                <RequirePalmettoFinanceUser
                  children={
                    <Field
                      label="[INTERNAL] Exception note"
                      helpText="Visible to package reviewers only and will not be visible to EPC users."
                      name="exceptionNote"
                      id="exceptionNote"
                      component={FormikTextareaInput}
                      autoComplete="off"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleApprovedRadioWhenExceptionNoteIsGiven(e, setFieldValue);
                      }}
                      isDisabled={isSubmitting}
                    />
                  }
                />
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer justifyContent={hasFlags ? 'space-between' : 'flex-end'}>
            {hasFlags && <FlagCount count={flagsCount} />}
            <Box direction="row" gap="sm">
              <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              {values?.status === QueueStatus.onHold ? (
                <Button
                  size="md"
                  variant="primary"
                  tone="neutral"
                  iconPrefix="pause"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Place on Hold
                </Button>
              ) : (
                <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Complete Review
                </Button>
              )}
            </Box>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
