import { ProgramType, ProjectTransactionStatus } from 'types';
import { useGetProjectTransactionsQuery, useAddTransactionToBatchMutation } from '@/services/payouts';
import { Box, Button, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FilterableProjectTransactionTable } from '../FilterableProjectTransactionTable';
import { useCallback, useState } from 'react';
import { AddTransactionToBatchModal } from './AddTransactionToBatchModal';
import { isErrorWithData, isErrorWithErrors } from '@/services/helpers';
import { RequirePayoutsAccountingAdminUser } from '@/components/auth/RequirePayoutsAccountingAdminUser';

export const UnbatchedTransactions = ({ programType }: { programType: ProgramType }) => {
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    programType,
    status: ProjectTransactionStatus.open,
  });
  const [addTransactionToBatch, { isLoading }] = useAddTransactionToBatchMutation();
  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleAddTransaction = useCallback(async () => {
    try {
      // todo should there be a check for if there are no selectedIds?
      await addTransactionToBatch({ transactionIds: selectedIds }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      if (isErrorWithErrors(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else if (isErrorWithData(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else {
        console.error(error);
        toast.error('Could not add transaction(s) to batch');
      }
    }
  }, [selectedIds, addTransactionToBatch, handleCloseAddProjectTransactionModal]);
  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );
  return (
    <>
      <AddTransactionToBatchModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Transaction to Batch"
        submitButtonText="Add To Batch"
        modalId="addTransactionModal"
        handleSubmit={handleAddTransaction}
        isAddingBatchToTransaction={isLoading}
      />
      <Box>
        {!isTransactionsLoading && selectedIds?.length > 0 && (
          <RequirePayoutsAccountingAdminUser>
            <Box childGap="sm" alignSelf="flex-end">
              <Button size="sm" prefix="trash" variant="primary" onClick={handleOpenAddProjectTransactionModal}>
                Add transaction{selectedIds.length > 1 ? 's' : ''} to Batch
              </Button>
            </Box>
          </RequirePayoutsAccountingAdminUser>
        )}
      </Box>
      <Box>
        {!isTransactionsLoading && transactions && (
          <FilterableProjectTransactionTable
            transactions={transactions}
            handleRowSelected={handleRowSelected}
            selectedIds={selectedIds}
          />
        )}
      </Box>
    </>
  );
};
