import FormSection from '../Forms/FormSection';
import { ActivationDocumentTypes } from 'types';
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import { PTOProofReview } from './PTOProofReview';
import { useFormikContext } from 'formik';
import { DateInput } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { DATE_FORMAT } from '@/helpers/dates';

export const ProofOfPto = () => {
  const { values, isSubmitting, errors, touched, setFieldValue } = useFormikContext<any>();
  const utilityLseId = values.lseId;
  const netMeteringDocRequiredLseId = 2241;
  const minDate = DateTime.fromISO('2022-01-01').toJSDate();
  const maxDate = DateTime.local().endOf('day').toJSDate();
  const ptoGrantedDate = useMemo(() => {
    return values.ptoGrantedDate ? DateTime.fromFormat(values.ptoGrantedDate, DATE_FORMAT).toJSDate() : null;
  }, [values.ptoGrantedDate]);
  const handleDateChange = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (!date || !(date instanceof Date)) return;
      setFieldValue('ptoGrantedDate', DateTime.fromJSDate(date).toLocal().toFormat(DATE_FORMAT));
    },
    [setFieldValue],
  );

  return (
    <FormSection
      title="Proof of Permission to Operate"
      description="Utility PTO proof such as letter/email copy, or screenshot of utility portal status"
    >
      <PTOProofReview />
      <DocumentList
        documentTypeFilter={[ActivationDocumentTypes.ptoConfirmation]}
        title="Proof of PTO Documents"
        showTypeLabelOnItems={false}
        baseCategory={ActivationDocumentTypes.ptoConfirmation}
      />
      <DateInput
        label="PTO Granted Date"
        name="ptoGrantedDate"
        id="ptoGrantedDate"
        placeholder={'Pick a date'}
        dateFormat={DATE_FORMAT}
        isDisabled={isSubmitting}
        width="2xl"
        value={values.ptoGrantedDate}
        error={touched.ptoGrantedDate && errors.ptoGrantedDate ? errors.ptoGrantedDate : undefined}
        datePickerProps={{
          maxDate,
          minDate,
          onChange: handleDateChange,
          openToDate: ptoGrantedDate ?? undefined,
        }}
        textInputProps={{
          onClear: () => setFieldValue('ptoGrantedDate', ''),
        }}
      />
      {utilityLseId === netMeteringDocRequiredLseId && (
        <DocumentList
          documentTypeFilter={[ActivationDocumentTypes.netMeteringApproval]}
          title="Net Metering Approval (COMED Only)"
          showTypeLabelOnItems={false}
          baseCategory={ActivationDocumentTypes.ptoConfirmation}
        />
      )}
    </FormSection>
  );
};
