import { useAuth } from './authProvider';
import { ReactNode } from 'react';
import { UserPermissions } from 'types';

const palmettoFinanceOrgAlias = 'sabal';

export const isPalmettoFinanceUser = (claims: any) =>
  claims?.org === palmettoFinanceOrgAlias || claims?.rootOrg === palmettoFinanceOrgAlias;

const RequirePricingAdminUser = ({ children }: { children: ReactNode }) => {
  const { claims } = useAuth();
  const isPalmettoFinance = isPalmettoFinanceUser(claims);
  const isPricingAdmin = claims?.permissions?.includes(UserPermissions.pricingAdmin);
  return isPalmettoFinance && isPricingAdmin ? <>{children}</> : null;
};

export default RequirePricingAdminUser;
