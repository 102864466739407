import { Box, Spinner, Button, FormikTextInput, toast } from '@palmetto/palmetto-components';
import { MilestoneType, OrganizationEmailSettingCategories } from 'types';
import { OrganizationNode } from '@/types/Organizations';
import {
  useGetOrganizationEmailSettingsQuery,
  usePutOrganizationEmailSettingsMutation,
} from '@/services/organizations';
import { Formik, Field } from 'formik';
import { object, string, Schema, array } from 'yup';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnhancedFormikForm } from '@/components/EnhancedFormikForm';

export interface EmailsSetting {
  milestone: MilestoneType;
  paymentPercent: number;
  rebate: number;
}
interface EmailsProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

export interface EmailSettingsFormEditData {
  [OrganizationEmailSettingCategories.programUpdates]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.ntpMilestone]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.installMilestone]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.activationMilestone]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.accounting]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.sales]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.compliance]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.crmContact]?: (string | undefined)[];
  [OrganizationEmailSettingCategories.servicePostPTO]?: (string | undefined)[];
}

const customEmailValidation = array()
  .transform(function (value, originalValue) {
    if (this.isType(value) && value !== null) {
      return value;
    }
    return originalValue ? originalValue.split(',') : [];
  })
  .of(
    string()
      .trim()
      .email(({ value }) => `${value} is not a valid email\n`),
  );

const emailSettingSchema = {
  [OrganizationEmailSettingCategories.programUpdates]: customEmailValidation,
  [OrganizationEmailSettingCategories.ntpMilestone]: customEmailValidation,
  [OrganizationEmailSettingCategories.installMilestone]: customEmailValidation,
  [OrganizationEmailSettingCategories.activationMilestone]: customEmailValidation,
  [OrganizationEmailSettingCategories.accounting]: customEmailValidation,
  [OrganizationEmailSettingCategories.sales]: customEmailValidation,
  [OrganizationEmailSettingCategories.compliance]: customEmailValidation,
  [OrganizationEmailSettingCategories.crmContact]: customEmailValidation,
  [OrganizationEmailSettingCategories.servicePostPTO]: customEmailValidation,
};

export const EmailSettingsFormSchema: Schema<EmailSettingsFormEditData> = object().shape(emailSettingSchema);

const Emails = ({ organization }: EmailsProps) => {
  const navigate = useNavigate();
  const [updateEmailSettingsMutation] = usePutOrganizationEmailSettingsMutation();
  const { data, isLoading: isLoadingEmailSettings } = useGetOrganizationEmailSettingsQuery(organization);

  const initialValues = data
    ? Object.values(OrganizationEmailSettingCategories).reduce((acc: any, category: string) => {
        acc[category] =
          data.emailSettings?.find((setting) => setting.category === category)?.emails.join(', ') || '';
        return acc;
      }, {})
    : {};

  const save = useCallback(
    async (values: any, { setSubmitting }: any) => {
      const emailSettings = Object.entries(values).reduce((acc: any, pair: any) => {
        const [key, value] = pair;

        if (value && value !== '') {
          const emails = value.split(',').map((email: string) => email.trim());
          acc.push({ category: key, emails });
        }

        return acc;
      }, []);
      try {
        await updateEmailSettingsMutation({ alias: organization.alias, emailSettings }).unwrap();
        toast.success('Settings submitted successfully');
      } catch (_err: any) {
        toast.error('Error submitting settings');
      }
      setSubmitting(false);
    },
    [organization, updateEmailSettingsMutation],
  );

  return (
    <Box
      childGap={{
        base: 'lg',
        desktop: '2xl',
        hd: '3xl',
      }}
      padding={{
        base: 'lg',
        desktop: '0',
      }}
      width="100%"
      display="block"
    >
      <Box
        childGap="2xs"
        style={{
          flexShrink: 0,
        }}
      >
        <Box as="h2" fontSize="md" fontWeight="medium">
          Email Notifications
        </Box>
        <Box as="p" color="body-secondary" fontSize="sm">
          Specify the email address(es) for each category to receive corresponding email notifications. The same
          email address can be used for multiple categories. Separate multiple email addresses with a comma.
        </Box>
      </Box>
      {!isLoadingEmailSettings ? (
        <Formik
          validationSchema={EmailSettingsFormSchema}
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={save}
        >
          {({ isSubmitting }) => (
            <EnhancedFormikForm>
              <Box childGap={{ base: 'lg', desktop: 'xl' }} style={{ whiteSpace: 'pre-wrap' }}>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  childGap={{ base: 'lg', desktop: 'xl' }}
                >
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      LightReach Program Updates
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Updates specific to the LightReach program, such as new market availability
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.programUpdates}
                      key={OrganizationEmailSettingCategories.programUpdates}
                      id={OrganizationEmailSettingCategories.programUpdates}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      NTP Milestone
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Receive updates on Projects as they work towards Notice to Proceed, including document status,
                      customer agreement status, & Milestone approval and rejection.
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.ntpMilestone}
                      key={OrganizationEmailSettingCategories.ntpMilestone}
                      id={OrganizationEmailSettingCategories.ntpMilestone}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  childGap={{ base: 'lg', desktop: 'xl' }}
                >
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Install Milestone
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Receive updates on Projects as they work towards installation, including Milestone approval and
                      rejection.
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.installMilestone}
                      key={OrganizationEmailSettingCategories.installMilestone}
                      id={OrganizationEmailSettingCategories.installMilestone}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Activation Milestone
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Receive updates on Projects as they work towards activation, including Milestone approval and
                      rejection.
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.activationMilestone}
                      key={OrganizationEmailSettingCategories.activationMilestone}
                      id={OrganizationEmailSettingCategories.activationMilestone}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  childGap={{ base: 'lg', desktop: 'xl' }}
                >
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Accounting
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Financial transactions, payments, and other accounting-related activities
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.accounting}
                      key={OrganizationEmailSettingCategories.accounting}
                      id={OrganizationEmailSettingCategories.accounting}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Sales
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Point(s) of contact for sales-related subjects
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.sales}
                      key={OrganizationEmailSettingCategories.sales}
                      id={OrganizationEmailSettingCategories.sales}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  childGap={{ base: 'lg', desktop: 'xl' }}
                >
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Compliance
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Requirements, regulatory changes, and key compliance deadlines
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.compliance}
                      key={OrganizationEmailSettingCategories.compliance}
                      id={OrganizationEmailSettingCategories.compliance}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      CRM Contact
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Point(s) of contact for your CRM
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.crmContact}
                      key={OrganizationEmailSettingCategories.crmContact}
                      id={OrganizationEmailSettingCategories.crmContact}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                </Box>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  childGap={{ base: 'lg', desktop: 'xl' }}
                  width={{ base: '100', desktop: '50' }}
                >
                  <Box childGap="2xs" flex="auto" width={{ base: '100', desktop: '50' }}>
                    <Box as="h4" fontSize="md" fontWeight="medium">
                      Service (Post-PTO)
                    </Box>
                    <Box as="p" color="body-secondary" fontSize="sm">
                      Updates on ongoing service, maintenance or support activities for accounts
                    </Box>
                    <Field
                      type="text"
                      name={OrganizationEmailSettingCategories.servicePostPTO}
                      key={OrganizationEmailSettingCategories.servicePostPTO}
                      id={OrganizationEmailSettingCategories.servicePostPTO}
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'stretch',
                  tablet: 'flex-start',
                }}
                justifyContent={{
                  tablet: 'flex-start',
                }}
                childGap="sm"
                style={{ flexShrink: 0 }}
                padding="lg 0 0 0"
              >
                <Button
                  as="a"
                  navigate={() => navigate('/settings/organizations')}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isDisabled={isLoadingEmailSettings}
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  type="submit"
                  isDisabled={isLoadingEmailSettings}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </EnhancedFormikForm>
          )}
        </Formik>
      ) : (
        <Spinner size="lg" />
      )}
    </Box>
  );
};

export default Emails;
