import { currencyFormatter } from '@/helpers/currencyFormatter';
import { useRecalculateBatchGroupsMutation } from '@/services/payouts';
import { Box, Button, Modal, Table, toast } from '@palmetto/palmetto-components';
import { useCallback } from 'react';

export const RecalculateBatchesModal = ({
  isOpen,
  payeesWithNegativeTotals,
  onClose,
}: {
  isOpen: boolean;
  payeesWithNegativeTotals: { payeeName: string; total: number; batchId: string }[];
  onClose: () => void;
}) => {
  const [recalculateBatchGroups, { isLoading: isRecalculateProcessing }] = useRecalculateBatchGroupsMutation();
  const handleRecalculate = useCallback(async () => {
    const toastId = toast.loading('Recalculating batch');
    try {
      const recalculateIds = payeesWithNegativeTotals.map((item) => item.batchId);
      await recalculateBatchGroups({ batchIds: recalculateIds }).unwrap();
      toast.success('Batch successfully recalculated');
      onClose();
    } catch (error) {
      console.log(error);
      toast.error('An error occurred');
    } finally {
      toast.dismiss(toastId);
    }
  }, [payeesWithNegativeTotals]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirm Payout Approval" onDismiss={onClose} maxWidth="5xl">
      <Modal.Header id="recalculateBatchesModal" title={'Recalculate Batches'} onDismiss={onClose} />
      <Modal.Body background="secondary" padding="sm">
        <Box childGap="sm">
          <Box>The following payees will be removed from the batch: </Box>
          <Box>
            {payeesWithNegativeTotals.length ? (
              <Table
                rowKey="payeeId"
                columns={[
                  { dataKey: 'payeeName', heading: 'Payee' },
                  {
                    dataKey: 'total',
                    heading: 'Total',
                    render: (cell?: any) => (
                      <Box fontWeight="medium">{cell ? `${currencyFormatter.format(cell)}` : '-'}</Box>
                    ),
                  },
                ]}
                rows={payeesWithNegativeTotals}
              />
            ) : (
              <Box textAlign="center">No batch updates needed</Box>
            )}
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" tone="neutral" onClick={onClose} isLoading={isRecalculateProcessing}>
          Cancel
        </Button>
        <Button variant="primary" isLoading={isRecalculateProcessing} onClick={handleRecalculate}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
