import { api } from './api';
import { ReviewTaskType } from '../types/Queues';
import { Task } from '../types/Tasks';
import { TaskAssigneeType } from '../types/Tasks';

export const adminTasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    runTask: build.mutation<any, any>({
      query: (body) => ({
        url: `/admin/tasks`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useRunTaskMutation } = adminTasksApi;
