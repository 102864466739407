import { UserPermissions } from 'types';
import { Box, Card, useOpenClose, Accordion } from '@palmetto/palmetto-components';
import RequirePermissions from '../../../auth/requirePermissions';
import RequirePalmettoFinanceUser from '../../../auth/RequirePalmettoFinanceUser';
import { useGetPermissionsQuery } from '../../../../services/users';
import { permissionMapper } from '../OrganizationUsers';

export function PermissionsList() {
  const { isOpen, handleToggle } = useOpenClose();

  const { data: permissions = [], isLoading: arePermissionsLoading } = useGetPermissionsQuery();

  return (
    <RequirePalmettoFinanceUser>
      <RequirePermissions permissions={[UserPermissions.lightReachPermissionsAdmin]} checkAllPermissions={false}>
        <Accordion>
          <Accordion.Panel isOpen={isOpen}>
            <Accordion.PanelSummary
              hasCaret="left"
              onToggle={handleToggle}
              isDetailsOpen={isOpen}
              padding="xs md md md"
            >
              Permission Definitions
            </Accordion.PanelSummary>
            <Accordion.PanelDetails>
              <Card childGap="md" as="dl" padding="md">
                {!arePermissionsLoading &&
                  permissions?.length > 0 &&
                  permissions?.map((permission: { value: string; description: string }) => (
                    <Box childGap="2xs">
                      <Box
                        as="dt"
                        fontWeight="bold"
                      >{`${permissionMapper[permission.value as keyof typeof UserPermissions] ?? permission?.value}:`}</Box>
                      <Box as="dd" margin="0 0 0 md">{`${permission?.description}.`}</Box>
                    </Box>
                  ))}
              </Card>
            </Accordion.PanelDetails>
          </Accordion.Panel>
        </Accordion>
      </RequirePermissions>
    </RequirePalmettoFinanceUser>
  );
}
