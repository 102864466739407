import { Alert, Box, Button, Card, Icon, toast } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { isErrorWithData } from '../../services/helpers';
import { useGetTasksQuery } from '../../services/tasks';
import { useSubmitInstallPacketMutation } from '../../services/installPacket';
import { MilestoneReviewStatus } from 'types';
import { InstallDocumentTypes } from 'types';
import { ReviewPackageActions, ReviewPackagePhotoCategory } from '../../types/ReviewPackage';
import { MilestoneType } from '../../types/Milestone';
import { Task, TaskType, taskToMilestoneMap } from '../../types/Tasks';
import { taskTypeToInstallPhotoSection } from './taskTypeToDocumentType';
import { Documents } from '../Documents';
import FormSection from '../Forms/FormSection';
import { InstallationPhotos } from './InstallationPhotos/InstallationPhotos';
import InstallNotes from './InstallNotes';
import { useGetQuoteSettingsQuery, useGetQuotesQuery } from '../../services/quotes';
import { useGetCurrentContractQuery, useVoidContractMutation } from '../../services/contract';
import { useGetAccountQuery } from '../../services/accounts';
import { ContractKwhInfo } from './ContractKwhInfo';
import { isProductionWithinChangeToleranceFE } from '../../helpers/productionValueCheck';
import { DiffErrorAlert } from '../ProductionValueCheck/DiffErrorAlert';
import { SkeletonBox } from '../SkeletonBox';
import { useCreateSystemDesignMutation } from '../../services/systemDesign';

const designDocsDescription = (
  <Box fontSize="sm" childGap="xs" color="body-secondary">
    <p>Upload all required design documents:</p>
    <Box childGap="2xs" as="ul">
      <li>DXF file</li>
      <li>Shade Report</li>
      <li>Plan Set</li>
      <li>Production Model</li>
    </Box>
  </Box>
);

const permitDocsDescription = (
  <Box fontSize="sm" childGap="xs" color="body-secondary">
    <p>Upload all required permit documents:</p>
    <Box childGap="2xs" as="ul">
      <li>Approved Permit</li>
    </Box>
  </Box>
);

const installPhotosDescription = (
  <Box childGap="xs" color="body-secondary">
    <p>Upload photos of the installation to all applicable categories.</p>
    <Box
      as="a"
      direction="row"
      alignItems="center"
      childGap="xs"
      href="https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements"
      rel="noreferrer"
      target="_blank"
      justifyContent="flex-start"
    >
      <span>Install Photo Requirements</span>
      <Icon name="launch-app" size="xs" color="body-tertiary" />
    </Box>
  </Box>
);

interface LegacyMilestonePackageProps {
  canSubmitInstallPackage: boolean;
}

interface LegacyInstallPackageProdValueComponentProps {
  areTasksLoading: boolean;
  userId: string;
  quotes: any[];
  areQuotesLoading: boolean;
  contract: any;
  isCurrentContractLoading: boolean;
  account: any;
  isAccountLoading: boolean;
  tolerance?: {
    valid: boolean;
    percentDifference: number;
    difference: number;
  };
}

export const LegacyInstallPackageProdValueComponent = ({
  areTasksLoading,
  userId,
  quotes,
  areQuotesLoading,
  contract,
  isCurrentContractLoading,
  account,
  isAccountLoading,
  tolerance,
}: LegacyInstallPackageProdValueComponentProps) => {
  const navigate = useNavigate();
  const contractQuote = (quotes || []).find((element: any) => element.id === contract?.quoteId);
  const firstYearContracted = contractQuote?.systemFirstYearProductionKwh || 0;
  const firstYearInstalled = account?.systemDesign?.systemFirstYearProductionKwh || 0;
  const maxKwh = Math.max(firstYearInstalled, firstYearContracted);
  const minKwh = Math.min(firstYearInstalled, firstYearContracted);
  const difference = (maxKwh - minKwh) / maxKwh;
  const loadingState = {
    isAccountLoading,
    isVoiding: false,
  };
  const [isVoiding, setIsVoiding] = useState(false);
  const [voidContract] = useVoidContractMutation();
  const isLoading = areTasksLoading || areQuotesLoading || isCurrentContractLoading || isAccountLoading || isVoiding;
  const [createSystemDesign] = useCreateSystemDesignMutation();

  const handleToleranceExceptionModal = async (note: string): Promise<boolean> => {
    if (!note || note.trim() === '' || note === undefined) {
      toast.error('Notes are required to grant a tolerance exception');
      return false;
    }

    try {
      await createSystemDesign({
        accountId: userId,
        systemDesign: { ...account.systemDesign, toleranceException: { note } },
      }).unwrap();

      toast.success('Production Exception Applied');
      return false;
    } catch (e) {
      toast.error('Something went wrong. Please try again.');
      console.log({ e });
      return false;
    }
  };

  async function requestVoid() {
    setIsVoiding(true);
    try {
      let voided: boolean = false;
      if (contract) {
        await voidContract({
          accountId: userId,
          contractId: contract.id,
        }).unwrap();
        voided = true;
      }
      if (voided) {
        navigate(`/accounts/${userId}`, {
          state: {
            justVoidedContract: true,
          },
        });
      }
    } catch (error) {
      console.error({ error });
      if (error) {
        toast.error('An error has occurred trying to void the contract. Please try again.');
      } else {
        toast.error('An error has occurred. Please try again later.');
      }
    }
    setIsVoiding(false);
  }

  if (isLoading) {
    return (
      <FormSection title="Loading...">
        <SkeletonBox height="25px" width="100" />
      </FormSection>
    );
  }

  return (
    <Box padding={{ base: '0 xl xl', tablet: '0 0 xl 0' }} borderColor="separator" borderWidth="0 0 xs 0">
      {!account?.systemDesign?.attestment?.attestedAt && (
        <Alert
          variant="warning"
          title="Production Not Confirmed"
          message="The Installed Year-1 Total Production Estimate has not been confirmed."
          hasIcon
        />
      )}
      {!tolerance?.valid && !account?.systemDesign?.toleranceException && (
        <DiffErrorAlert
          requestVoid={requestVoid}
          percentErrorDiff={difference}
          loadingState={loadingState}
          handleToleranceExceptionModal={handleToleranceExceptionModal}
          modalCTA2Text="Void Contract and Update Production"
        />
      )}
      <ContractKwhInfo
        firstYearContracted={firstYearContracted}
        firstYearInstalled={firstYearInstalled}
        toleranceException={account?.systemDesign?.toleranceException}
        outOfRange={!tolerance?.valid}
        hideFlags={true}
      />
    </Box>
  );
};

export const LegacyMilestonePackage = ({ canSubmitInstallPackage }: LegacyMilestonePackageProps) => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: items = [], isLoading } = useGetTasksQuery({ id });
  const navigate = useNavigate();
  const [submitInstallPacket] = useSubmitInstallPacketMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);
  const { data: quotes, isLoading: areQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: contract, isLoading: isCurrentContractLoading } = useGetCurrentContractQuery(id);
  const contractQuote = (quotes || []).find((element: any) => element.id === contract?.quoteId);
  const firstYearContracted = contractQuote?.systemFirstYearProductionKwh || 0;
  const firstYearInstalled = account?.systemDesign?.systemFirstYearProductionKwh || 0;
  const { data: quoteSettings } = useGetQuoteSettingsQuery({ programType: account?.programType });
  const toleranceRate = isProductionWithinChangeToleranceFE({
    newProductionKwh: firstYearInstalled,
    originalProductionKwh: firstYearContracted,
    settings: quoteSettings,
  });
  if (firstYearContracted === 0) {
    toleranceRate.valid = true;
  }
  const toleranceException = account?.systemDesign?.toleranceException || undefined;
  const toleranceAllowsSubmit = Boolean(toleranceRate.valid || (!toleranceRate.valid && toleranceException));

  const rejectedTasks = useMemo(() => {
    if (!items) {
      return;
    }
    const internalInstallTasks = items.filter(
      (item: any) =>
        item.assigneeType === 'internal' &&
        taskToMilestoneMap[item.type as TaskType] === MilestoneType.installPackageComplete,
    );
    const groupedTasks = internalInstallTasks.reduce(
      (acc: any, task: any) => {
        acc[task.type] = acc[task.type] || [];
        acc[task.type].push(task);
        return acc;
      },
      {} as Record<TaskType, Task[]>,
    );
    return Object.keys(groupedTasks).reduce((acc, taskType) => {
      const tasks = groupedTasks[taskType as TaskType];
      if (!tasks.every((task: any) => task.rejectionReasons?.length && task.status === 'rejected')) {
        return acc;
      }
      acc.push(taskType);
      return acc;
    }, [] as string[]);
  }, [items]);

  const incompleteAdminTasks = useMemo(() => {
    return items.filter(
      (item) =>
        item.assigneeType === 'internal' &&
        item.status === 'ready' &&
        taskToMilestoneMap[item.type as TaskType] === MilestoneType.installPackageComplete,
    );
  }, [items]);

  const installPhotoPartnerTasks = useMemo(() => {
    if (isLoading) return [];

    if (incompleteAdminTasks?.length === 0 && action === ReviewPackageActions.edit) {
      const partnerTasks = items.filter((item) => {
        return (
          item.assigneeType === 'partner' &&
          item.status === 'ready' &&
          taskToMilestoneMap[item.type as TaskType] === MilestoneType.installPackageComplete
        );
      });
      if (partnerTasks?.length) {
        let filesToUpload: ReviewPackagePhotoCategory[] = [];
        partnerTasks.forEach((task: any) => {
          if (taskTypeToInstallPhotoSection[task.type]) {
            filesToUpload.push(taskTypeToInstallPhotoSection[task.type]);
          }
        });
        return filesToUpload;
      }
    }

    return [];
  }, [items, action, isLoading, incompleteAdminTasks]);

  const isInstallPackagePendingReview = action === ReviewPackageActions.edit && !!incompleteAdminTasks.length;

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
  }, [id, navigate, action]);

  const handleSendRejectedPackageEmail = async () => {
    setIsSubmitting(true);
    try {
      await submitInstallPacket({ accountId: id, action, reviewStatus: MilestoneReviewStatus.rejected }).unwrap();
      toast.success('Install Package Rejection email sent');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error sending email: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error sending email');
      }
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (values?: any) => {
    setIsSubmitting(true);
    try {
      await submitInstallPacket({ accountId: id, action, ...values }).unwrap();
      if (action !== ReviewPackageActions.review) {
        toast.success('Install Package submitted successfully');
        navigate(`/accounts/${id}`);
      }
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error submitting package: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error submitting package');
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isInstallPackagePendingReview && (
        <Alert
          variant="warning"
          hasIcon
          title="Install Package Pending Review"
          message="The Install Package is pending review. The review must be completed before resubmitting."
        />
      )}
      <Box padding={{ base: '0 lg', tablet: '0' }}>
        {contractQuote && contract && (
          <LegacyInstallPackageProdValueComponent
            areQuotesLoading={areQuotesLoading}
            quotes={quotes || []}
            contract={contract}
            isCurrentContractLoading={isCurrentContractLoading}
            account={account}
            isAccountLoading={isAccountLoading}
            areTasksLoading={isLoading}
            tolerance={toleranceRate}
            userId={id}
          />
        )}
        {/* @ts-ignore pass component to description */}
        <FormSection title="Design Package Documents" description={designDocsDescription}>
          <Documents
            documentTypeFilter={[
              InstallDocumentTypes.dxf,
              InstallDocumentTypes.planSet,
              InstallDocumentTypes.productionModel,
              InstallDocumentTypes.shadeReport,
            ]}
            showDocumentActions={action === ReviewPackageActions.review}
            allowArchive={false}
            title="Design Package"
            showUploadButton={action !== ReviewPackageActions.review && !isInstallPackagePendingReview}
          />
        </FormSection>

        {/* @ts-ignore pass component to description */}
        <FormSection title="Permit Documents" description={permitDocsDescription}>
          <Documents
            documentTypeFilter={[InstallDocumentTypes.permit]}
            showDocumentActions={action === ReviewPackageActions.review}
            allowArchive={false}
            title="Permit"
            showUploadButton={action !== ReviewPackageActions.review && !isInstallPackagePendingReview}
          />
        </FormSection>
        {/* @ts-ignore pass component to description */}
        <FormSection title="Install Photo Documentation" description={installPhotosDescription}>
          <Card>
            <InstallationPhotos
              partnerReviewTaskSections={installPhotoPartnerTasks}
              isInstallPackagePendingReview={isInstallPackagePendingReview}
            />
          </Card>
        </FormSection>
        <InstallNotes />
        {rejectedTasks && rejectedTasks.length > 0 && action === ReviewPackageActions.review && (
          <Box
            direction={{
              base: 'column',
              tablet: 'row',
            }}
            alignItems={{
              base: 'center',
              tablet: 'center',
            }}
            justifyContent={{
              tablet: 'flex-end',
            }}
            childGap="sm"
            style={{ flexShrink: 0 }}
            padding="lg 0 0 0"
          >
            <Box alignItems="center" color="danger">
              {`${rejectedTasks.length} ${rejectedTasks.length > 1 ? 'items are' : 'item is'} rejected`}
            </Box>
            <Button
              variant="primary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSendRejectedPackageEmail}
            >
              Send Package Rejection Email
            </Button>
          </Box>
        )}
        {(action === ReviewPackageActions.create || action === ReviewPackageActions.edit) && (
          <Box
            direction={{
              base: 'column',
              tablet: 'row',
            }}
            alignItems={{
              base: 'stretch',
              tablet: 'flex-end',
            }}
            justifyContent={{
              tablet: 'flex-end',
            }}
            childGap="sm"
            style={{ flexShrink: 0 }}
            padding="lg 0 0 0"
          >
            <Button as="a" navigate={() => navigate(`/accounts/${id}`)} variant="secondary" tone="neutral" isLoading={isSubmitting}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              isLoading={isSubmitting}
              onClick={handleSubmit}
              disabled={!!incompleteAdminTasks?.length || !canSubmitInstallPackage || !toleranceAllowsSubmit}
            >
              Submit Install Package
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
