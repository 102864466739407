export interface Logger {
  info: (x: string) => void;
  error: (x: string) => void;
}

export enum Locale {
  en = 'English',
  es = 'Spanish',
}

export interface ExternalReference {
  id: string;
  type: string;
}

export enum FinancingDecision {
  applicantNotFound = 'applicantNotFound',
  approved = 'approved',
  creditFrozen = 'creditFrozen',
  canceled = 'canceled',
  approvedWithStipulations = 'approvedWithStipulations',
  declined = 'declined',
  error = 'error',
  expired = 'expired',
  new = 'new',
  pending = 'pending',
  processing = 'processing',
  withdrawn = 'withdrawn',
  notChosen = 'notChosen',
}

export enum ContractStatus {
  created = 'created',
  sent = 'sent',
  partiallySigned = 'partiallySigned',
  signed = 'signed',
  approved = 'approved',
  rejected = 'rejected',
  voided = 'voided',
  disclosureSent = 'disclosureSent',
  disclosureSigned = 'disclosureSigned',
}

export enum ContractRecipientStatus {
  created = 'created',
  sent = 'sent',
  signed = 'signed',
}

export enum UnderwritingNotes {
  applicantNotFound = 'Applicant not found',
  creditIsFrozen = 'Credit is frozen',
  creditScoreTooLow = 'Credit score too low',
  insufficientCreditHistory = 'Insufficient credit history',
  limitedCreditExperience = 'Limited credit experience',
  recentBankruptcy = 'Bankruptcy',
  preApproved = 'Application was pre-approved',
  other = 'Other: Error, unable to obtain credit report',
}

export const UnderwritingNotesTranslations = {
  [UnderwritingNotes.applicantNotFound]: {
    [Locale.en]: 'Applicant not found',
    [Locale.es]: 'Solicitante no encontrado',
  },
  [UnderwritingNotes.creditIsFrozen]: {
    [Locale.en]: 'Credit is frozen',
    [Locale.es]: 'El crédito está congelado',
  },
  [UnderwritingNotes.creditScoreTooLow]: {
    [Locale.en]: 'Credit score too low',
    [Locale.es]: 'Puntuación de crédito demasiado baja',
  },
  [UnderwritingNotes.insufficientCreditHistory]: {
    [Locale.en]: 'Insufficient credit history',
    [Locale.es]: 'Historial de crédito insuficiente',
  },
  [UnderwritingNotes.limitedCreditExperience]: {
    [Locale.en]: 'Limited credit experience',
    [Locale.es]: 'Experiencia de crédito limitada',
  },
  [UnderwritingNotes.recentBankruptcy]: {
    [Locale.en]: 'Bankruptcy',
    [Locale.es]: 'Bancarrota',
  },
  [UnderwritingNotes.preApproved]: {
    [Locale.en]: 'Application was pre-approved',
    [Locale.es]: 'La solicitud fue preaprobada',
  },
  [UnderwritingNotes.other]: {
    [Locale.en]: 'Other: Error, unable to obtain credit report',
    [Locale.es]: 'Otro: Error, no se puede obtener el informe de crédito',
  },
};

export enum ContractDocumentStatus {
  created = 'created',
  declined = 'declined',
  partiallySigned = 'partiallySigned',
  sent = 'sent',
  signed = 'signed',
  voided = 'voided',
}

export * from './AccountActivity';
export * from './AccountMilestones';
export * from './AccountRequirements';
export * from './AccountTypes';
export * from './AccountTasks';
export * from './ActivityEvents';
export * from './Adders';
export * from './AppSettings';
export * from './Application';
export * from './ApprovedVendors';
export * from './AssetTypes';
export * from './Calendar';
export * from './CEDTypes';
export * from './ContractDocumentSettings';
export * from './CreditCheck';
export * from './DocumentTypes';
export * from './Disclosures';
export * from './Entities';
export * from './Errors';
export * from './ExecutionContext';
export * from './FinanceProducts';
export * from './FinancialAccount';
export * from './Flags';
export * from './GlideTypes';
export * from './Helpers';
export * from './InboundPayments';
export * from './IncomingInvoices';
export * from './InviteTypes';
export * from './ListFilters';
export * from './Logs';
export * from './Messages';
export * from './Milestones';
export * from './Modifiers';
export * from './OrganizationMilestones';
export * from './Organizations';
export * from './OrganizationSettings';
export * from './PriceSheetMapping';
export * from './PriceSheets';
export * from './PricingSettings';
export * from './ProductCatalog';
export * from './ProductPricing';
export * from './ProgramTypes';
export * from './PropertyService';
export * from './PropertyVerificationLogs';
export * from './Queues';
export * from './Quotes';
export * from './ReferenceIds';
export * from './Stipulations';
export * from './SystemDesigns';
export * from './Tasks';
export * from './OrganizationPricing';
export * from './UserTypes';
export * from './Utility';
export * from './PaymentPlans';
export * from './Webhooks';
export * from './Quotes';
export * from './InstallPackage';
export * from './Attestment';
export * from './SystemDesigns';
export * from './ValidateChange';
export * from './ActivationPackage';
export * from './History';
export * from './InboundPaymentsAccountStatus';
export * from './FinancingData';
export * from './ProgramTypes';
export * from './RejectionReasons';
export * from './Payouts';
export * from './AccountPayoutEvents';
export * from './OrganizationTypes';
export * from './NTPPackage';
export * from './PostActivationPackage';
export * from './SalesRepTypes';
