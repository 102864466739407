import { Box, Button, FormikTextInput, FormLabel, Modal } from '@palmetto/palmetto-components';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { PaymentPlan, PayoutEvent, PayoutEventMap, ProgramType, WithObjectIdsAsString } from 'types';
import { isNumber } from 'lodash';

const paymentPlanSchema = yup.object({
  name: yup.string().required('Plan name is a required field.'),
  paymentSchedule: yup.mixed().test({
    test: function (value: unknown, context) {
      if (value === undefined) return true;
      const typedValue = value as Record<PayoutEvent, number>;
      const errors = Object.entries(typedValue).map(function ([key, value]: [string, number]) {
        if (value < 0 || value > 100) {
          return context.createError({
            message: 'Payment percentages must be between 0 and 100',
            path: `${context.path}.${key}`,
          });
        }
      });
      const filteredErrors = errors.filter(Boolean);
      if (filteredErrors.length > 0) {
        return filteredErrors[0];
      }
      if (
        Math.abs(100 - Object.values(typedValue).reduce((acc, event) => acc + (isNumber(event) ? event : 0), 0)) >
        0.00001
      ) {
        return context.createError({ message: 'Total payment must be 100%', path: 'total' });
      }
      return true;
    },
  }),
});

type formValuesType = {
  name: string;
  paymentSchedule: Partial<Record<PayoutEvent, number>>;
};

export const PaymentPlanEditModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  programType,
  modalTitle,
  submitButtonText,
  modalId,
  currentPaymentPlan,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  programType: ProgramType;
  modalTitle: string;
  submitButtonText: string;
  modalId: string;
  currentPaymentPlan?: WithObjectIdsAsString<PaymentPlan>;
}) => {
  const initialValues: formValuesType = useMemo(() => {
    return {
      name: currentPaymentPlan?.name ?? '',
      paymentSchedule: currentPaymentPlan
        ? currentPaymentPlan.paymentSchedule.reduce(
            (acc, event) => {
              acc[event.event] = event.paymentPercent * 100;
              return acc;
            },
            {} as Record<PayoutEvent, number>,
          )
        : {},
    };
  }, [programType, currentPaymentPlan]);

  const onSubmit = useCallback(
    async (values: formValuesType, { setSubmitting, resetForm }: FormikHelpers<formValuesType>) => {
      const paymentPlan: Omit<PaymentPlan, 'id'> = {
        programType,
        name: values.name,
        paymentSchedule: Object.entries(values.paymentSchedule ?? {}).map(([event, paymentPercent]) => ({
          event: event as PayoutEvent,
          paymentPercent: (paymentPercent ?? 0) / 100,
        })),
      };
      await handleSubmit(paymentPlan);
      setSubmitting(false);
      resetForm();
    },
    [programType, currentPaymentPlan, handleSubmit],
  );
  const renderEventSchedule = useCallback(() => {
    return (
      <Box childGap={{ base: 'lg', desktop: 'xl' }}>
        <Box direction={{ base: 'column', desktop: 'row' }}>
          <Box width="50">Payment Event</Box>
          <Box width="50" textAlign="right">
            Percentage Payment
          </Box>
        </Box>
        {PayoutEventMap[programType]
          .sort((a, b) => a.order - b.order)
          .map((entry) => (
            <Box direction={{ base: 'column', desktop: 'row' }}>
              <Box width="50" direction="row" alignItems="center">
                <FormLabel inputId={entry.event}>{entry.name}</FormLabel>
              </Box>
              <Box width="50">
                <Field
                  key={entry.event}
                  type="number"
                  name={`paymentSchedule.${entry.event}`}
                  id={`paymentSchedule.${entry.event}`}
                  component={FormikTextInput}
                  suffix="%"
                />
              </Box>
            </Box>
          ))}
      </Box>
    );
  }, [programType]);

  const renderTotalPayment = useCallback((values: formValuesType) => {
    const total = Object.values(values.paymentSchedule ?? {}).reduce((acc, event) => {
      return acc + (event || 0);
    }, 0);
    return (
      <Box direction={{ base: 'column', desktop: 'row' }} padding="lg 0 0 0">
        <Box width="50">Total</Box>
        <Box width="50" textAlign="right" color={total === 100 ? 'body-primary' : 'danger'} childGap="md">
          <Box>{total?.toFixed?.(1)}%</Box>
          <Box height="1rem">
            {total !== 0 && total !== 100 && (
              <Box as="span" color="danger">
                Total must be 100%
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }, []);

  return (
    <Formik
      validationSchema={paymentPlanSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnBlur
    >
      {({ isSubmitting, resetForm, submitForm, values }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy={modalId}
            onDismiss={() => {
              if (!isSubmitting) {
                resetForm();
                handleClose();
              }
            }}
          >
            <Modal.Header
              id={modalId}
              title={modalTitle}
              onDismiss={() => {
                if (!isSubmitting) {
                  resetForm();
                  handleClose();
                }
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  isRequired={true}
                />
                <Box borderWidth="xs" borderColor="grey-100" padding="md" radius="md">
                  {programType && (
                    <>
                      <FieldArray name="paymentSchedule" render={renderEventSchedule} />
                      {renderTotalPayment(values)}
                    </>
                  )}
                </Box>
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                {submitButtonText}
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
