import { Box, Button } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';

const OrganizationListRow = ({
  item,
  level,
  isSearchingByUser,
}: {
  item: any;
  level: any;
  isSearchingByUser: boolean;
}) => {
  return (
    <Box>
      <Box
        borderColor="separator"
        direction="row"
        justifyContent="space-between"
        borderWidth="0 0 xs 0"
        alignItems="center"
        hover={{ background: 'secondary' }}
      >
        <Box
          direction="row"
          childGap="sm"
          alignItems="center"
          style={{ marginLeft: `${(level + 1) * 25}px` }}
          margin="sm"
          padding="0 0 0 sm"
        >
          <Box direction="row" alignItems="baseline" childGap="2xs">
            <Box fontWeight="medium">{item.name}</Box>
            <Box color="body-secondary" fontSize="xs">
              {item.alias}
            </Box>
          </Box>
        </Box>

        <Box margin="xs lg xs">
          <Link to={`/settings/organizations/${item.alias}`}>
            <Button
              iconPrefix="settings"
              aria-label="organization settings"
              size="sm"
              variant="secondary"
              tone="neutral"
            />
          </Link>
        </Box>
      </Box>

      <Box>
        {item.children?.map((child: any) => (
          <OrganizationListRow key={child.id} item={child} level={level + 1} isSearchingByUser={isSearchingByUser} />
        ))}
      </Box>
    </Box>
  );
};

export default OrganizationListRow;
