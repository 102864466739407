import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import PageHeader from '../PageHeader';
import { Box, Card } from '@palmetto/palmetto-components';
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Task } from './Task';
import { useEffect } from 'react';

export const taskMap: Record<string, string> = {
  orgVisibility: 'Organization Visibility',
};
export const AdminTasks = () => {
  const navigate = useNavigate();

  const { taskType } = useParams<{ taskType: string }>();

  useEffect(() => {
    if (!taskType) {
      const taskKey = Object.keys(taskMap)[0];
      navigate(`${taskKey}`);
    }
  }, [taskType, navigate]);

  return (
    <>
      <Helmet>
        <title>Admin Tasks</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <PageHeader title="Admin Tasks"></PageHeader>
        <Box direction="row" childGap="3xl">
          <Box>
            {Object.keys(taskMap).map((task) => (
              <NavLink
                to={`/admin/tasks/${task}`}
                style={{ textDecoration: 'none', fontWeight: '500' }}
                key={task}
                end
              >
                {({ isActive }) => (
                  <Box
                    as="span"
                    padding="md"
                    className={isActive ? 'tab-active' : ''}
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s', borderBottom: 0 }}
                  >
                    {taskMap[task]}
                  </Box>
                )}
              </NavLink>
            ))}
          </Box>
          <Box flex="auto">
            <Routes>
              <Route path=":taskType?" element={<Task />} />
            </Routes>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};
