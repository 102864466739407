import { DocumentList } from '../../MilestonePackagePartials/Documents/DocumentList';
import FormSection from '@/components/Forms/FormSection';
import { Box, CheckboxInput, FormikTextareaInput, TextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { InstallDocumentTypes } from 'types';

const solarReadinessAdderOptions = [
  {
    value: 'doubleTap',
    label: 'Double Tap',
  },
  {
    value: 'McbReplacement',
    label: 'MCB Replacement',
  },
  {
    value: 'mcbAndMountingBaseReplacement',
    label: 'MCB and Mounting Base Replacement',
  },
  {
    value: 'groundRodInstall',
    label: 'Ground Rod Installation',
  },
  {
    value: 'groundWireBtwMeterAndPanelUnder50',
    label: "Ground Wire Between Meter and Panel <50'",
  },
  {
    value: 'groundWireBtwMeterAndPanelOver50',
    label: "Ground Wire Between Meter and Panel >50'",
  },
  {
    value: 'softTrenchUnder50',
    label: "Soft Trench <50'",
  },
  {
    value: 'softTrenchOver50',
    label: "Soft Trench >50'",
  },
  {
    value: 'hardTrenchUnder50',
    label: "Hard Trench <50'",
  },
  {
    value: 'hardTrenchOver50',
    label: "Hard Trench >50'",
  },
  {
    value: 'mainPanelUpgrade',
    label: 'Main Panel Upgrade',
  },
  {
    value: 'meterUpgrade',
    label: 'Meter Upgrade',
  },
  {
    value: 'preExistingCodeViolation',
    label: 'Pre-existing Code Violation',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const SolarReadinessAdder = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [displayOtherTextArea, setDisplayOtherTextArea] = useState(
    values.solarReadiness?.otherReasonDetails ? true : false,
  );

  const handleSolarReadinessSelection = ({ isSelected, value }: { isSelected: boolean; value: string }) => {
    const selectedObject = solarReadinessAdderOptions.find((option) => option.value === value);

    if (!selectedObject) return;

    const currentSelectedOptions = values.solarReadiness?.reasonOptionsSelected || [];
    const updatedSelectedOptions = isSelected
      ? [...currentSelectedOptions, selectedObject]
      : currentSelectedOptions.filter((selectedOption: { value: string }) => selectedOption.value !== value);

    setFieldValue('solarReadiness.reasonOptionsSelected', updatedSelectedOptions);

    if (value === 'other') {
      setDisplayOtherTextArea(isSelected);
      if (!isSelected) {
        setFieldValue('solarReadiness.otherReasonDetails', '');
      }
    }
  };

  const handleBlurCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);

    if (isNaN(value)) {
      setFieldValue(`solarReadiness.cost`, 0);
      return;
    }

    const roundedValue = Math.abs(Math.round(value / 100) * 100);

    if (roundedValue < 0) {
      setFieldValue(`solarReadiness.cost`, 0);
    } else if (roundedValue > 3000) {
      setFieldValue(`solarReadiness.cost`, 3000);
    } else {
      setFieldValue(`solarReadiness.cost`, roundedValue);
    }
  };

  return (
    <FormSection
      title="Solar Readiness Adder"
      description={'Upload evidence of the solar readiness adder for this site.'}
    >
      <TextInput
        max={3000}
        width="2xl"
        label="Amount (increments of 100)"
        name="solarReadiness.cost"
        value={values?.solarReadiness?.cost}
        id="solarReadinessCost"
        type="number"
        prefix="$"
        onChange={(event) => setFieldValue(`solarReadiness.cost`, event.target.value)}
        onBlur={handleBlurCost}
      />
      <DocumentList
        documentTypeFilter={[InstallDocumentTypes.solarReadinessAdder]}
        title="Solar Readiness Adder"
        showTypeLabelOnItems={false}
        baseCategory={InstallDocumentTypes.solarReadinessAdder}
      />
      <Box height={'200px'} gap={'sm'} wrap>
        {solarReadinessAdderOptions.map((option) => (
          <CheckboxInput
            id={`readinessAdder-${option.value}`}
            label={option.label}
            name={`readinessAdder-${option.value}`}
            key={option.value}
            isChecked={values.solarReadiness?.reasonOptionsSelected?.some(
              (selectedOption: { value: string }) => selectedOption.value === option.value,
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSolarReadinessSelection({
                isSelected: event.target.checked,
                value: option.value,
              })
            }
          />
        ))}
      </Box>
      {displayOtherTextArea && (
        <Box childGap="lg">
          <Field
            label="Other Explanation *"
            name="solarReadiness.otherReasonDetails"
            id="solarReadinessOtherReasonDetails"
            component={FormikTextareaInput}
            autoComplete="off"
            placeholder="If 'Other' is selected, provide an explanation of what work was needed"
          />
        </Box>
      )}
    </FormSection>
  );
};
