import { UserPermissions } from '@/../../types/src/UserTypes';
import { Box, Icon } from '@palmetto/palmetto-components';
import { AccountMilestoneStipulation } from '../../types/Accounts';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import AdminStipulationDetails from './AdminStipulationDetails';
import ClearAdminStipulation from './ClearAdminStipulation';

interface AdminStipulationBannerProps {
  stipulation: AccountMilestoneStipulation;
  className?: string;
}

const AdminStipulationBanner = ({ stipulation, className }: AdminStipulationBannerProps) => (
  <Box
    borderWidth="xs"
    borderColor="warning-200"
    radius="md"
    direction={{ base: 'column', tablet: 'row' }}
    alignItems="stretch"
    fontSize="sm"
    overflow="hidden"
    background="primary"
    shadow="xs"
    className={className}
  >
    <Box background="warning" padding="xl" justifyContent="center" direction="row" gap="sm" alignItems="center">
      <Box>
        <Icon name="flag" size="xl" color="warning-500" />
      </Box>
      <Box>
        <Box fontSize="md" fontWeight="bold" style={{ lineHeight: 'var(--size-line-height-base)' }} display="block">
          Administrative Stipulation
        </Box>
      </Box>
    </Box>
    <AdminStipulationDetails stipulation={stipulation} forBanner />
    <Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
      <RequirePalmettoFinanceUser>
        <RequirePermissions permissions={[UserPermissions.lightReachSupport]}>
          <ClearAdminStipulation stipulation={stipulation} />
        </RequirePermissions>
      </RequirePalmettoFinanceUser>
    </Box>
  </Box>
);

export default AdminStipulationBanner;
