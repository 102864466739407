import { Box, Button, FormikTextInput, Modal, toast } from '@palmetto/palmetto-components';
import { Field, FieldArray, Form, Formik } from 'formik';
import { SyntheticEvent, useCallback } from 'react';
import * as yup from 'yup';
import { AdderType, ApprovedVendorSettingValue, VendorEquipmentTypes, adderMap, ProgramType } from 'types';
import { useCreateConstructionAdderMutation } from '@/services/pricing';
import { useGetApprovedVendorsByTypeQuery } from '@/services/approvedVendors';

const backupBatteryPriceCapSchema = yup.object({
  name: yup.string().required('Option Name is required'),
  backupBatteryPriceCaps: yup.mixed().test({
    test: function (value: any, { createError }) {
      return Object.entries(value).every(([batteryId, priceCap]) => {
        const priceCapNumber = Number(priceCap);
        if (!isNaN(priceCapNumber) && priceCapNumber >= 0 && batteryId) {
          return createError({
            path: `backupBatteryPriceCaps.${batteryId}`,
            message: 'Price Cap must be a positive number',
          });
        }
        return true;
      });
    },
  }),
});

interface BackupBatteryValues {
  name: string;
  backupBatteryPriceCaps: Record<string, number>;
}

export const CreateBackupBatteryPriceCapModal = ({
  isModalOpen,
  closeModal,
  adderType,
  programType,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  adderType?: string;
  programType?: ProgramType;
}) => {
  const { data: avlStorage } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType,
  });
  const [createConstructionAdder] = useCreateConstructionAdderMutation();

  const getDefaultValues = useCallback(() => {
    return {
      name: '',
      backupBatteryPriceCaps: avlStorage?.data?.reduce(
        (acc: Record<string, number>, storage: ApprovedVendorSettingValue) => {
          // @ts-expect-error default to empty string for input values
          acc[storage.id] = avlStorage?.data?.find((battery) => battery.id === storage.id)?.maxPrice ?? '';
          return acc;
        },
        {} as Record<string, number>,
      ),
    };
  }, [avlStorage?.data]);

  const handleSubmit = useCallback(
    async (values: BackupBatteryValues) => {
      try {
        await createConstructionAdder({
          data: {
            adderType: adderType,
            name: values.name,
            backupBatteryPriceCaps: Object.entries(values.backupBatteryPriceCaps ?? {}).map(([id, value]) => ({
              batteryId: id,
              maxPrice: value,
            })),
          },
          programType: programType as ProgramType,
        }).unwrap();
        toast.success('Adder created successfully');
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating adder');
      }
    },
    [adderType, closeModal, createConstructionAdder],
  );

  return (
    <Modal ariaLabelledBy="createConstructionAdder" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header
        id="createConstructionAdder"
        title={`Add ${adderMap[programType as ProgramType][adderType as AdderType]} Modifier`}
        onDismiss={closeModal}
      />
      <Formik
        initialValues={getDefaultValues()}
        validationSchema={backupBatteryPriceCapSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ resetForm, isSubmitting }) => {
          return (
            <Form>
              <Modal.Body overflow={'auto'} maxHeight={'70vh'} background="secondary">
                <Box direction="column" childGap="md">
                  <Field component={FormikTextInput} label="Option Name" name="name" id="name" isRequired />
                  <Box childGap="lg">
                    <Box fontWeight="medium" color="body-primary">
                      Backup Battery Price Caps
                    </Box>
                    <Box gap="lg">
                      <FieldArray
                        name="backupBatteryPriceCaps"
                        render={() =>
                          avlStorage?.data?.map((battery: ApprovedVendorSettingValue) => (
                            <Field
                              prefix="$"
                              type="number"
                              component={FormikTextInput}
                              label={battery.manufacturer + ' - ' + battery.modelNumber}
                              name={`backupBatteryPriceCaps.${battery.id}`}
                              id={`backupBatteryPriceCaps.${battery.id}`}
                              key={battery.id}
                              isRequired
                            />
                          ))
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  as="button"
                  onClick={() => {
                    resetForm();
                    closeModal();
                  }}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button size="md" type="submit" variant="primary" isLoading={isSubmitting}>
                  Add Option
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
