import { ChangeEvent, useEffect } from 'react';
import { Field, Formik, useFormikContext, useField, FieldArray, FormikHelpers } from 'formik';
import {
  Box,
  Button,
  FormikSelectInput,
  FormikSelectInputNative,
  FormikTextInput,
  Icon,
  SelectInputNative,
  Toggle,
} from '@palmetto/palmetto-components';
import * as yup from 'yup';

import FormSection from '../../Forms/FormSection';
import { AddressFormFields } from '../../AddressFormFields/AddressFormFields';
import { getStateOptions } from '../../../helpers/getStateOptions';
import { isPalmettoFinanceUser } from '../../auth/RequirePalmettoFinanceUser';
import { OrganizationType, OrganizationTypeLabels, ProgramType, ProgramTypeLabels, UserPermissions } from 'types';
import usePermissions from '../../../hooks/usePermissions';
import { useAuth } from '../../auth/authProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { EnhancedFormikForm } from '@/components/EnhancedFormikForm';

const AliasField = (props: any) => {
  const { values, touched, setFieldValue }: any = useFormikContext();
  const [field] = useField(props);

  useEffect(() => {
    if (props.isDisabled) return;
    if (values?.name.trim() !== '' && touched.name) {
      setFieldValue(
        props.name,
        values?.name
          .trim()
          .toLowerCase()
          .replace(/[^a-z0-9]/g, '-'),
      );
    }
  }, [values?.name, props.name, props.isDisabled, setFieldValue, touched.alias, touched.name]);

  return (
    <>
      <Field {...field} {...props} component={FormikTextInput} />
    </>
  );
};

export type OrganizationFormSchema = yup.InferType<typeof organizationSchema>;
type SubmitHandler = (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

interface OrganizationFormProps {
  initialValues: any;
  handleSubmit: SubmitHandler;
  isLoading: boolean;
  parentOptions: {
    label: string;
    value: string;
  }[];
  disabledFields?: string[];
  cancelButtonUrl?: string;
}

const organizationSchema = yup.object().shape({
  name: yup.string().required('A friendly name is required'),
  alias: yup.string().required('An alias is required'),
  email: yup.string().email('Invalid email address').required('An email is required'),
  phoneNumber: yup.string().required('A phone number is required'),
  federalEmployerId: yup.string(),
  address1: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string(),
  insuranceCompanyName: yup.string(),
  insuranceCompanyPhoneNumber: yup.string(),
  programTypes: yup.array().of(yup.string()),
  organizationTypes: yup.array().of(yup.string()),
  // Extended Warranty Dealer Information
  extendedWarrantyDealerInformation: yup.array().of(
    yup.object().shape({
      warrantyProvider: yup.string(),
      dealerLicenseNumber: yup.string().when('$programTypes', {
        is: (programTypes: ProgramType) => programTypes.includes(ProgramType.hvac),
        then: (schema) => schema.required('Dealer License Number is required.'),
        otherwise: (schema) => schema,
      }),
    }),
  ),
});

export const OrganizationForm = ({
  initialValues,
  handleSubmit,
  isLoading,
  parentOptions,
  disabledFields = [],
  cancelButtonUrl = '/settings/organizations',
}: OrganizationFormProps) => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const flags = useFlags();
  const { hvacProgramType = false, doePrProgramType = false, newHomesProgramType = false } = useFlags();

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (
      isPalmettoFinanceUser(claims) &&
      permissions.includes(UserPermissions.admin) &&
      values?.stateLicense?.length
    ) {
      const stateLicense: Array<any> = [];
      values.stateLicense.forEach((license: any, index: number) => {
        stateLicense[index] = {};
        if (!license.state) {
          stateLicense[index].state = 'A state is required';
        }
        if (!license.contractorLicenseNumber) {
          stateLicense[index].contractorLicenseNumber = 'A contractor license number is required';
        }
      });
      const stateLicenseErrors = stateLicense.filter((e: any) => Object.keys(e).length > 0);
      if (stateLicenseErrors.length) {
        errors.stateLicense = stateLicense;
      }
    }
    return errors;
  };

  const { claims } = useAuth();

  const isStateLicenseFieldDisabled = (isSubmitting: boolean, index: number) =>
    isSubmitting ||
    isLoading ||
    (disabledFields.includes('stateLicense') && index + 1 <= initialValues.stateLicense.length) ||
    !isPalmettoFinanceUser(claims) ||
    !permissions.includes(UserPermissions.admin);

  const isAuthorizedSignerFieldDisabled = () =>
    !isPalmettoFinanceUser(claims) ||
    !(permissions.includes(UserPermissions.lightReachSupport) || permissions.includes(UserPermissions.admin));

  const enabledProgramTypes = [ProgramType.solar];
  if (hvacProgramType) {
    enabledProgramTypes.push(ProgramType.hvac);
  }
  if (doePrProgramType) {
    enabledProgramTypes.push(ProgramType.doePr);
  }
  if (newHomesProgramType) {
    enabledProgramTypes.push(ProgramType.newHomes);
  }

  const enabledOrganizationTypes = [OrganizationType.epc];
  if (isPalmettoFinanceUser(claims) && permissions.includes(UserPermissions.admin)) {
    enabledOrganizationTypes.push(OrganizationType.materials);
  }

  return (
    <>
      <Formik
        validationSchema={organizationSchema}
        initialValues={initialValues}
        validate={handleValidation}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <EnhancedFormikForm noValidate>
            <FormSection
              title="Details"
              description="Organizations are used both for organization and isolation of resources"
            >
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                gap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  isRequired
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('name')}
                />
                <AliasField
                  type="text"
                  label="Alias"
                  name="alias"
                  id="alias"
                  isRequired
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('alias')}
                />
              </Box>
              <Field
                label="Parent Organization"
                helpText="Members of the parent organization will be able to view and manage the new organization."
                name="parent"
                id="parent"
                options={parentOptions}
                component={FormikSelectInput}
                isRequired
                isDisabled={isSubmitting || isLoading || disabledFields.includes('parent')}
              />
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                gap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="text"
                  label="Company Phone Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  isRequired
                  component={FormikTextInput}
                  inputMask="phone"
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('phoneNumber')}
                />
                <Field
                  type="text"
                  label="Company Email"
                  name="email"
                  id="email"
                  isRequired
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('email')}
                />
                <Field
                  type="text"
                  label="Federal Employer Id"
                  name="federalEmployerId"
                  id="federalEmployerId"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('federalEmployerId')}
                />
              </Box>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                gap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="text"
                  label="Insurance Company Name"
                  name="insuranceCompanyName"
                  id="insuranceCompanyName"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('insuranceCompanyName')}
                />
                <Field
                  type="text"
                  label="Insurance Company Phone Number"
                  name="insuranceCompanyPhoneNumber"
                  id="insuranceCompanyPhoneNumber"
                  component={FormikTextInput}
                  inputMask="phone"
                  autoComplete="off"
                  isDisabled={isSubmitting || isLoading || disabledFields.includes('insuranceCompanyPhoneNumber')}
                />
              </Box>
              <AddressFormFields
                isSubmitting={isSubmitting}
                placeHolderId="organizationAddress"
                disabledFields={disabledFields}
                includePR={true}
              />
            </FormSection>
            {enabledProgramTypes.length > 1 && (
              <FormSection title="Program Types" description="Program types for this organization.">
                {enabledProgramTypes.map((programType: ProgramType) => (
                  <Toggle
                    id={programType}
                    label={ProgramTypeLabels[programType]}
                    isChecked={values.programTypes?.includes(programType)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.checked ? [programType] : [ProgramType.solar];
                      setFieldValue('programTypes', value);
                    }}
                    isDisabled={
                      isSubmitting ||
                      isLoading ||
                      disabledFields.includes('programTypes') ||
                      !isPalmettoFinanceUser(claims)
                    }
                  />
                ))}
              </FormSection>
            )}
            <FormSection title="Organization Types" description="Organization types for this organization.">
              {enabledOrganizationTypes.map((organizationType: OrganizationType) => (
                <Toggle
                  id={organizationType}
                  label={OrganizationTypeLabels[organizationType]}
                  isChecked={values.organizationTypes?.includes(organizationType)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.checked ? [organizationType] : [OrganizationType.epc];
                    setFieldValue('organizationTypes', value);
                  }}
                  isDisabled={
                    isSubmitting ||
                    isLoading ||
                    disabledFields.includes('organizationTypes') ||
                    !isPalmettoFinanceUser(claims)
                  }
                />
              ))}
            </FormSection>
            <FormSection title="Authorized Signers" description="Authorized signers for this organization.">
              <FieldArray
                name="authorizedSigners"
                render={(arrayHelpers) => {
                  return (
                    <Box gap="md">
                      {values.authorizedSigners?.map((_authorizedSigner: any, index: any) => (
                        <Box
                          key={index}
                          gap={{ base: 'md', desktop: 'xl' }}
                          direction="row"
                          alignItems="center"
                          borderWidth="xs"
                          borderColor="separator"
                          padding="md"
                          radius="md"
                        >
                          <Box
                            flex="auto"
                            gap={{ base: 'md', desktop: 'xl' }}
                            direction={{ base: 'column', tablet: 'row' }}
                          >
                            <Field
                              type="text"
                              label="Name"
                              name={`authorizedSigners[${index}].name`}
                              id={`authorizedSigners[${index}].name`}
                              component={FormikTextInput}
                              autoComplete="off"
                              isDisabled={isAuthorizedSignerFieldDisabled()}
                            />
                            <Field
                              type="text"
                              label="Title"
                              name={`authorizedSigners[${index}].title`}
                              id={`authorizedSigners[${index}].title`}
                              component={FormikTextInput}
                              autoComplete="off"
                              isDisabled={isAuthorizedSignerFieldDisabled()}
                            />
                            <Field
                              type="text"
                              label="Email"
                              name={`authorizedSigners[${index}].email`}
                              id={`authorizedSigners[${index}].email`}
                              component={FormikTextInput}
                              autoComplete="off"
                              isDisabled={isAuthorizedSignerFieldDisabled()}
                            />
                          </Box>
                          {!isAuthorizedSignerFieldDisabled() && (
                            <Box
                              as="button"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              aria-label="Remove authorized signer"
                              fontSize="lg"
                              borderWidth="0"
                              color="body-secondary"
                              padding="sm"
                              background="transparent"
                              hover={{
                                color: 'body-primary',
                              }}
                              cursor="pointer"
                            >
                              <Icon name="c-remove" />
                            </Box>
                          )}
                        </Box>
                      ))}

                      {!isAuthorizedSignerFieldDisabled() && (
                        <Box alignItems="flex-end">
                          <Button
                            as="button"
                            onClick={() => arrayHelpers.push({ name: '', title: '', email: '' })}
                            variant="secondary"
                            tone="neutral"
                            size="sm"
                            aria-label="Add Authorized Signer"
                            iconPrefix="add"
                          >
                            Authorized Signer
                          </Button>
                        </Box>
                      )}
                    </Box>
                  );
                }}
              />
            </FormSection>
            <FormSection
              title="State Licenses"
              description={`All applicable license numbers for each state ${values.programTypes?.length > 1 ? 'Please ensure that license numbers covering both Solar and HVAC installation are included' : ''}`}
            >
              <FieldArray
                name="stateLicense"
                render={(arrayHelpers) => {
                  const stateLicense = values.stateLicense;
                  return (
                    <Box gap="md">
                      {stateLicense &&
                        stateLicense.length > 0 &&
                        stateLicense.map((_stateLicense: any, index: any) => (
                          <Box
                            key={index}
                            gap={{ base: 'md', desktop: 'xl' }}
                            direction="row"
                            alignItems={{ base: 'center', tablet: 'flex-end' }}
                            borderWidth="xs"
                            borderColor="separator"
                            padding="md"
                            radius="md"
                          >
                            <Box
                              flex="auto"
                              gap={{ base: 'md', desktop: 'xl' }}
                              direction={{ base: 'column', tablet: 'row' }}
                              alignItems={{ tablet: 'flex-end' }}
                            >
                              <Field
                                type="text"
                                label="State"
                                name={`stateLicense[${index}].state`}
                                id={`stateLicense[${index}].state`}
                                options={getStateOptions(true)}
                                component={FormikSelectInputNative}
                                autoComplete="off"
                                isDisabled={isStateLicenseFieldDisabled(isSubmitting, index)}
                                isRequired
                              />
                              <Field
                                type="text"
                                label="Contractor License Number"
                                name={`stateLicense[${index}].contractorLicenseNumber`}
                                id={`stateLicense[${index}].contractorLicenseNumber`}
                                component={FormikTextInput}
                                autoComplete="off"
                                isDisabled={isStateLicenseFieldDisabled(isSubmitting, index)}
                                isRequired
                              />
                              <Field
                                type="text"
                                label="License Monetary Limit"
                                name={`stateLicense[${index}].monetaryLimit`}
                                id={`stateLicense[${index}].monetaryLimit`}
                                component={FormikTextInput}
                                autoComplete="off"
                                isDisabled={isStateLicenseFieldDisabled(isSubmitting, index)}
                              />
                              <Field
                                type="text"
                                label="Additional License Numbers"
                                name={`stateLicense[${index}].additionalLicenseNumbers`}
                                id={`stateLicense[${index}].additionalLicenseNumbers`}
                                component={FormikTextInput}
                                autoComplete="off"
                                maxLength={50}
                                isDisabled={isStateLicenseFieldDisabled(isSubmitting, index)}
                              />
                            </Box>
                            <Box
                              as="button"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              aria-label="Remove state license"
                              disabled={isStateLicenseFieldDisabled(isSubmitting, index)}
                              fontSize="lg"
                              borderWidth="0"
                              color="body-secondary"
                              padding="sm"
                              background="transparent"
                              hover={{
                                color: 'body-primary',
                              }}
                              cursor="pointer"
                            >
                              <Icon name="c-remove" />
                            </Box>
                          </Box>
                        ))}

                      <Box alignItems="flex-end">
                        <Button
                          as="button"
                          onClick={() =>
                            arrayHelpers.push({
                              state: '',
                              contractorLicenseNumber: '',
                              additionalLicenseNumbers: '',
                            })
                          }
                          variant="secondary"
                          tone="neutral"
                          size="sm"
                          aria-label="Add Additional License"
                          iconPrefix="add"
                          isDisabled={
                            isSubmitting ||
                            isLoading ||
                            !isPalmettoFinanceUser(claims) ||
                            !permissions.includes(UserPermissions.admin)
                          }
                        >
                          State License
                        </Button>
                      </Box>
                    </Box>
                  );
                }}
              />
            </FormSection>
            {values.programTypes.includes(ProgramType.hvac) && (
              <FormSection
                title="Extended Warranty Dealer Information"
                description="Add your Dealer IDs so that LightReach can register all applicable extended warranties"
              >
                <FieldArray
                  name="extendedWarrantyDealerInformation"
                  render={(arrayHelpers) => {
                    return (
                      <Box gap="md">
                        {values.extendedWarrantyDealerInformation &&
                          values.extendedWarrantyDealerInformation.length > 0 &&
                          values.extendedWarrantyDealerInformation.map((_extendedWarranty: any, index: any) => (
                            <Box
                              key={index}
                              gap={{ base: 'md', desktop: 'xl' }}
                              direction="row"
                              alignItems={{ base: 'center', tablet: 'flex-end' }}
                              borderWidth="xs"
                              borderColor="separator"
                              padding="md"
                              radius="md"
                            >
                              <Box
                                flex="auto"
                                gap={{ base: 'md', desktop: 'xl' }}
                                direction={{ base: 'column', tablet: 'row' }}
                                alignItems={{ tablet: 'flex-end' }}
                              >
                                <SelectInputNative
                                  label="Warranty Provider"
                                  name={`extendedWarrantyDealerInformation[${index}].warrantyProvider`}
                                  id={`extendedWarrantyDealerInformation[${index}].warrantyProvider`}
                                  options={[
                                    {
                                      label: values.extendedWarrantyDealerInformation[0].warrantyProvider,
                                      value: values.extendedWarrantyDealerInformation[0].warrantyProvider,
                                    },
                                  ]}
                                  value={
                                    {
                                      label: values.extendedWarrantyDealerInformation[0].warrantyProvider,
                                      value: values.extendedWarrantyDealerInformation[0].warrantyProvider,
                                    } as any
                                  }
                                  maxWidth="40"
                                  isDisabled={true}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `extendedWarrantyDealerInformation[${index}].warrantyProvider`,
                                      event.target.value,
                                    )
                                  }
                                />
                                <Field
                                  type="text"
                                  label="Dealer License Number"
                                  name={`extendedWarrantyDealerInformation[${index}].dealerLicenseNumber`}
                                  id={`extendedWarrantyDealerInformation[${index}].dealerLicenseNumber`}
                                  component={FormikTextInput}
                                  onChange={(event: any) =>
                                    setFieldValue(
                                      `extendedWarrantyDealerInformation[${index}].dealerLicenseNumber`,
                                      event.target.value,
                                    )
                                  }
                                  autoComplete="off"
                                  maxWidth="20"
                                  isRequired
                                />
                              </Box>
                              <Box
                                as="button"
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                aria-label="Remove Warranty Dealer Info"
                                fontSize="lg"
                                borderWidth="0"
                                color="body-secondary"
                                padding="sm"
                                background="transparent"
                                hover={{
                                  color: 'body-primary',
                                }}
                                cursor="pointer"
                              >
                                <Icon name="c-remove" />
                              </Box>
                            </Box>
                          ))}
                        <Box alignItems="flex-end">
                          <Button
                            as="button"
                            onClick={() =>
                              arrayHelpers.push({
                                warrantyProvider: 'JB Warranties',
                                dealerLicenseNumber: '',
                              })
                            }
                            variant="secondary"
                            tone="neutral"
                            size="sm"
                            aria-label="Add Additional Warranty Information"
                            iconPrefix="add"
                          >
                            Warranty Info
                          </Button>
                        </Box>
                      </Box>
                    );
                  }}
                />
              </FormSection>
            )}
            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                as="a"
                navigate={() => navigate(cancelButtonUrl)}
                variant="secondary"
                tone="neutral"
                size="md"
                isDisabled={isLoading}
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button variant="primary" size="md" type="submit" isDisabled={isLoading} isLoading={isSubmitting}>
                Submit
              </Button>
            </Box>
          </EnhancedFormikForm>
        )}
      </Formik>
    </>
  );
};
