import { Helmet } from 'react-helmet';
import { Box, Button, Toggle, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';

import PageHeader from '../PageHeader';
import { ChangeEvent, useEffect, useState } from 'react';
import { useCreateSystemDesignMutation } from '@/services/systemDesign';
import { DomesticContentAttestmentModal } from './DomesticContentAttestmentModal';
import { isDomesticContentRequirementRejectedForAccount } from '@/helpers/isDomesticContentRequirementRejectedForAccount';
import { DocumentRejectionReasons, QueueType } from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';

export const DomesticContentAttestmentModalLabels = {
  confirmed: {
    label: 'I confirm that this project meets LightReach Domestic Content guidance to the best of my knowledge.',
    description:
      'If the project is rejected for Domestic Content, I understand that the entire Install Package will need to be re-reviewed before any Install Milestone payment is made.',
  },
  unconfirmed: {
    label: 'I confirm that I am not requesting Domestic Content review for this project.',
    description:
      'I understand that this project will be eligible for Milestone Payments based off the non-Domestic Content value.',
  },
};

export function DomesticContentValueConfirmation() {
  const { id: accountId } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: account }: any = useGetAccountQuery(accountId);

  const [createSystemDesign, { isLoading: isCreatingSystemDesign }] = useCreateSystemDesignMutation();
  const { data: domesticContentQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: accountId,
    type: QueueType.domesticContent,
  });
  const [isDomesticContentValueConfirmed, setIsDomesticContentValueConfirmed] = useState(true);
  const [attestmentModalConfirmationLabel, setAttestmentModalConfirmationLabel] = useState(
    DomesticContentAttestmentModalLabels.confirmed.label,
  );
  const [attestmentModalConfirmationDescription, setAttestmentModalConfirmationDescription] = useState(
    DomesticContentAttestmentModalLabels.confirmed.description,
  );

  const [isResubmitDomesticContentEnabled, setIsResubmitDomesticContentEnabled] = useState(false);

  const {
    isOpen: isAttestmentModalOpen,
    handleClose: handleCloseAttestmentModal,
    handleOpen: handleOpenAttestmentModal,
  } = useOpenClose();

  const handleOnDomesticContentConfirmation = async () => {
    try {
      await createSystemDesign({
        systemDesign: {
          ...account.systemDesign,
          domesticContentAttestment: {
            valueConfirmation: isDomesticContentValueConfirmed,
            ...(isResubmitDomesticContentEnabled && { resubmission: true }),
          },
        },
        accountId: account.id,
      }).unwrap();
      if (isResubmitDomesticContentEnabled) {
        navigate(`/accounts/${accountId}/installation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${accountId}/installation-package/create`, { replace: true });
      }
    } catch (error) {
      toast.error((error as any)?.data?.message);
    }
  };

  const updateAttestmentLabels = (isChecked: boolean) => {
    const { label, description } = isChecked
      ? DomesticContentAttestmentModalLabels.confirmed
      : DomesticContentAttestmentModalLabels.unconfirmed;
    setAttestmentModalConfirmationLabel(label);
    setAttestmentModalConfirmationDescription(description);
  };

  const handleConfirmationToggleChange = (isChecked: boolean) => {
    setIsDomesticContentValueConfirmed(isChecked);
    updateAttestmentLabels(isChecked);
  };

  const getRejectionReason = (reasonKey: string | undefined): string => {
    if (!reasonKey) return DocumentRejectionReasons.other;
    return (
      DocumentRejectionReasons[reasonKey as keyof typeof DocumentRejectionReasons] || DocumentRejectionReasons.other
    );
  };

  useEffect(() => {
    if (!account || !accountId) return;

    const hasDomesticContentAttestment = Boolean(account.systemDesign?.domesticContentAttestment);

    if (!hasDomesticContentAttestment) {
      return;
    }

    const isDomesticContentRequirementRejected = isDomesticContentRequirementRejectedForAccount(account);

    if (isDomesticContentRequirementRejected) {
      setIsResubmitDomesticContentEnabled(true);
    } else {
      navigate(`/accounts/${accountId}/installation-package/create`, { replace: true });
    }
  }, [accountId, navigate, account]);

  return (
    <>
      <Helmet>
        <title>Domestic Content Value Confirmation</title>
      </Helmet>

      <PageHeader
        eyebrow={<Link to={`/accounts/${accountId}`}>{account?.primaryApplicantName}</Link>}
        title={'Domestic Content Value Confirmation'}
      />

      <Box
        childGap={{
          base: 'lg',
        }}
        padding={{
          base: 'md',
          desktop: '0',
        }}
        width={{ base: '100', desktop: '50' }}
        display="block"
      >
        <Box padding="lg 0 0 0">
          {isResubmitDomesticContentEnabled ? (
            <Box fontSize="sm" childGap="md" color="body-secondary">
              <p>
                LightReach review determined that this project does not satisfy the Domestic Content equipment
                thresholds for the following reason(s):{' '}
              </p>
              <Box childGap="2xs" as="ul">
                {domesticContentQueue?.rejectionReasons?.map((reason: any, index: number) => (
                  <Box key={index}>
                    <li>{getRejectionReason(reason?.reasons[0])}</li>
                    {reason?.note && <Box>{reason.note}</Box>}
                  </Box>
                ))}
              </Box>

              <Toggle
                id="resubmit-status"
                label={'Yes, I am submitting this Account for Domestic Content Review'}
                isChecked={isDomesticContentValueConfirmed}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleConfirmationToggleChange(event.target.checked)
                }
              />
              <Box as="p" color="body-secondary" fontSize="sm">
                I may re-submit for Domestic Content review again, but by doing so, I understand that the entire
                Installation Package will be rejected if Domestic Content is not met. I understand that I must
                provide additional proof as to why I believe this project does satisfy the LightReach Domestic
                Content requirements in the "Domestic Content Documents" section of the Installation Package.
              </Box>
            </Box>
          ) : (
            <Box childGap="md">
              <Toggle
                id="status"
                label="Yes, I am submitting this Account for Domestic Content Review"
                isChecked={isDomesticContentValueConfirmed}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleConfirmationToggleChange(event.target.checked)
                }
              />
              <Box as="p" color="body-secondary" fontSize="sm">
                Based on the final system design, we believe this project will satisfy the Domestic Content threshold
                guidelines for the current IRA Program Year. By submitting for Domestic Content value, I understand
                that the entire Installation Package will be rejected if Domestic Content is not met.
              </Box>
            </Box>
          )}
        </Box>

        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          justifyContent={{
            tablet: 'flex-end',
          }}
          childGap="sm"
        >
          <Button
            as="a"
            navigate={() => navigate(`/accounts/${accountId}`)}
            variant="secondary"
            tone="neutral"
            size="md"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={() => handleOpenAttestmentModal()}
            isLoading={isCreatingSystemDesign}
          >
            Confirm
          </Button>
        </Box>
        <DomesticContentAttestmentModal
          isOpen={isAttestmentModalOpen}
          onDismiss={handleCloseAttestmentModal}
          onAttestmentConfirmed={handleOnDomesticContentConfirmation}
          confirmationLabel={attestmentModalConfirmationLabel}
          confirmationDescription={attestmentModalConfirmationDescription}
          isLoading={isCreatingSystemDesign}
        />
      </Box>
    </>
  );
}
