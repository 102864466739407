import { Box, Icon, toast } from '@palmetto/palmetto-components';
import { useCallback, useContext } from 'react';
import { QueueFlagsContext } from './QueueFlagsContext';

export interface FlagProps {
  dataKey: string;
  fileName?: string;
  baseCategory?: string;
  type?: string;
}

export const Flag = ({ dataKey, fileName, baseCategory, type }: FlagProps) => {
  const { showModal, getFlag, resolveFlag, isLoading, setLoading } = useContext(QueueFlagsContext);
  const currentFlag = getFlag(dataKey, fileName, false);
  const handleFlagClick = useCallback(async () => {
    if (currentFlag) {
      if (!isLoading) {
        setLoading(true);
        try {
          await resolveFlag(dataKey, fileName);
        } catch (e) {
          toast.error('Something went wrong. Please try again.');
          console.log({ e });
        }
        setLoading(false);
      }
    } else {
      showModal(dataKey, fileName, baseCategory, type);
    }
  }, [currentFlag, resolveFlag, dataKey, fileName, showModal, baseCategory, isLoading]);

  return (
    <Box onClick={handleFlagClick} cursor={isLoading ? '' : 'pointer'}>
      <Icon name="flag" color={isLoading ? 'disabled' : 'body-primary'} />
    </Box>
  );
};
