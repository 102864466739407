import { UserPermissions } from 'types';
import { useAuth } from './authProvider';
import { ReactNode } from 'react';

const palmettoFinanceOrgAlias = 'sabal';

export const isPalmettoFinanceUser = (claims: any) =>
  claims?.org === palmettoFinanceOrgAlias || claims?.rootOrg === palmettoFinanceOrgAlias;

const RequireContractAdminUser = ({ children }: { children: ReactNode }) => {
  const { claims } = useAuth();
  const isPalmettoFinance = isPalmettoFinanceUser(claims);
  const isContractAdmin = claims?.permissions?.includes(UserPermissions.contractAdmin);
  return isPalmettoFinance && isContractAdmin ? <>{children}</> : null;
};

export default RequireContractAdminUser;
