import { useMemo } from 'react';
import { DocumentFile, DocumentDocument, DocumentStatus, DocumentTypes, documentTitles, UserPermissions } from 'types';
import { documentStatus } from '../../types/Documents';
import { Badge, Box, Button, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FileItem } from '../documents/FileItem';
import { useArchiveDocumentMutation, useUpdateDocumentStatusMutation } from '../../services/documents';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { isErrorWithData } from '../../services/helpers';
import { DocumentRejectionModal } from '../DocumentRejectionModal/DocumentRejectionModal';
import { useUpdateQueueMutation } from '../../services/queues';
import { QueueDocument, QueueStatus, QueueRejectionReasons, QueueRejectionReason } from 'types';
import { useAuth } from '../auth/authProvider';

export function DocumentItem({
  item,
  showDocumentActions = true,
  allowArchive = true,
  queueItem,
}: {
  item: DocumentDocument;
  showDocumentActions?: boolean;
  allowArchive?: boolean;
  queueItem?: QueueDocument;
}) {
  const { type, status, externalReference, files } = item;
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [updateDocumentStatus, { isLoading: isUpdatingStatus }] = useUpdateDocumentStatusMutation();
  const [updateQueue, { isLoading: isUpdatingQueue }] = useUpdateQueueMutation();
  const [archiveDocument, { isLoading: isArchiving }] = useArchiveDocumentMutation();
  const { user } = useAuth();
  const documentTitle = documentTitles[type] || (type as string);
  const canArchive = allowArchive && status !== DocumentStatus.approved;

  const rejectionReasonFromQueue = useMemo(() => {
    if (item && queueItem) {
      return queueItem?.rejectionReasons?.find(
        (reason: QueueRejectionReasons) => reason?.rejectedDocumentId?.toString() === item?.id?.toString(),
      );
    }
  }, [item, queueItem]);

  const reasonText = rejectionReasonFromQueue?.reasons
    .map((r: QueueRejectionReason) => QueueRejectionReason[r as unknown as keyof typeof QueueRejectionReason])
    .join(', ');

  const handleDocumentStatusUpdate = async (
    status: DocumentStatus,
    rejectionNote?: string,
    rejectionReasons?: string[],
  ) => {
    try {
      await updateDocumentStatus({
        id: item?.id?.toString() || '',
        accountId: item?.accountId?.toString() || '',
        status,
        rejectionNote,
        rejectionReasons,
      }).unwrap();
      toast.success('Document updated successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating document: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating document');
      }
    }
  };

  const handleQueueUpdate = async (status: QueueStatus, note?: string, reasons?: QueueRejectionReason[]) => {
    try {
      if (queueItem?.id) {
        await updateQueue({
          id: queueItem.id,
          data: {
            assigneeId: user?.sub,
            status,
            ...(reasons && {
              rejectionReasons: [
                {
                  reasons,
                  rejectedDocumentId: item?.id,
                  ...(note && { note }),
                },
              ],
            }),
          },
        }).unwrap();
        toast.success('Queue updated successfully');
      }
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating queue: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating queue');
      }
    }
  };

  const approve = async () => {
    if (queueItem) {
      return handleQueueUpdate(QueueStatus.approved);
    }
    // older accounts will not have a queue document
    return handleDocumentStatusUpdate(DocumentStatus.approved);
  };

  // handles older accounts without a queue document, newer accounts will use a rejection modal that calls handleRejectModal
  const reject = async () => {
    await handleDocumentStatusUpdate(DocumentStatus.rejected);
  };

  const handleRejectModal = async (values: { rejectionReason: string; notes: string | undefined }) => {
    const { rejectionReason, notes } = values;
    await handleQueueUpdate(QueueStatus.rejected, notes, [rejectionReason as QueueRejectionReason]);
    closeModal();
  };

  const archive = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to Archive this Document? This action cannot be undone.',
    );

    if (!confirmed) return;

    await archiveDocument({
      id: item?.id?.toString() || '',
      accountId: item?.accountId?.toString() || '',
      invalidateAllTags: false,
    });

    toast.success('Document archived successfully');
  };

  return (
    <>
      <DocumentRejectionModal
        isModalOpen={isModalOpen}
        title={documentTitle}
        handleClose={closeModal}
        handleSubmit={handleRejectModal}
      />
      <Box
        padding="sm 0 sm lg"
        width="100"
        childGap="lg"
        direction="row"
        alignItems="center"
        borderWidth="xs 0 0 0"
        borderColor="separator"
      >
        <Box
          width="100"
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ base: 'flex-start', tablet: 'center' }}
          childGap={{ base: 'xs', tablet: 'lg' }}
        >
          <Box alignItems="flex-start" width="xl">
            <Badge message={documentStatus[status]?.label} variant={documentStatus[status]?.variant} />
          </Box>
          <Box flex="auto">
            <Box display="block" childGap="2xs">
              {files.map((f: DocumentFile) => (
                <FileItem file={f} key={f?.md5Hash} />
              ))}
            </Box>

            {externalReference && (
              <Box fontSize="xs" margin="xs 0 0 0">{`Ext. Reference: ${externalReference}`}</Box>
            )}
            {status === DocumentStatus.rejected && rejectionReasonFromQueue && (
              <Box color="danger" margin="xs 0 0 0">
                {rejectionReasonFromQueue.note ? (
                  <Box>
                    {reasonText}: {rejectionReasonFromQueue.note}
                  </Box>
                ) : (
                  <Box>{reasonText}</Box>
                )}
              </Box>
            )}
          </Box>
        </Box>

        {showDocumentActions && (
          <Box
            width="40"
            direction={{ base: 'column', tablet: 'row' }}
            childGap="xs"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {status === DocumentStatus.pending && (
              <div>
                <RequirePalmettoFinanceUser>
                  <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                    <Button
                      size="sm"
                      variant="primary"
                      isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                      onClick={approve}
                    >
                      Approve
                    </Button>
                  </RequirePermissions>
                </RequirePalmettoFinanceUser>
              </div>
            )}
            {type !== DocumentTypes.contract && status === DocumentStatus.pending && (
              <div>
                <RequirePalmettoFinanceUser>
                  <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                    <Button
                      size="sm"
                      variant="primary"
                      tone="danger"
                      isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                      onClick={queueItem ? openModal : reject}
                    >
                      Reject
                    </Button>
                  </RequirePermissions>
                </RequirePalmettoFinanceUser>
              </div>
            )}
            {type === DocumentTypes.contract && status === DocumentStatus.pending && (
              <div>
                <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="primary"
                    tone="danger"
                    isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                    onClick={reject}
                  >
                    Void
                  </Button>
                </RequirePermissions>
              </div>
            )}
            {canArchive && (
              <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                <Button
                  size="sm"
                  variant="secondary"
                  tone="neutral"
                  isLoading={isUpdatingStatus || isUpdatingQueue || isArchiving}
                  onClick={archive}
                >
                  Archive
                </Button>
              </RequirePermissions>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
