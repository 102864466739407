import * as yup from 'yup';
import {
  Box,
  Spinner,
  Button,
  FormikTextInput,
  FormikSelectInputNative,
  toast,
  CheckboxInput,
} from '@palmetto/palmetto-components';
import { OrganizationCEDSettings, ProgramType, VendorEquipmentTypes } from 'types';
import { OrganizationNode } from '../../../../types/Organizations';
import {
  useGetOrganizationSplitPaySettingsQuery,
  usePutOrganizationSplitPaySettingsMutation,
  useGetSplitPayProgramNamesQuery,
} from '../../../../services/organizations';
import { useGetApprovedVendorsByTypeQuery } from '../../../../services/approvedVendors';
import { Formik, Field } from 'formik';
import { useCallback, useMemo } from 'react';
import FormSection from '../../../Forms/FormSection';
import { useNavigate } from 'react-router-dom';
import { EnhancedFormikForm } from '@/components/EnhancedFormikForm';

interface SplitPayProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

const positiveNumber = yup.number().min(0, 'Must be a positive number').typeError('Must be a number');
const splitPaySchema = yup.object().shape({
  cedSplitPayEnabled: yup.boolean(),
  cedInstallerIdentifier: yup.string(),
  spaPricingEnabled: yup.boolean().optional().nullable(),
  pvBillingRate: positiveNumber,
  programName: yup.string(),
});

const SplitPay = ({ organization }: SplitPayProps) => {
  const navigate = useNavigate();
  const [updateSplitPaySettingsMutation] = usePutOrganizationSplitPaySettingsMutation();
  const { data, isLoading } = useGetOrganizationSplitPaySettingsQuery(organization);
  const { data: splitPayProgramNames, isLoading: areProgramNamesLoading } = useGetSplitPayProgramNamesQuery();
  const { data: storageOptions, isLoading: areStorageOptionsLoading } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType:
      organization.programTypes && organization.programTypes.length > 0
        ? organization.programTypes[0]
        : ProgramType.solar,
  });

  const programNameOptions = useMemo(() => {
    if (!splitPayProgramNames) {
      return [];
    }

    return splitPayProgramNames.programs.map((program) => ({
      label: program.programName,
      value: program.programId,
    }));
  }, [splitPayProgramNames]);

  const getInitialValues = (settings: OrganizationCEDSettings) => {
    const { batteryRates, ...rest } = settings;

    const initialValues = {
      ...rest,
    } as Record<string, any>;

    (batteryRates || []).forEach((rate) => {
      initialValues[rate.id] = rate.billingRate;
    });

    return initialValues;
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (values.cedSplitPayEnabled) {
      if (!values.cedInstallerIdentifier) {
        errors.cedInstallerIdentifier =
          'The Greentech ID of the EPC is required when Greentech Direct Pay is enabled';
      }
      if (values.spaPricingEnabled) {
        if (!values.pvBillingRate) {
          errors.pvBillingRate = 'The PV Billing Rate is required when SPA pricing is enabled';
        }

        for (const option of storageOptions?.data) {
          if (!values[option.id]) {
            errors[option.id] = 'The battery rate is required for all storage options when SPA pricing is enabled';
          }
        }
      }
      if (!values.programName) {
        errors.programName = 'The PV Billing Rate is required when Greentech Direct Pay is enabled';
      }
    }
    return errors;
  };

  const handleSubmit = useCallback(
    async (values: any, { setSubmitting }: any) => {
      try {
        const updates = { ...values, batteryRates: [] };

        if (!updates.spaPricingEnabled) {
          updates.pvBillingRate = 0;
        } else {
          updates.batteryRates = (storageOptions?.data || []).map((option: any) => {
            delete updates[option.id];
            return {
              id: option.id,
              manufacturer: option.manufacturer,
              model: option.modelNumber,
              billingRate: values[option.id],
            };
          });
        }
        await updateSplitPaySettingsMutation({
          alias: organization.alias,
          splitPaySettings: updates as OrganizationCEDSettings,
        }).unwrap();
        toast.success('Settings submitted successfully');
      } catch (_err: any) {
        toast.error('Error submitting settings');
      }
      setSubmitting(false);
    },
    [organization, updateSplitPaySettingsMutation, storageOptions],
  );

  return (
    <Box
      childGap={{
        base: 'lg',
        desktop: '2xl',
        hd: '3xl',
      }}
      padding={{
        base: 'lg',
        desktop: '0',
      }}
      width="50%"
      display="block"
    >
      <Box
        childGap="2xs"
        style={{
          flexShrink: 0,
        }}
      >
        <Box as="h2" fontSize="md" fontWeight="medium">
          Greentech Direct Pay Settings
        </Box>
        {data?.cedSplitPayEnabled && (
          <Box as="p" fontSize="sm">
            Enabled on{' '}
            {data?.cedSplitPayEnabledDate ? new Date(data.cedSplitPayEnabledDate).toLocaleDateString() : 'N/A'}
          </Box>
        )}
      </Box>
      {!isLoading && !areProgramNamesLoading && !areStorageOptionsLoading ? (
        <Formik
          validationSchema={splitPaySchema}
          initialValues={getInitialValues(data as OrganizationCEDSettings)}
          validate={handleValidation}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <EnhancedFormikForm>
              <FormSection title="General Settings" description="General Direct Pay Settings">
                <Box childGap={{ base: 'lg', desktop: 'xl' }} style={{ whiteSpace: 'pre-wrap' }}>
                  <Box
                    direction={{
                      base: 'column',
                      tablet: 'row',
                    }}
                    childGap={{ base: 'lg', desktop: 'xl' }}
                  >
                    <CheckboxInput
                      id="cedSplitPayEnabled"
                      name="cedSplitPayEnabled"
                      label="Is Greentech Direct Pay Enabled?"
                      onChange={(event) => setFieldValue('cedSplitPayEnabled', event.target.checked)}
                      isChecked={values.cedSplitPayEnabled}
                      isDisabled={isLoading || isSubmitting}
                    />
                  </Box>
                  <Box
                    direction={{
                      base: 'column',
                      tablet: 'row',
                    }}
                    childGap={{ base: 'lg', desktop: 'xl' }}
                  >
                    <CheckboxInput
                      id="spaPricingEnabled"
                      label="Is Spa Pricing Enabled?"
                      name="spaPricingEnabled"
                      isChecked={values.spaPricingEnabled || false}
                      isDisabled={isSubmitting || isLoading || !values.cedSplitPayEnabled}
                      onChange={(event) => setFieldValue('spaPricingEnabled', event.target.checked)}
                    />
                  </Box>
                  <Box
                    direction={{
                      base: 'column',
                      tablet: 'row',
                    }}
                    childGap={{ base: 'lg', desktop: 'xl' }}
                  >
                    <Field
                      type="text"
                      label="Greentech Installer Identifier"
                      name="cedInstallerIdentifier"
                      id="cedInstallerIdentifier"
                      component={FormikTextInput}
                      autoComplete="off"
                      isDisabled={isSubmitting || isLoading || !values.cedSplitPayEnabled}
                    />
                  </Box>
                  <Box
                    direction={{
                      base: 'column',
                      tablet: 'row',
                    }}
                    childGap={{ base: 'lg', desktop: 'xl' }}
                  >
                    <Field
                      type="text"
                      label="Greentech Program Name"
                      name="programName"
                      id="programName"
                      options={programNameOptions}
                      component={FormikSelectInputNative}
                      onChange={(event: any) => setFieldValue('programName', event.target.value)}
                      autoComplete="off"
                      isDisabled={isSubmitting || isLoading || !values.cedSplitPayEnabled}
                    />
                  </Box>
                </Box>
              </FormSection>

              <FormSection title="SPA Pricing Details" description="Amounts charged to the EPC for equipment">
                <Box childGap={{ base: 'lg', desktop: 'xl' }} style={{ whiteSpace: 'pre-wrap' }}>
                  <Box
                    direction={{
                      base: 'column',
                      tablet: 'row',
                    }}
                    childGap={{ base: 'lg', desktop: 'xl' }}
                  >
                    <Field
                      type="number"
                      label="EPC Billing Rate"
                      name="pvBillingRate"
                      id="pvBillingRate"
                      component={FormikTextInput}
                      onChange={(event: any) => setFieldValue('pvBillingRate', event.target.value)}
                      prefix="$"
                      suffix="/W"
                      isDisabled={
                        isSubmitting || isLoading || !values.cedSplitPayEnabled || !values.spaPricingEnabled
                      }
                    />
                  </Box>
                </Box>
                {(storageOptions?.data || []).map((option: any) => (
                  <Box childGap={{ base: 'lg', desktop: 'xl' }} style={{ whiteSpace: 'pre-wrap' }}>
                    <Box
                      direction={{
                        base: 'column',
                        tablet: 'row',
                      }}
                      childGap={{ base: 'lg', desktop: 'xl' }}
                    >
                      <Field
                        type="number"
                        label={`${option.manufacturer} - ${option.modelNumber} Battery Rate`}
                        name={option.id}
                        id={option.id}
                        component={FormikTextInput}
                        onChange={(event: any) => setFieldValue(option.id, event.target.value)}
                        prefix="$"
                        isDisabled={
                          isSubmitting || isLoading || !values.cedSplitPayEnabled || !values.spaPricingEnabled
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </FormSection>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'stretch',
                  tablet: 'flex-start',
                }}
                justifyContent={{
                  tablet: 'flex-start',
                }}
                childGap="sm"
                style={{ flexShrink: 0 }}
                padding="lg 0 0 0"
              >
                <Button
                  as="a"
                  navigate={() => navigate(`/settings/organizations/${organization.alias}`)}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isDisabled={isLoading}
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button variant="primary" size="md" type="submit" isDisabled={isLoading} isLoading={isSubmitting}>
                  Save
                </Button>
              </Box>
            </EnhancedFormikForm>
          )}
        </Formik>
      ) : (
        <Spinner size="lg" />
      )}
    </Box>
  );
};

export default SplitPay;
