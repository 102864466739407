import { useGetInvoiceNeededProjectsQuery } from '@/services/payouts';
import { Box, Column, Table } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const InvoiceNeeded = () => {
  const { data: projectsData, isLoading: isProjectDataLoading } = useGetInvoiceNeededProjectsQuery();
  const columns: Column[] = [
    {
      heading: 'Account',
      dataKey: 'name',
      render: (cell: any, row: any) => (
        <Link to={`/accounts/${row.externalId}`} target="_blank">
          {cell}
        </Link>
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title>Accounts Without Invoices</title>
      </Helmet>
      {projectsData && projectsData?.length > 0 ? (
        <Table rowKey="id" isLoading={isProjectDataLoading} columns={columns as Column[]} rows={projectsData} />
      ) : (
        <Box textAlign={'center'}>There are no accounts without invoices</Box>
      )}
    </>
  );
};
