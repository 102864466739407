import { Box, Button, Card, FormikTextareaInput, Modal, useOpenClose, toast } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useEditAccountMutation, useGetAccountQuery } from '../../services/accounts';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import FormSection from '../Forms/FormSection';
import { MilestoneType, UserPermissions } from 'types';

interface InstallNotesProps {
  handleClose: () => void;
  account: any;
  accountId: string;
}

const ManageInstallNotes = ({ handleClose, account, accountId }: InstallNotesProps) => {
  const [editAccount] = useEditAccountMutation();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      await editAccount({
        account: { notes: { milestones: { [MilestoneType.install]: values.notes } } },
        id: accountId,
      }).unwrap();

      toast.success('Account Install Notes Saved');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error saving account install notes');
    }
    setSubmitting(false);
  };

  const initialValues = useMemo(() => {
    return {
      notes: account?.notes?.milestones?.[MilestoneType.install],
    };
  }, [account]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ isSubmitting }) => (
        <Form id="installNotesForm">
          <Modal.Body background="secondary">
            <Box childGap="lg">
              <Field
                label="Notes"
                name="notes"
                id="notes"
                component={FormikTextareaInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button size="md" variant="primary" type="submit" form="installNotesForm" isLoading={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

const InstallNotes = () => {
  const { id, action } = useParams<{ id: any; action: ReviewPackageActions }>();
  const { data: account, isLoading }: any = useGetAccountQuery(id);
  const hasInstallNotes = account?.notes?.milestones?.install;
  const canManageInstallNotes = useMemo(() => {
    if (!account) return false;
    return (
      (account?.currentMilestone?.type === MilestoneType.install && action === ReviewPackageActions.review) || false
    );
  }, [account, action]);
  const { isOpen: isModifyOpen, handleClose: handleManageClose, handleToggle: handleManageToggle } = useOpenClose();

  return (
    <>
      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleManageClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="installNotesHeader"
        fullScreenMobile
      >
        <Modal.Header
          id="installNotesHeader"
          title="Install Notes"
          onDismiss={() => {
            handleManageClose();
          }}
        />
        <ManageInstallNotes
          handleClose={() => {
            handleManageClose();
          }}
          account={account || {}}
          accountId={id}
        />
      </Modal>

      {!isLoading && (
        <FormSection title="Install Notes" description="Notes added by the reviewer will appear at activation.">
          <Card>
            <Card.Section childGap="xs">
              <Box
                direction={{ base: 'column', tablet: 'row' }}
                childGap="2xl"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>{hasInstallNotes ? account?.notes?.milestones?.install : 'No notes to display.'}</Box>
                {canManageInstallNotes && (
                  <RequirePalmettoFinanceUser>
                    <RequirePermissions permissions={[UserPermissions.admin, UserPermissions.editor]} checkAllPermissions={false}>
                      <Button
                        onClick={handleManageToggle}
                        aria-label="manage permit approved date"
                        isLoading={isLoading}
                        size="sm"
                      >
                        {hasInstallNotes ? 'Edit' : 'Add'}
                      </Button>
                    </RequirePermissions>
                  </RequirePalmettoFinanceUser>
                )}
              </Box>
            </Card.Section>
          </Card>
        </FormSection>
      )}
    </>
  );
};

export default InstallNotes;
