import { useParams } from 'react-router-dom';
import { taskMap } from './AdminTasks';
import { Box, Card } from '@palmetto/palmetto-components';
import { OrganizationVisibility } from './OrgVisibility';

export const Task = () => {
  const { taskType = '' } = useParams<{ taskType: string }>();

  return (
    <Card padding="sm" height="75vh">
      <Card.Header>
        <Box direction="row" justifyContent="space-between">
          <Box fontSize="md" fontWeight="medium">
            {taskMap[taskType]}
          </Box>
        </Box>
      </Card.Header>
      {renderTaskComponent(taskType)}
    </Card>
  );
};

const renderTaskComponent = (taskType: string) => {
  switch (taskType) {
    case 'orgVisibility':
      return <OrganizationVisibility />;
    default:
      return null;
  }
};
